import React from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Stack,
    Switch,
    SwitchProps,
    Typography,
    Alert,
} from "@mui/material";
import { GridColumnMenuProps, GridColumnMenuSortItem, GridColumnMenuFilterItem, GridColDef, GridColumnGroupingModel } from "@mui/x-data-grid-premium";
import { FiberManualRecord, RestartAlt, Info } from "@mui/icons-material";
import { USStateAbbreviations } from "src/constants/States";
import { StyledSelect, CellBox } from "./JurisdictionOptions.styled";
import { SelectChangeEvent } from "@mui/material";

interface CustomSwitchProps {
    overrideValue: boolean | "";
    defaultValue: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomSwitch: React.FC<CustomSwitchProps & SwitchProps> = ({
    overrideValue,
    defaultValue,
    onChange,
    ...props
}) => {
    const isOverride = overrideValue !== "";
    const checked = isOverride ? overrideValue : defaultValue;
    const showRed = isOverride && checked !== defaultValue;
    const color = showRed ? "red" : checked ? "blue" : "gray";
    return (
        <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography variant="caption">off</Typography>
            <Switch
                {...props}
                checked={checked}
                color={showRed ? "error" : "default"}
                onChange={onChange}
                sx={{
                    "& .MuiSwitch-thumb": {
                        color: color,
                    },
                    "& .MuiSwitch-track": {
                        backgroundColor: color,
                    },
                }}
            />
            <Typography variant="caption">on</Typography>
        </Stack>
    );
};

interface CustomColumnMenuProps extends GridColumnMenuProps {
    formData: any;
    onFormDataChange: (data: any) => void;
    retalFormatAll: string;
    setRetalFormatAll: (value: string) => void;
    estimateBasisAll: string;
    setEstimateBasisAll: (value: string) => void;
}

export const CustomColumnMenu: React.FC<CustomColumnMenuProps> = (props) => {
    const itemProps = {
        colDef: props.colDef,
        onClick: props.hideMenu,
    };

    return (
        <Stack px={0.5} py={0.5}>
            {(props.colDef.field === "dollarsAndCentsDisplayOverrideValue" ||
                props.colDef.field === "payAllWithFirstOverrideValue") && (
                <>
                    <MenuItem
                        onClick={(event) => {
                            toggleAll(props.colDef.field, true, props.formData, props.onFormDataChange);
                            props.hideMenu(event);
                        }}
                    >
                        <FiberManualRecord sx={{ color: "blue" }} />
                        Turn on all
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            toggleAll(props.colDef.field, false, props.formData, props.onFormDataChange);
                            props.hideMenu(event);
                        }}
                    >
                        <FiberManualRecord sx={{ color: "red" }} />
                        Turn off all
                    </MenuItem>
                </>
            )}
            {props.colDef.field === "retaliatoryReturnsDisplayOverrideValue" && (
                <>
                    <MenuItem>
                        <FormControl sx={{ width: "200px" }}>
                            <FormHelperText>Set Retal Format for all</FormHelperText>
                            <Select
                                value={props.retalFormatAll}
                                id="retal-format-select"
                                onChange={(event) => {
                                    changeAllRetalFormat(
                                        event.target.value,
                                        props.formData,
                                        props.onFormDataChange,
                                        props.setRetalFormatAll
                                    );
                                    props.hideMenu(new Event('click') as any);
                                }}
                            >
                                <MenuItem value="both">
                                    Display both Returns and Worksheets
                                </MenuItem>
                                <MenuItem value="returnsOnly">
                                    Display Returns only
                                </MenuItem>
                                <MenuItem value="worksheetsOnly">
                                    Display Worksheets only
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </MenuItem>
                </>
            )}
            {props.colDef.field === "estimateBasisOverrideValue" && (
                <>
                    <MenuItem>
                        <FormControl sx={{ width: "200px" }}>
                            <FormHelperText>Set Estimate Basis for all</FormHelperText>
                            <Select
                                value={props.estimateBasisAll}
                                id="estimate-basis-select"
                                onChange={(event) => {
                                    changeAllEstimateBasis(
                                        event.target.value,
                                        props.formData,
                                        props.onFormDataChange,
                                        props.setEstimateBasisAll
                                    );
                                    props.hideMenu(new Event('click') as any);
                                }}
                            >
                                <MenuItem value="priorYear">Prior Year</MenuItem>
                                <MenuItem value="currentYear">Current Year</MenuItem>
                            </Select>
                        </FormControl>
                    </MenuItem>
                </>
            )}
            <Divider />
            <GridColumnMenuSortItem {...itemProps} />
            <Divider />
            <GridColumnMenuFilterItem {...itemProps} />
        </Stack>
    );
};

interface CustomFooterProps {
    displayInfo: boolean;
    setDisplayInfo: (value: boolean) => void;
    resetAllToDefault: () => void;
}

export const CustomFooter: React.FC<CustomFooterProps> = ({
    displayInfo,
    setDisplayInfo,
    resetAllToDefault,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid lightgray",
                padding: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 1,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Info />}
                    size="small"
                    onClick={() => setDisplayInfo(!displayInfo)}
                >
                    {displayInfo ? "Hide Info" : "Display Info"}
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    startIcon={<RestartAlt />}
                    size="small"
                    onClick={resetAllToDefault}
                >
                    Reset All to Default
                </Button>
            </Box>
            <Box
                sx={{
                    display: displayInfo ? "flex" : "none",
                    alignItems: "start",
                    gap: 2,
                    flexDirection: "column",
                    borderTop: "1px solid lightgray",
                    padding: 1,
                }}
            >
                <Alert severity="info">How to read switches:</Alert>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                    }}
                >
                    <CustomSwitch
                        overrideValue={"" as any}
                        defaultValue={true as any}
                        onChange={(event) => {}}
                    />
                    <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", marginLeft: 1 }}
                    >
                        Default ON
                    </Typography>
                    <Typography variant="caption" sx={{ marginLeft: 1 }}>
                        This will show when the system default value is{" "}
                        <strong>ON</strong> and the override is{" "}
                        <strong>NOT</strong> set
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                    }}
                >
                    <CustomSwitch
                        overrideValue={"" as any}
                        defaultValue={false as any}
                        onChange={(event) => {}}
                    />
                    <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", marginLeft: 1 }}
                    >
                        Default OFF
                    </Typography>
                    <Typography variant="caption" sx={{ marginLeft: 1 }}>
                        This will show when the system default value is{" "}
                        <strong>OFF</strong> and the override is{" "}
                        <strong>NOT</strong> set
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                    }}
                >
                    <CustomSwitch
                        overrideValue={true as any}
                        defaultValue={false as any}
                        onChange={(event) => {}}
                    />
                    <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", marginLeft: 1 }}
                    >
                        Override to ON:
                    </Typography>
                    <Typography variant="caption" sx={{ marginLeft: 1 }}>
                        This will show when the system default value is{" "}
                        <strong>OFF</strong> and the override is set to{" "}
                        <strong>ON</strong>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                    }}
                >
                    <CustomSwitch
                        overrideValue={false as any}
                        defaultValue={true as any}
                        onChange={(event) => {}}
                    />
                    <Typography
                        variant="caption"
                        sx={{ fontWeight: "bold", marginLeft: 1 }}
                    >
                        Override to OFF:
                    </Typography>
                    <Typography variant="caption" sx={{ marginLeft: 1 }}>
                        This will show when the system default value is{" "}
                        <strong>ON</strong> and the override is set to{" "}
                        <strong>OFF</strong>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export const handleStateToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    state: string,
    option: string,
    formData: any,
    onFormDataChange: (newData: any) => void
) => {
    onFormDataChange({
        [state]: {
            ...formData?.jsonOptions[state],
            [option]: event.target.checked,
        },
    });
};

export const handleDropdownChange = (
    event: SelectChangeEvent,
    state: string,
    formData: any,
    onFormDataChange: (newData: any) => void
) => {
    onFormDataChange({
        [state]: {
            ...formData?.jsonOptions[state],
            retaliatoryReturnsDisplayOverrideValue: event.target.value as string,
        },
    });
};

export const handleEstimateBasisDropdownChange = (
    event: SelectChangeEvent,
    state: string,
    formData: any,
    onFormDataChange: (newData: any) => void
) => {
    onFormDataChange({
        [state]: {
            ...formData?.jsonOptions[state],
            estimateBasisOverrideValue: event.target.value as string,
        },
    });
};

export const getColumns = (
    handleStateToggleChange: (event: React.ChangeEvent<HTMLInputElement>, state: string, option: string) => void,
    handleDropdownChange: (event: SelectChangeEvent, state: string) => void,
    handleEstimateBasisDropdownChange: (event: SelectChangeEvent, state: string) => void
): GridColDef[] => [
    {
        field: "state",
        headerName: "State",
        width: 150,
        headerAlign: "center",
    },
    {
        field: "dollarsAndCentsDisplayOverrideValue",
        headerName: "Cents",
        renderCell: (params) => {
            return (
                <CellBox>
                    <CustomSwitch
                        overrideValue={
                            params.row.dollarsAndCentsDisplayOverrideValue
                        }
                        defaultValue={
                            params.row.dollarsAndCentsDisplayValue
                        }
                        onChange={(event) =>
                            handleStateToggleChange(
                                event,
                                params.row.state,
                                "dollarsAndCentsDisplayOverrideValue"
                            )
                        }
                    />
                </CellBox>
            );
        },
        flex: 1,
        minWidth: 100,
        maxWidth: 150,
        headerAlign: "center",
    },
    {
        field: "retaliatoryReturnsDisplayOverrideValue",
        headerName: "Retal Format",
        renderCell: (params) => {
            if (
                params.row.state === "CompanyInfo" ||
                params.row.state === "SummarySchedule"
            ) {
                return <CellBox>NA</CellBox>;
            } else {
                return (
                    <CellBox>
                        <FormControl fullWidth>
                            <StyledSelect
                                size="small"
                                value={
                                    params.row
                                        .retaliatoryReturnsDisplayOverrideValue
                                        ? params.row
                                              .retaliatoryReturnsDisplayOverrideValue
                                        : params.row
                                              .retaliatoryReturnsDisplayValue
                                }
                                onChange={(event) =>
                                    handleDropdownChange(
                                        event,
                                        params.row.state
                                    )
                                }
                                overrideValue={
                                    params.row
                                        .retaliatoryReturnsDisplayOverrideValue
                                }
                                defaultValue={
                                    params.row
                                        .retaliatoryReturnsDisplayValue
                                }
                            >
                                <MenuItem value="both">
                                    Display both Returns and Worksheets
                                </MenuItem>
                                <MenuItem value="returnsOnly">
                                    Display Returns only
                                </MenuItem>
                                <MenuItem value="worksheetsOnly">
                                    Display Worksheets only
                                </MenuItem>
                            </StyledSelect>
                        </FormControl>
                    </CellBox>
                );
            }
        },
        flex: 1,
        minWidth: 250,
        maxWidth: 400,
        headerAlign: "center",
    },
    {
        field: "estimateBasisOverrideValue",
        headerName: "Estimate Basis",
        renderCell: (params) => {
            if (
                params.row.state === "CompanyInfo" ||
                params.row.state === "SummarySchedule"
            ) {
                return <CellBox>NA</CellBox>;
            } else {
                return (
                    <CellBox>
                        <FormControl fullWidth>
                            <StyledSelect
                                size="small"
                                value={
                                    params.row.estimateBasisOverrideValue
                                        ? params.row
                                              .estimateBasisOverrideValue
                                        : params.row.estimateBasisValue
                                }
                                onChange={(event) =>
                                    handleEstimateBasisDropdownChange(
                                        event,
                                        params.row.state
                                    )
                                }
                                overrideValue={
                                    params.row.estimateBasisOverrideValue
                                }
                                defaultValue={params.row.estimateBasisValue}
                            >
                                <MenuItem value="priorYear">
                                    Prior Year
                                </MenuItem>
                                <MenuItem value="currentYear">
                                    Current Year
                                </MenuItem>
                            </StyledSelect>
                        </FormControl>
                    </CellBox>
                );
            }
        },
        flex: 1,
        minWidth: 100,
        maxWidth: 200,
        headerAlign: "center",
    },
    {
        field: "payAllWithFirstOverrideValue",
        headerName: "Pay All With First",
        renderCell: (params) => {
            if (
                params.row.state === "CompanyInfo" ||
                params.row.state === "SummarySchedule"
            ) {
                return <CellBox>NA</CellBox>;
            } else {
                return (
                    <CellBox>
                        <CustomSwitch
                            overrideValue={
                                params.row.payAllWithFirstOverrideValue
                            }
                            defaultValue={params.row.payAllWithFirstValue}
                            onChange={(event) =>
                                handleStateToggleChange(
                                    event,
                                    params.row.state,
                                    "payAllWithFirstOverrideValue"
                                )
                            }
                        />
                    </CellBox>
                );
            }
        },
        flex: 1,
        minWidth: 100,
        maxWidth: 150,
        headerAlign: "center",
    },
];

export const getRows = (formData: any) => [
    ...USStateAbbreviations.map((state, index) => ({
        id: index,
        state,
        ...formData?.jsonOptions[state],
    })),
    {
        id: USStateAbbreviations.length,
        state: "CompanyInfo",
        ...formData?.jsonOptions["CompanyInfo"],
    },
    {
        id: USStateAbbreviations.length + 1,
        state: "SummarySchedule",
        ...formData?.jsonOptions["SummarySchedule"],
    },
];

export const columnGroupingModel: GridColumnGroupingModel = [
    {
        groupId: "display",
        headerName: "Display",
        headerAlign: "center",
        children: [
            { field: "dollarsAndCentsDisplayOverrideValue" },
            { field: "retaliatoryReturnsDisplayOverrideValue" },
        ],
    },
    {
        groupId: "estimate",
        headerName: "Estimate",
        headerAlign: "center",
        children: [
            { field: "estimateBasisOverrideValue" },
            { field: "payAllWithFirstOverrideValue" },
        ],
    },
];

export const toggleAll = (columnName: string, value: boolean, formData: any, onFormDataChange: (data: any) => void) => {
    let newData = {};
    USStateAbbreviations.forEach((stateAbbreviation) => {
        newData[stateAbbreviation] = {
            ...formData?.jsonOptions[stateAbbreviation],
            [columnName]: value,
        };
    });
    if (columnName === "dollarsAndCentsDisplayOverrideValue") {
        newData["CompanyInfo"] = {
            ...formData?.jsonOptions["CompanyInfo"],
            [columnName]: value,
        };
        newData["SummarySchedule"] = {
            ...formData?.jsonOptions["SummarySchedule"],
            [columnName]: value,
        };
    }
    onFormDataChange(newData);
};

export const changeAllRetalFormat = (
    value: string,
    formData: any,
    onFormDataChange: (data: any) => void,
    setRetalFormatAll: (value: string) => void
) => {
    if (value !== "") {
        let newData = {};
        USStateAbbreviations.forEach((stateAbbreviation) => {
            newData[stateAbbreviation] = {
                ...formData?.jsonOptions[stateAbbreviation],
                retaliatoryReturnsDisplayOverrideValue: value,
            };
        });
        onFormDataChange(newData);
        setRetalFormatAll(value);
    }
};

export const changeAllEstimateBasis = (
    value: string,
    formData: any,
    onFormDataChange: (data: any) => void,
    setEstimateBasisAll: (value: string) => void
) => {
    if (value !== "") {
        let newData = {};
        USStateAbbreviations.forEach((stateAbbreviation) => {
            newData[stateAbbreviation] = {
                ...formData?.jsonOptions[stateAbbreviation],
                estimateBasisOverrideValue: value,
            };
        });
        onFormDataChange(newData);
        setEstimateBasisAll(value);
    }
};

export const resetAllToDefault = (
    formData: any,
    onFormDataChange: (data: any) => void,
    setRetalFormatAll: (value: string) => void,
    setEstimateBasisAll: (value: string) => void
) => {
    let newData = {};
    USStateAbbreviations.forEach((stateAbbreviation) => {
        newData[stateAbbreviation] = {
            ...formData?.jsonOptions[stateAbbreviation],
            dollarsAndCentsDisplayOverrideValue: "",
            retaliatoryReturnsDisplayOverrideValue: "",
            estimateBasisOverrideValue: "",
            payAllWithFirstOverrideValue: "",
        };
    });
    newData["CompanyInfo"] = {
        ...formData?.jsonOptions["CompanyInfo"],
        dollarsAndCentsDisplayOverrideValue: "",
    };
    newData["SummarySchedule"] = {
        ...formData?.jsonOptions["SummarySchedule"],
        dollarsAndCentsDisplayOverrideValue: "",
    };
    onFormDataChange(newData);
    setRetalFormatAll("");
    setEstimateBasisAll("");
}; 