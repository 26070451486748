import { BaseAPIService } from "src/services/BaseApiService";
import {
    OptinsAllocationTypesResponse,
    OptinsCompareResponse,
    OptinsDeleteResponse,
    OptinsFilingStatus,
    OptinsInfo,
    OptinsPayload,
    SubmitFilingPayload,
    SubmitFilingResponse,
} from "src/types/OptinsService.types";

export class OptinsService {
    private static _instance: OptinsService;
    private readonly BASE_PATH: string = "/api/return-optins";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public compare(payload: OptinsPayload): Promise<OptinsCompareResponse[]> {
        return this.api.post<OptinsCompareResponse[]>(
            this.getPath("/compare"),
            payload
        );
    }

    public async export(payload: OptinsPayload): Promise<Response> {
        const response = await this.api.post<any>(
            this.BASE_PATH,
            payload,
            {},
            true
        );
        return response;
    }

    public async getCompanyTypesByState(
        payload: OptinsPayload
    ): Promise<OptinsInfo> {
        return this.api.post<OptinsInfo>(
            this.getPath(`/get-company-types-by-state`),
            payload
        );
    }

    public async findFilling(payload: {
        returnDocumentIdDTO: OptinsPayload;
        naicCompanyTypeId: string;
        testingYear?: string;
    }): Promise<OptinsFilingStatus> {
        return this.api.post<OptinsFilingStatus>(
            this.getPath(`/find-filing`),
            payload
        );
    }

    public async delete(
        payload: OptinsPayload,
        trackingId: string
    ): Promise<OptinsDeleteResponse> {
        return this.api.delete<OptinsDeleteResponse>(
            this.getPath(`/${trackingId}`),
            payload
        );
    }

    public async getAllocationTypes(
        payload: OptinsPayload,
        naicCompanyTypeId: string,
        testingYear: string
    ): Promise<OptinsAllocationTypesResponse> {
        return this.api.post<OptinsAllocationTypesResponse>(
            this.getPath(`/get-buckets`),
            {
                returnDocumentIdDTO: payload,
                naicCompanyTypeId: naicCompanyTypeId,
                testingYear: testingYear,
            }
        );
    }

    public async submitFiling(payload: SubmitFilingPayload): Promise<SubmitFilingResponse>{
        return this.api.post<SubmitFilingResponse>(this.getPath(`/submit-filing`), payload);
    }

    public static getInstance(): OptinsService {
        if (!OptinsService._instance) {
            OptinsService._instance = new OptinsService();
        }
        return OptinsService._instance;
    }
}
