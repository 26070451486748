import { Box } from '@mui/material';
import styled from 'styled-components';

export const CompanyTypeStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const GeneralInfoContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CompanyTypeSelectorContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FilingStatusContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    /* height: 100%; */
`;

export const ActionsContainer = styled(Box)`
    display: flex;
`;
