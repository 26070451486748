import { Button } from '@mui/material';
import styled from 'styled-components';

export const EfileNaicOptinsStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const EfileNaicOptinsActionContainerStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    padding: 16px;
`;

export const ActionButtonStyled = styled(Button)`
    background-color: ${({ theme }) => theme.palette.primary};
    &:hover {
        background-color: ${({ theme }) => theme.palette.secondary};
    }
`;

export const EfileNaicOptinsErrorStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

export const ErrorContainer = styled.div`
  font-size: 14px;
  padding: 24px;
  width: 100%;
  overflow-y: auto;
`;

export const Title = styled.span`
  font-size: 14px;
  display: flex;
  border-bottom: 3px solid #354560;
  width: 100%;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const ErrorList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ErrorItem = styled.li`
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 5px;
`;