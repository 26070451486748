import styled from "styled-components";

export const StyledPercentageContainer = styled.div`
    height: 100%;
    margin-right: 65px;
`;

export const StyledFieldsContainer = styled.div`
    display: flex;
    margin-bottom: 10px;

    p:first-child {
        width: 150px;
    }
`;

export const StyledScrollableContainer = styled.div`
    overflow: auto;
    height: 100%;
`;
