import {
    AccountOptions,
    AllocateResponse,
    AllocationPayload,
    EffectiveDate,
    SystemVersion,
    KentuckyAgentUserOptions,
    UsageReport,
} from "src/types";
import { BaseAPIService } from "./BaseApiService";

export class AgentService {
    private static _instance: AgentService;
    private readonly BASE_PATH: string = "/api";

    constructor() {}

    private api: BaseAPIService = BaseAPIService.getInstance();

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    getUsageReport() {
        return this.api.get<UsageReport>(this.getPath("/usagereport"));
    }

    getEffectiveDate() {
        return this.api.get<EffectiveDate>(this.getPath("/effective-date"));
    }

    getAccountOptions() {
        return this.api.get<AccountOptions[]>(this.getPath("/account/options"));
    }

    getSystemVersions() {
        return this.api.get<SystemVersion[]>(this.getPath("/versions"));
    }

    getKentuckyAgentUserOptions() {
        return this.api.get<KentuckyAgentUserOptions>(this.getPath("/user/options"));
    }

    setKentuckyAgentUserOptions(kentuckyAgentUserOptions) {
        return this.api.post<KentuckyAgentUserOptions>(
            this.getPath("/user/options"),
            kentuckyAgentUserOptions
        );
    }

    allocate(payload: AllocationPayload) {
        return this.api.post<AllocateResponse>(
            this.getPath("/allocate"),
            payload
        );
    }

    public static getInstance(): AgentService {
        if (!AgentService._instance) {
            AgentService._instance = new AgentService();
        }
        return AgentService._instance;
    }
}
