import { Chip, Tabs } from "@mui/material";
import styled from "styled-components";

interface StyledFlexContainerProps {
    $isHidden: boolean;
}

export const StyledFlexContainer = styled.div<StyledFlexContainerProps>`
    display: ${(props) => props.$isHidden ? "none" : "flex" };
    align-items: start;
    height: calc(100% - 76px);
`;

export const StyledTabs = styled(Tabs)`
    display: flex;
    min-height: 32px !important; 

    .MuiTabs-indicator {
        display: none;
        height: 0;
    }

    .MuiTab-root {
        padding: 0;
        min-height: 32px;
        min-width: 0;
        text-transform: none;
    }
`;

export const StyledChipTab = styled(Chip)`
    color: ${({ theme }) => theme.palette.primary};
    background-color: ${({ theme }) => theme.colors.indigo_dye[900]};
    margin: 0 2px;

    &: hover {
        background-color: ${({ theme }) => theme.colors.indigo_dye[800]};
    }
`;

export const SelectedStyledChipTab = styled(Chip)`
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.palette.primary};
    margin: 0 2px;
`;
