import React, { useContext } from "react";
import {
    Card,
    CardContent,
    Radio,
    RadioGroup,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
    fabClasses,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
} from "@mui/material";
import {
    EfileNaicOptinsContext,
    EfileNaicOptinsContextState,
} from "../EfileNaicOptins.context";
import {
    PaymentInfoElectronicPaymentInformation,
    PaymentInfoInstructions,
    PaymentInfoSelectorContainer,
    PaymentInfoStyled,
} from "./PaymentInfo.styled";
import { AllocatePayments } from "./AllocatePayments";
import { NumericFormat } from "react-number-format";
import { CONSENT_TYPE, FILING_TYPE, PAYMENT_TYPE } from "src/types";
import dayjs, { Dayjs } from "dayjs";
import { FALSE } from "sass";

export const PaymentInfo: React.FC = () => {
    const EfileNaicOptinsCtx: EfileNaicOptinsContextState | undefined =
        useContext(EfileNaicOptinsContext);

    const handleDateChange = (date: Dayjs | null) => {
        EfileNaicOptinsCtx?.setFormData({
            ...EfileNaicOptinsCtx.formData,
            selectedDate: date ? date.toISOString() : undefined,
        });
    };

    const handleSelectChange = (
        event: SelectChangeEvent<PAYMENT_TYPE>
    ): void => {
        EfileNaicOptinsCtx?.setFormData({
            ...EfileNaicOptinsCtx.formData,
            paymentType: event.target.value as PAYMENT_TYPE,
        });
    };

    const handleConsentChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        EfileNaicOptinsCtx?.setFormData({
            ...EfileNaicOptinsCtx.formData,
            consent: (event.target as HTMLInputElement).value as CONSENT_TYPE,
        });
    };

    const handleConsentAmendedChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        EfileNaicOptinsCtx?.setFormData({
            ...EfileNaicOptinsCtx.formData,
            consentAmended: event.target.checked,
        });
    };

    const totalAmount =
        EfileNaicOptinsCtx.formData.filingStatus?.originalAllocationPayment.reduce(
            (sum, transaction) => sum + transaction.amount,
            0
        );

    const fee =
        EfileNaicOptinsCtx.formData.filingStatus?.originalAllocationPayment.find(
            (it) => it.typeId === "NAIC OPTins Transaction Fee"
        )?.amount;
    const getPaymentInformation = (
        paymentType: PAYMENT_TYPE,
        filingType?: FILING_TYPE
    ) => {
        if (filingType === FILING_TYPE.ORIGINAL) {
            if (paymentType === PAYMENT_TYPE.CREDIT) {
                return (
                    <>
                        <PaymentInfoElectronicPaymentInformation
                            component={"fieldset"}
                        >
                            <legend>
                                <strong>Electronic Payment Information</strong>
                            </legend>
                            <span>
                                <strong>
                                    Payment Breakdown of Taxes and Fees Owed
                                </strong>
                            </span>
                            <>
                                {EfileNaicOptinsCtx.formData.filingStatus?.originalAllocationPayment.map(
                                    (transaction) => (
                                        <span key={transaction.typeId}>
                                            {transaction?.typeId}:{" "}
                                            <strong>
                                                $
                                                {transaction?.amount?.toFixed(
                                                    2
                                                )}
                                            </strong>
                                        </span>
                                    )
                                )}
                                <span>
                                    Total:{" "}
                                    <strong>${totalAmount?.toFixed(2)}</strong>
                                </span>
                            </>
                        </PaymentInfoElectronicPaymentInformation>
                        <PaymentInfoInstructions component={"fieldset"}>
                            <legend>
                                <strong>Instructions</strong>
                            </legend>
                            <p>
                                When paying by credit, upon succsessful
                                submission you will receive and addenda which
                                you will give to your bank to submit the
                                payment.
                            </p>
                            <p>
                                <strong>
                                    The filling is not considered submitted
                                    until the payment is processed.
                                </strong>{" "}
                                You will receive and acceptance email within 1
                                business day from when the payment is processed
                                by OPTins.
                            </p>
                        </PaymentInfoInstructions>
                    </>
                );
            }
            if (paymentType === PAYMENT_TYPE.DEBIT) {
                return (
                    <>
                        <PaymentInfoElectronicPaymentInformation
                            component={"fieldset"}
                        >
                            <legend>
                                <strong>Electronic Payment Information</strong>
                            </legend>
                            <span>
                                <strong>
                                    Payment Breakdown of Taxes and Fees Owed
                                </strong>
                            </span>
                            <>
                                {EfileNaicOptinsCtx.formData.filingStatus?.originalAllocationPayment.map(
                                    (transaction) => (
                                        <span key={transaction.typeId}>
                                            {transaction.typeId}:{" "}
                                            <strong>
                                                $
                                                {transaction?.amount?.toFixed(
                                                    2
                                                )}
                                            </strong>
                                        </span>
                                    )
                                )}
                                <span>
                                    Total:{" "}
                                    <strong>${totalAmount?.toFixed(2)}</strong>
                                </span>
                            </>
                        </PaymentInfoElectronicPaymentInformation>
                        <PaymentInfoInstructions component={"fieldset"}>
                            <legend>
                                <strong>Consent</strong>
                            </legend>
                            <p>
                                You are required to pay a transaction fee when
                                electronically filing through NAIC OPTins.
                            </p>
                            <FormControl>
                                <RadioGroup
                                    value={EfileNaicOptinsCtx?.formData.consent}
                                    onChange={handleConsentChange}
                                >
                                    <FormControlLabel
                                        value={CONSENT_TYPE.ALL_PAYMENTS}
                                        control={<Radio />}
                                        label={
                                            <span>
                                                I consent to electronic payment
                                                of{" "}
                                                <strong>
                                                    {" "}
                                                    {
                                                        CONSENT_TYPE.ALL_PAYMENTS
                                                    }{" "}
                                                </strong>{" "}
                                                of the payments listed above
                                                through NAIC OPTins at the time
                                                of my submission:{" "}
                                                <strong>
                                                    {" "}
                                                    ${totalAmount?.toFixed(
                                                        2
                                                    )}{" "}
                                                </strong>{" "}
                                                to be processed on
                                            </span>
                                        }
                                    />
                                    {EfileNaicOptinsCtx?.formData.consent ===
                                        CONSENT_TYPE.ALL_PAYMENTS &&
                                        totalAmount != null &&
                                        fee != null &&
                                        totalAmount - fee > 0 && (
                                            <Card className="p-6 w-full max-w-lg border shadow-lg">
                                                <CardContent>
                                                    <Stack spacing={2}>
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label="Select processing
                                                                date"
                                                                value={
                                                                    EfileNaicOptinsCtx
                                                                        ?.formData
                                                                        ?.selectedDate
                                                                        ? dayjs(
                                                                              EfileNaicOptinsCtx
                                                                                  .formData
                                                                                  .selectedDate
                                                                          )
                                                                        : null
                                                                }
                                                                onChange={
                                                                    handleDateChange
                                                                }
                                                                sx={{
                                                                    width: "100%",
                                                                    "& .MuiOutlinedInput-root":
                                                                        {
                                                                            borderRadius:
                                                                                "8px",
                                                                        },
                                                                }}
                                                            />
                                                        </LocalizationProvider>

                                                        {EfileNaicOptinsCtx
                                                            ?.formData
                                                            .consent ===
                                                            CONSENT_TYPE.ALL_PAYMENTS && (
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                            >
                                                                Please note that
                                                                full processing
                                                                of the payment
                                                                could take{" "}
                                                                <strong>
                                                                    2-5 business
                                                                    days
                                                                </strong>
                                                                . To avoid
                                                                potential late
                                                                filings and
                                                                payments, please
                                                                schedule
                                                                accordingly.
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                        )}
                                    {!EfileNaicOptinsCtx?.formData
                                        .paymentOnly && (
                                        <>
                                            <FormControlLabel
                                                value={CONSENT_TYPE.ONLY_NAIC}
                                                control={<Radio />}
                                                label={
                                                    <span>
                                                        I consent to electronic
                                                        payment of{" "}
                                                        <strong>
                                                            {
                                                                CONSENT_TYPE.ONLY_NAIC
                                                            }
                                                        </strong>{" "}
                                                        the NAIC OPTins
                                                        Transaction Fee at the
                                                        time of my submission:{" "}
                                                        <strong>
                                                            ${fee?.toFixed(2)}
                                                        </strong>
                                                    </span>
                                                }
                                            />
                                            <Card className="p-4 w-full max-w-lg border">
                                                <CardContent>
                                                    {EfileNaicOptinsCtx
                                                        ?.formData.consent ===
                                                        CONSENT_TYPE.ONLY_NAIC && (
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Because you have
                                                            chosen to pay{" "}
                                                            <strong>
                                                                ONLY
                                                            </strong>{" "}
                                                            the $
                                                            {fee?.toFixed(2)}{" "}
                                                            transaction fee at
                                                            this time, you will
                                                            need to log into the
                                                            OPTins website prior
                                                            to the due date to
                                                            remit payment for
                                                            the taxes and fees
                                                            listed above.
                                                        </Typography>
                                                    )}
                                                </CardContent>
                                            </Card>
                                        </>
                                    )}
                                </RadioGroup>
                            </FormControl>
                        </PaymentInfoInstructions>
                    </>
                );
            }
        }
        if (filingType === FILING_TYPE.AMENDED) {
            if (paymentType === PAYMENT_TYPE.CREDIT) {
                return (
                    <>
                        <PaymentInfoElectronicPaymentInformation
                            component={"fieldset"}
                        >
                            <legend>
                                <strong>Electronic Payment Information</strong>
                            </legend>
                            <p>
                                You have the option of electronically submitting
                                the positive change in the overall payment.
                                Please enter the amount in the box below.
                            </p>
                        </PaymentInfoElectronicPaymentInformation>
                        <PaymentInfoInstructions component={"fieldset"}>
                            <legend>
                                <strong>Instructions</strong>
                            </legend>
                            <p>
                                When paying by credit, upon succsessful
                                submission you will receive an addenda which you
                                will give to your bank to submit the payment.
                            </p>
                            <p>
                                <strong>
                                    The filling is not considered submitted
                                    until the payment is processed.
                                </strong>{" "}
                                You will receive an acceptance email within 1
                                business day from when the payment is processed
                                by OPTins.
                            </p>
                            <p>
                                <strong>
                                    Positive change in the overall payment:
                                </strong>
                            </p>
                            <NumericFormat
                                onValueChange={(values, sourceInfo) => {
                                    EfileNaicOptinsCtx?.setFormData({
                                        ...EfileNaicOptinsCtx.formData,
                                        overallPayment: values.floatValue || 0,
                                    });
                                }}
                                value={
                                    EfileNaicOptinsCtx?.formData.overallPayment
                                }
                                customInput={TextField}
                                thousandSeparator
                                prefix="$"
                                valueIsNumericString
                                variant="outlined"
                                size="small"
                            />
                            {(EfileNaicOptinsCtx?.formData.overallPayment ??
                                0) > 0 &&
                                (
                                    <AllocatePayments />
                                )}
                        </PaymentInfoInstructions>
                    </>
                );
            }
            if (paymentType === PAYMENT_TYPE.DEBIT) {
                return (
                    <>
                        <PaymentInfoElectronicPaymentInformation
                            component={"fieldset"}
                        >
                            <legend>
                                <strong>Electronic Payment Information</strong>
                            </legend>
                            <p>
                                You have the option of electronically submitting
                                the positive change in the overall payment.
                                Please enter the amount in the box below.
                            </p>
                        </PaymentInfoElectronicPaymentInformation>
                        <PaymentInfoInstructions component={"fieldset"}>
                            <legend>
                                <strong>Consent</strong>
                            </legend>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="agreement"
                                        checked={
                                            EfileNaicOptinsCtx?.formData
                                                .consentAmended
                                        }
                                        onChange={handleConsentAmendedChange}
                                    />
                                }
                                label="I consent to the electronic payment of the payment entered below through NAIC OPTins"
                            />
                            <p>
                                <strong>
                                    Positive change in the overall payment:
                                </strong>
                            </p>
                            <NumericFormat
                                onValueChange={(values, sourceInfo) => {
                                    EfileNaicOptinsCtx?.setFormData({
                                        ...EfileNaicOptinsCtx.formData,
                                        overallPayment: values.floatValue || 0,
                                    });
                                }}
                                value={
                                    EfileNaicOptinsCtx?.formData.overallPayment
                                }
                                customInput={TextField}
                                thousandSeparator
                                prefix="$"
                                valueIsNumericString
                                variant="outlined"
                                size="small"
                            />
                            {(EfileNaicOptinsCtx?.formData.overallPayment ??
                                0) > 0 &&
                                EfileNaicOptinsCtx?.formData.consentAmended &&
                                 (
                                    <AllocatePayments />
                                )}
                        </PaymentInfoInstructions>
                    </>
                );
            }
        }
    };

    return (
        <PaymentInfoStyled>
            <PaymentInfoSelectorContainer component={"fieldset"}>
                <legend>
                    <strong>Select payment type</strong>
                </legend>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={EfileNaicOptinsCtx?.formData.paymentType}
                        onChange={handleSelectChange}
                        MenuProps={{
                            disablePortal: true,
                        }}
                    >
                        <MenuItem value={PAYMENT_TYPE.DEBIT}>
                            {PAYMENT_TYPE.DEBIT}
                        </MenuItem>
                        <MenuItem value={PAYMENT_TYPE.CREDIT}>
                            {PAYMENT_TYPE.CREDIT}
                        </MenuItem>
                    </Select>
                </FormControl>
            </PaymentInfoSelectorContainer>
            {getPaymentInformation(
                EfileNaicOptinsCtx?.formData.paymentType,
                EfileNaicOptinsCtx?.formData.filingType
            )}
        </PaymentInfoStyled>
    );
};
