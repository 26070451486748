import {
    Button,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import Loader from "src/components/Loader/Loader";
import ModalError from "src/components/ModalError/ModalError";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AccountService } from "src/services";
import { FixMeLater } from "src/types";
import {
    StyledFlexContainer,
    StyledForm,
    StyledFormContainer,
    StyledModalContainer,
} from "../../../../AccountAdmin.styled";
import "./AddProductModal.scss";

interface AddProductModalProps {
    open: boolean;
    onClose: () => void;
}

const AddProductModal: FC<AddProductModalProps> = ({ open, onClose }) => {
    const accountService = AccountService.getInstance();
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const selectedAccount = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccount
    );
    const selectedAccountProducts = useAppSelector(
        (state) => state[product?.productName]?.value?.selectedAccountProducts
    );

    const initialFormData: FixMeLater = {
        productName: ProductNameToIdMap.keys().next().value,
        taxYear: new Date().getFullYear(),
        companyOrClickQuantity: 1,
        userQuantity: 1,
        jurisdictionQuantity: 1,
    };

    const [formData, setFormData] = useState<FixMeLater>(initialFormData);

    const [validationErrors, setValidationErrors] = useState({
        taxYear: "",
        companyOrClickQuantity: "",
        userQuantity: "",
        jurisdictionQuantity: "",
    });

    const handleInputChange = (e: FixMeLater) => {
        const { name, value } = e.target;
        if (name === "productName") setFormData({ ...formData, [name]: value });
        else {
            const numericValue = parseFloat(value);
            setFormData({ ...formData, [name]: numericValue });

            if (numericValue <= 0) {
                setValidationErrors({
                    ...validationErrors,
                    [name]: `${(
                        name.charAt(0).toUpperCase() + name.slice(1)
                    ).replace(/([a-z])([A-Z])/g, "$1 $2")} cannot be negative.`,
                });
            } else {
                setValidationErrors({
                    ...validationErrors,
                    [name]: "",
                });
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const newProduct = {
            ...formData,
            accountId: selectedAccount.id,
            productId: ProductNameToIdMap.get(formData.productName),
            expirationDate: [formData.taxYear, 12, 31],
            isTrial: false,
            productTypeId: "BASIC",
        };

        try {
            setIsLoading(true);
            await accountService.setProductsToAccount([newProduct]);
            dispatch(
                GlobalStateActions[
                    product?.productName
                ].setSelectedAccountProducts([
                    ...selectedAccountProducts,
                    newProduct,
                ])
            );

            onClose();
            setSnackbarOpen(true);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setError(null);
        onClose();
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel}>
                <div>
                    <StyledModalContainer>
                        <StyledFormContainer>
                            <Typography variant="h5">Add Product</Typography>
                            <StyledForm onSubmit={handleSubmit}>
                                <Select
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="productName"
                                    name="productName"
                                    value={formData.productName}
                                    onChange={handleInputChange}
                                >
                                    {Array.from(ProductNameToIdMap.keys())?.map(
                                        (productName) => (
                                            <MenuItem
                                                key={productName}
                                                value={productName}
                                            >
                                                {productName}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="taxYear"
                                    label="Tax Year"
                                    name="taxYear"
                                    type="number"
                                    value={formData.taxYear}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.taxYear}
                                    helperText={validationErrors.taxYear}
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="companyOrClickQuantity"
                                    label="Company Or Click Quantity"
                                    name="companyOrClickQuantity"
                                    type="number"
                                    value={formData.companyOrClickQuantity}
                                    onChange={handleInputChange}
                                    error={
                                        !!validationErrors.companyOrClickQuantity
                                    }
                                    helperText={
                                        validationErrors.companyOrClickQuantity
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="userQuantity"
                                    label="User Quantity"
                                    name="userQuantity"
                                    type="number"
                                    value={formData.userQuantity}
                                    onChange={handleInputChange}
                                    error={!!validationErrors.userQuantity}
                                    helperText={validationErrors.userQuantity}
                                />
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="jurisdictionQuantity"
                                    label="Jurisdiction Quantity"
                                    name="jurisdictionQuantity"
                                    type="number"
                                    value={formData.jurisdictionQuantity}
                                    onChange={handleInputChange}
                                    error={
                                        !!validationErrors.jurisdictionQuantity
                                    }
                                    helperText={
                                        validationErrors.jurisdictionQuantity
                                    }
                                />

                                <StyledFlexContainer justify="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !!validationErrors.taxYear ||
                                            !!validationErrors.companyOrClickQuantity ||
                                            !!validationErrors.userQuantity ||
                                            !!validationErrors.jurisdictionQuantity
                                        }
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </StyledFlexContainer>
                            </StyledForm>
                        </StyledFormContainer>
                        {isLoading && <Loader />}
                        {error && <ModalError error={error} />}
                    </StyledModalContainer>
                </div>
            </Modal>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message="New product added successfully!"
                severity="success"
            />
        </>
    );
};

export default AddProductModal;
