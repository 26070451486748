import React from "react";
import { Chip } from "@mui/material";
import { StyledLayoutPaneTab } from "./Tab.styled";

/**
 * LayoutPaneTab component is a custom Tab component for LayoutPane
 */
export const LayoutPaneTab = ({ children, selectedTab, ...props }) => {
    const isSelected = selectedTab === props.value;
    return (
        <StyledLayoutPaneTab
            label={
                <Chip
                    label={children}
                    variant="outlined"
                />
            }
            $isSelected={isSelected}
            {...props}
        />
    );
};
