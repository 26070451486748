import React from "react";
import {
    CONSENT_TYPE,
    FILING_TYPE,
    OptinsAllocationTypesResponse,
    OptinsCompanyType,
    OptinsCompareResponse,
    OptinsDeleteResponse,
    OptinsFilingStatus,
    OptinsInfo,
    PAYMENT_TYPE,
    SubmitFilingResponse,
} from "src/types";

export type EfileNaicOptinsFormData = {
    agreement: boolean;
    paymentType: PAYMENT_TYPE;
    filingType?: FILING_TYPE;
    optinsInfo?: OptinsInfo;
    selectedCompanyType?: OptinsCompanyType;
    filingStatus?: OptinsFilingStatus;
    testingYear?: number;
    consent?: CONSENT_TYPE;
    consentAmended?: boolean;
    overallPayment: number;
    allocatedPayments: {
        AnnualPremiumTax: number;
        QuarterlyEstimatedPremiumTax: number;
        AnnualPenalty: number;
        ReviewFees: number;
        LicenseFees: number;
        FireMarshalTax: number;
        RetaliatoryTax: number;
        Interest: number;
        QuarterlyPenalty: number;
    };
    filingDeleteStatus?: OptinsDeleteResponse;
    allocationTypes?: OptinsAllocationTypesResponse;
    submitResponse?: SubmitFilingResponse;
    reset: boolean;
    selectedDate?: string;
    allocatePaymentsTotal: number;
    compare : OptinsCompareResponse[];
    paymentOnly: boolean
};

export const initialEfileNaicOptinsFormData: EfileNaicOptinsFormData = {
    agreement: false,
    paymentType: PAYMENT_TYPE.CREDIT,
    filingType: undefined,
    optinsInfo: undefined,
    selectedCompanyType: undefined,
    filingStatus: undefined,
    consent: CONSENT_TYPE.ALL_PAYMENTS,
    consentAmended: false,
    overallPayment: 0,
    allocatedPayments: {
        AnnualPremiumTax: 0,
        QuarterlyEstimatedPremiumTax: 0,
        AnnualPenalty: 0,
        ReviewFees: 0,
        LicenseFees: 0,
        FireMarshalTax: 0,
        RetaliatoryTax: 0,
        Interest: 0,
        QuarterlyPenalty: 0,
    },
    filingDeleteStatus: undefined,
    allocationTypes: undefined,
    submitResponse: undefined,
    reset: false,
    selectedDate: new Date().toISOString(),
    allocatePaymentsTotal:0,
    compare: [],
    paymentOnly: false
};

export interface EfileNaicOptinsContextState {
    formData: EfileNaicOptinsFormData;
    setFormData: React.Dispatch<React.SetStateAction<EfileNaicOptinsFormData>>;
    isFetchingFilingStatus: boolean;
    isDeleting: boolean;
    deleteFiling: () => Promise<void>;
    onReset: () => void;
}

const initialEfileNaicOptinsContextState: EfileNaicOptinsContextState = {
    formData: initialEfileNaicOptinsFormData,
    setFormData: () => {},
    isFetchingFilingStatus: false,
    isDeleting: false,
    deleteFiling: async () => {},
    onReset: () => {},
}

export const EfileNaicOptinsContext = React.createContext<
    EfileNaicOptinsContextState
>(initialEfileNaicOptinsContextState);
