import { Box, Select } from "@mui/material";
import { styled } from "styled-components";

export const CellBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
});

interface StyledSelectProps {
    overrideValue: string;
    defaultValue: string;
}

export const StyledSelect = styled(Select<string>)<StyledSelectProps>(({ overrideValue, defaultValue }) => {
    const isOverride = overrideValue !== "";
    const isDifferentFromDefault = isOverride && overrideValue !== defaultValue;

    return {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: isDifferentFromDefault ? 'red' : 'lightgray',
        },
        '&:hover .MuiOutlinedInput-notchedOutline, &:focus .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: isDifferentFromDefault ? 'red' : 'blue',
        }
    };
});
