import styled from "styled-components";

export const StyledFixedWidthContainer = styled.div`
    height: 100%;
    margin-right: 40px;
`;

export const StyledFieldsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
        width: 150px;
    }
`;

export const StyledScrollableContainer = styled.div`
    overflow: auto;
    height: 100%;
`;
