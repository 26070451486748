import React, { FC, MouseEvent, useState } from "react";
import "./BulkExport.scss";
import { StyledDarkOutlinedButton, StyledDarkTextButton, StyledTextButton } from "src/products/Allocator/Allocator.styled";
import { ALLOCATOR_TAX_DIST_OPTIONS, AllocatorExportOption, AllocatorJob, AllocatorJobsExportOptions, FixMeLater } from "src/types";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorService } from "src/services";
import { downloadFile } from "src/utils";
import { useSnackbar } from "notistack";
import { Menu, MenuItem } from "@mui/material";
import TaxDistribution from "../../../TaxDistribution/TaxDistribution";
import ConfirmationDialog from "../../../ConfirmationDialog/ConfirmationDialog";

interface BulkExportProps {
    isDragActive: boolean;
    exportOptions?: AllocatorJobsExportOptions;
}

const BulkExport: FC<BulkExportProps> = ({ isDragActive, exportOptions }) => {
    const allocatorService = AllocatorService.getInstance();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [taxDistributionOption, setTaxDistributionOption] = useState<ALLOCATOR_TAX_DIST_OPTIONS>(ALLOCATOR_TAX_DIST_OPTIONS.STOCK);
    const [isMutualChecked, setIsMutualChecked] = useState<boolean>(false);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const jobList: AllocatorJob[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.jobList
    );
    const checkedJobState = jobList?.find(job => job.checked)?.stateAbbr;
    const checkedJobIds = jobList?.reduce((filtered: number[], job: AllocatorJob) => {
        if (job?.checked) {
            filtered.push(job?.id)
        }
        return filtered;
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleExportClick = async (event: MouseEvent<HTMLElement>) => {
        const exportParam = String(event?.currentTarget?.dataset?.name);
        handleCloseMenu(event);
        if (exportParam === "taxDistribution") {
            setSelectedOption(exportParam);
            setOpenDialog(true);
        } else handleExport(exportParam);
    };

    const handleExport = async (
        exportParam: string,
        taxDistributionOption?: ALLOCATOR_TAX_DIST_OPTIONS,
        additionalOption?: string,
    ) => {
        setIsLoading(true);
        handleCloseDialog();

        try {
            const reportDownloadable = await allocatorService.generateSelectedReports(
                checkedJobIds,
                exportParam,
                taxDistributionOption,
                additionalOption,
            );
            const { blob, contentDispositionHeader } = reportDownloadable;
            downloadFile(blob, contentDispositionHeader.split("filename=")[1]);
            enqueueSnackbar("Exported successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to download reports", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        dispatch(
            GlobalStateActions[product?.productName].setJobList(
                jobList?.map(job => {
                    return {
                        ...job,
                        checked: false
                    }
                })
            )
        );
    };

    const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event?.currentTarget);
    };

    const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const actionConfirm = {
        text: "Confirm",
        action: () => handleExport(
            selectedOption,
            taxDistributionOption,
            isMutualChecked ? "INCLUDE" : "EXCLUDE",
        ),
    };

    const actionCancel = {
        text: "Cancel",
        action: handleCloseDialog,
    };

    if (!checkedJobIds?.length || isDragActive) return null;

    return (
        <>
            <div className="bulk-export-container">
                <StyledDarkTextButton
                    disabled={isLoading}
                    onClick={handleCancel}
                >
                    Cancel
                </StyledDarkTextButton>
                <StyledDarkOutlinedButton
                    variant="outlined"
                    loading={isLoading}
                    disabled={!!checkedJobState && !exportOptions?.[checkedJobState]?.length}
                    onClick={handleClickOpen}
                >
                    Export ({checkedJobIds?.length})
                </StyledDarkOutlinedButton>
                <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handleCloseMenu}
                    onContextMenu={handleCloseMenu}
                >
                    {!!checkedJobState && exportOptions?.[checkedJobState]?.map(
                        (option: AllocatorExportOption, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={handleExportClick}
                                    data-name={option?.name}
                                >
                                    {option?.displayName}
                                </MenuItem>
                            )
                        }
                    )}
                </Menu>
            </div>
            <ConfirmationDialog
                open={openDialog}
                title={"Select Tax Distribution Options"}
                content={
                    <TaxDistribution 
                        taxDistributionOption={taxDistributionOption}
                        setTaxDistributionOption={setTaxDistributionOption}
                        isMutualChecked={isMutualChecked}
                        setIsMutualChecked={setIsMutualChecked}
                    />
                }
                actionPrimary={actionConfirm}
                actionSecondary={actionCancel}
                handleClose={handleCloseDialog}
            />
        </>
    );
};

export default BulkExport;
