export interface OptinsPayload {
    companyId: number | string;
    productId: number | string;
    taxYearId: number | string;
    folderId: number | string;
    moduleId: number | string;
    returnId: number | string;
    retalFolderId: number | string;
}

export interface OptinsCompareResponse {
    fieldName: string | null,
    cell: string,
    excelValue: string,
    stratusValue: string | null
}


export type FilingStatus = "PENDING" | "PROCESSED" | "SCHEDULED" | "FAILED";


export interface PendingPayment {
    amount: number;
    submitByFullName: string;
    dateCreated: number;
    dateSubmitted: string;
    paymentMethod: PAYMENT_TYPE;
    status: string;
    additionalPayment: string;
}

export interface OptinsFilingStatus {
    response: {
        trackingNumber: string;
        versionId: string;
    } | null;
    filingStatus: string,
    pendingPayments: PendingPayment[],
    clientError: {
        code: string;
        message: string;
    } | null;
    serverError: string | null;
    originalAllocationPayment : Allocation[]
}

export interface OptinsDeleteResponse {
    message: string;
    success: string;
    clientError: {
        code: string;
        message: string;
    };
    serverError: string;
}

export interface OptinsInfo{
     companyTypes:OptinsCompanyType[],
     companyName: string,
     fein: string,
     naic: string,
     companyNaicGroup: string,
     optinsYear: string,
     defaultCompanyType: OptinsCompanyType,
     userName: string
}

export interface OptinsCompanyType {
    identifier: string;
    name: string;
    additionalInfo: string;
}

export interface AllocationType {
    typeId: string;
    name: string;
    description: string;
    displayIndex: number;
    defaultAllocation: boolean;
    defaultPaymentAmount: string;
}


export interface Allocation{
    typeId: string;
    amount: number;
}

export interface Allocations {
    types: AllocationType[];
}

export interface OptinsAllocationTypesResponse {
    allocations: Allocations;
    clientError?: {
        code: string;
        message: string;
    };
    serverError?: string;
}

export interface SubmitFilingPayload {
    returnDocumentIdDTO: OptinsPayload;
    naicCompanyTypeId: string;
    testingYear: null | number;
    paymentMethod: PAYMENT_TYPE;
    types: AllocationType[];
    didConsentToAll: boolean;
    naicCompanyTypeName: string;
    selectedDate: string | undefined
    paymentOnly: boolean
}

export interface SubmitFilingResponse {
    response: {
        trackingNumber: string;
        versionId: string;
    };
    filingStatus: {
        filingStatus: FilingStatus;
        expectedReleaseDate: string;
    };
    achBankMemo: string;
    clientError?: {
        code: string;
        message: string;
    };
    serverError?: {
        code: string;
        message: string;
    };
}

export enum PAYMENT_TYPE {
    DEBIT = "DEBIT",
    CREDIT = "CREDIT",
}

export enum FILING_TYPE {
    ORIGINAL = "Original",
    AMENDED = "Amended",
}

export enum CONSENT_TYPE {
    ALL_PAYMENTS = "ALL",
    ONLY_NAIC = "ONLY",
}