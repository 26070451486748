import React, { FC, MouseEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { AllocatorService } from "src/services";
import { ALLOCATOR_SEARCH_PARAMS, ALLOCATOR_TAX_DIST_OPTIONS, AllocatorReportExportOption, FixMeLater } from "src/types";
import { useAppSelector } from "src/hooks";
import { StyledDarkOutlinedButton } from "src/products/Allocator/Allocator.styled";
import { downloadFile } from "src/utils";
import ConfirmationDialog from "../../../ConfirmationDialog/ConfirmationDialog";
import TaxDistribution from "../../../TaxDistribution/TaxDistribution";

const ReportExportButton: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [taxDistributionOption, setTaxDistributionOption] = useState<ALLOCATOR_TAX_DIST_OPTIONS>(ALLOCATOR_TAX_DIST_OPTIONS.STOCK);
    const [isMutualChecked, setIsMutualChecked] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const exportOptions: AllocatorReportExportOption[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.exportOptions
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (exportOptions?.length === 1 && exportOptions[0]?.name) {
            handleExportReport(exportOptions[0].name);
        } else setAnchorEl(event?.currentTarget);
    };

    const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOptionClick = async (event: MouseEvent<HTMLElement>) => {
        const exportParam = String(event?.currentTarget?.dataset?.name);
        handleCloseMenu(event);
        if (exportParam === "taxDistribution") {
            setSelectedOption(exportParam);
            setOpenDialog(true);
        } else handleExportReport(exportParam);
    };

    const handleExportReport = async (
        exportParam: string,
        taxDistributionOption?: ALLOCATOR_TAX_DIST_OPTIONS,
        additionalOption?: string,
    ) => {
        setIsLoading(true);
        handleCloseDialog();
        try {
            const reportDownloadable = await allocatorService.generateImpReport(
                exportParam,
                {
                    jobId: Number(jobId),
                },
                taxDistributionOption,
                additionalOption,
            );
            const { blob, contentDispositionHeader } = reportDownloadable;
            downloadFile(blob, contentDispositionHeader.split("filename=")[1]);
            enqueueSnackbar("Exported successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to download report", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const actionConfirm = {
        text: "Confirm",
        action: () => handleExportReport(
            selectedOption,
            taxDistributionOption,
            isMutualChecked ? "INCLUDE" : "EXCLUDE",
        ),
    };

    const actionCancel = {
        text: "Cancel",
        action: handleCloseDialog,
    };

    return (
        <>
            <StyledDarkOutlinedButton
                sx={{marginRight: "24px"}}
                variant="outlined"
                loading={isLoading}
                disabled={!exportOptions?.length}
                onClick={handleClickOpen}
            >
                Export .imp
            </StyledDarkOutlinedButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseMenu}
                onContextMenu={handleCloseMenu}
            >
                {exportOptions?.map(
                    (option: AllocatorReportExportOption, index: number) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={handleOptionClick}
                                data-name={option.name}
                            >
                                {option.displayName}
                            </MenuItem>
                        );
                    }
                )}
            </Menu>
            <ConfirmationDialog
                open={openDialog}
                title={"Select Tax Distribution Options"}
                content={
                    <TaxDistribution 
                        taxDistributionOption={taxDistributionOption}
                        setTaxDistributionOption={setTaxDistributionOption}
                        isMutualChecked={isMutualChecked}
                        setIsMutualChecked={setIsMutualChecked}
                    />
                }
                actionPrimary={actionConfirm}
                actionSecondary={actionCancel}
                handleClose={handleCloseDialog}
            />
        </>
    );
};

export default ReportExportButton;
