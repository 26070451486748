import React, { FC, MouseEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid-premium";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { ALLOCATOR_SEARCH_PARAMS, ConfirmationDialogSettings, ContextMenuPosition, FixMeLater, REPORT_EDIT_FIELDS } from "src/types";
import { AllocatorService } from "src/services";
import { useSnackbar } from "notistack";
import ConfirmationDialog from "../../../ConfirmationDialog/ConfirmationDialog";

interface ReportContextMenuProps {
    position: ContextMenuPosition | null;
    selectedRow: GridRowModel;
    handleCloseReportContextMenu: () => void;
}

const ReportContextMenu: FC<ReportContextMenuProps> = ({
    position,
    selectedRow,
    handleCloseReportContextMenu,
}) => {
    const allocatorService = AllocatorService.getInstance();

    const [open, setOpen] = useState<boolean>(false);
    const [dialogSettings, setDialogSettings] = useState<ConfirmationDialogSettings>();
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const allocateToSourceCity = async () => {
        handleClose();

        try {
            await allocatorService.addressForceAllocate(
                Number(jobId),
                selectedRow?.id
            );
            enqueueSnackbar("Address allocated", { variant: "success" });
            dispatch(
                GlobalStateActions[product?.productName].setToogleFetchReport()
            );
        } catch (error) {
            enqueueSnackbar("Address could not be allocated", { variant: "error" });
        }
    };

    const deleteSingleAddress = async () => {
        handleClose();

        try {
            await allocatorService.deleteSingleAddress(
                Number(jobId),
                selectedRow?.id
            );
            dispatch(
                GlobalStateActions[product?.productName].setToogleFetchReport()
            );
        } catch (error) {
            enqueueSnackbar("Address could not be deleted", { variant: "error" });
        }
    };

    const openAllocateConfirmation = () => {
        setOpen(true);
        setDialogSettings({
            title: "Allocate to Normal City",
            content: [
                "The following address will be used to allocate the Premiums for this policy: ",
                `${selectedRow[REPORT_EDIT_FIELDS.NORMAL_STREET]?.value}`,
                `${selectedRow[REPORT_EDIT_FIELDS.NORMAL_CITY]?.value}, ${selectedRow[REPORT_EDIT_FIELDS.NORMAL_STATE]?.value} ${selectedRow[REPORT_EDIT_FIELDS.NORMAL_ZIP]?.value}`,
                "Do you want to continue?"
            ],
            actionPrimary: {
                text: "Allocate",
                action: allocateToSourceCity,
            },
            actionSecondary: {
                text: "Cancel",
                action: handleClose,
            },
        })
    };

    const openDeleteConfirmation = () => {
        setOpen(true);
        setDialogSettings({
            title: "Confirm Delete",
            content: "You are about to delete 1 record(s). If you click yes, you won’t be able to undo this operation. Are you sure you want to proceed?",
            actionPrimary: {
                text: "Yes",
                action: deleteSingleAddress,
            },
            actionSecondary: {
                text: "No",
                action: handleClose,
            },
        })
    };

    const handleClose = () => {
        setOpen(false);
        handleCloseReportContextMenu();
    };

    return (
        <>
            <Menu
                open={position !== null}
                onClose={handleCloseReportContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    position !== null
                        ? { top: position?.mouseY, left: position?.mouseX }
                        : undefined
                }
                slotProps={{
                    root: {
                        onContextMenu: (e: MouseEvent) => {
                            e.preventDefault();
                            handleCloseReportContextMenu();
                        },
                    },
                }}
            >
                <MenuItem onClick={openAllocateConfirmation}>
                    Allocate to Source City
                </MenuItem>
                <MenuItem onClick={openDeleteConfirmation}>
                    Delete record
                </MenuItem>
            </Menu>
            <ConfirmationDialog
                open={open}
                title={dialogSettings?.title}
                content={dialogSettings?.content}
                actionPrimary={dialogSettings?.actionPrimary}
                actionSecondary={dialogSettings?.actionSecondary}
                handleClose={handleClose}
            />
        </>
    );
};

export default ReportContextMenu;
