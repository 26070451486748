import React, { useEffect, useRef, useState } from "react";
import {
    ActionButtonStyled,
    EfileNaicOptinsActionContainerStyled,
    EfileNaicOptinsErrorStyled,
    EfileNaicOptinsStyled,
    ErrorContainer,
    Title,
    ErrorList,
    ErrorItem,
} from "./EfileNaicOptins.styled";
import {
    LayoutPaneTab as Tab,
    LayoutPaneTabs as Tabs,
    LayoutPaneTabPanel as TabPanel,
} from "src/uikit";
import {
    ArrowBack,
    ArrowCircleLeftOutlined,
    ArrowCircleRightOutlined,
    ArrowForward,
    Send,
    TableChartOutlined,
} from "@mui/icons-material";
import { UseAgreement } from "./Sections/UseAgreement";
import Loader from "../Loader/Loader";
import { Alert, Box, Grid, Tooltip } from "@mui/material";
import {
    EfileNaicOptinsContext,
    EfileNaicOptinsFormData,
    initialEfileNaicOptinsFormData,
} from "./EfileNaicOptins.context";
import { PaymentInfo } from "./Sections/PaymentInfo";
import { CompanyType } from "./Sections/CompanyType";
import { OptinsService } from "src/services/OptinsService";
import {
    AllocationType,
    CONSENT_TYPE,
    FILING_TYPE,
    FixMeLater,
    OptinsCompareResponse,
    OptinsDeleteResponse,
    OptinsPayload,
    PAYMENT_TYPE,
    ReturnNode,
    TabProperties,
    Tree,
    ValidationResponse,
} from "src/types";
import { SubmitResponse } from "./Sections/SubmitResponse";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import Compare from "../OptinsExport/components/Compare/Compare";
import { downloadFile } from "src/utils/DownloadFiles";
import { HttpError } from "src/utils/HttpError";

export type EfileNaicOptinsProps = {
    open: boolean;
    onClose: () => void;
    payload: OptinsPayload;
    returnNode: ReturnNode;
    tabProperties: TabProperties;
    onEfiled: () => void;
    validationResponse: ValidationResponse | null;
    paymentOnly: boolean;
};

enum EfileNaicOptinsTabs {
    COMPARE = 0,
    COMPANY_TYPE = 1,
    USE_AGREEMENT = 2,
    PAYMENT_INFO = 3,
}

export const EfileNaicOptins: React.FC<EfileNaicOptinsProps> = ({
    open,
    payload,
    returnNode,
    tabProperties,
    onEfiled,
    validationResponse,
    paymentOnly = false,
}) => {
    const dispatch = useAppDispatch();

    // Services instances
    const optinsService = OptinsService.getInstance();

    // State for tabs
    const [tab, setTab] = useState<EfileNaicOptinsTabs>(
        EfileNaicOptinsTabs.COMPANY_TYPE
    );

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const tree: Tree = useAppSelector(
        (state) => state[product?.productName]?.value?.tree
    );

    // States when doing API calls
    const [isFetchingCompanyTypes, setIsFetchingCompanyTypes] =
        useState<boolean>(false);
    const [isFetchingFilingStatus, setIsFetchingFilingStatus] =
        useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    // Form data
    const [formData, setFormData] = useState<EfileNaicOptinsFormData>(
        initialEfileNaicOptinsFormData
    );

    // Error state
    const [error, setError] = useState<string | null>(null);

    // GETTERS AND VALIDATORS

    const getIsLoading = () => {
        return (
            isFetchingCompanyTypes ||
            isFetchingFilingStatus ||
            isDeleting ||
            isSubmitting
        );
    };

    // Determine if the next button should be disabled
    const isNextDisabled = (currentTab: EfileNaicOptinsTabs) => {
        if (getIsLoading()) return true;

        let statusInfo = formData.filingStatus?.filingStatus;

        switch (currentTab) {
            case EfileNaicOptinsTabs.COMPARE: {
                return false;
            }
            case EfileNaicOptinsTabs.COMPANY_TYPE: {
                const condition1 =
                    formData.selectedCompanyType === undefined ||
                    !formData.selectedCompanyType.identifier 
                    || statusInfo?.includes("PENDING");
                return condition1;
            }
            case EfileNaicOptinsTabs.USE_AGREEMENT:
                return !formData.agreement;
            case EfileNaicOptinsTabs.PAYMENT_INFO:
                return true;
            default:
                return true;
        }
    };

    // Determine if the previous button should be disabled
    const isPrevDisabled = () => {
        if (getIsLoading()) return true;

        return (
            (formData.compare.length === 0 &&
                tab === EfileNaicOptinsTabs.COMPANY_TYPE) ||
            tab === EfileNaicOptinsTabs.COMPARE
        );
    };

    // Perform validation to determine if the submit button should be disabled
    const canSubmit = () => {
        if (getIsLoading()) return false;

        if (tab !== EfileNaicOptinsTabs.PAYMENT_INFO) return false;

        if (!formData.agreement) return false;

        if (formData.filingType === FILING_TYPE.ORIGINAL) {
            return formData.consent;
        }
        if (formData.filingType === FILING_TYPE.AMENDED) {
            if (!paymentOnly) {
                if (formData.paymentType === PAYMENT_TYPE.DEBIT) {
                    return (
                        (formData.consentAmended &&
                            formData.overallPayment > 0 &&
                            formData.overallPayment ===
                                formData.allocatePaymentsTotal) ||
                        (!formData.consentAmended &&
                            formData.overallPayment === 0)
                    );
                }
                if (formData.overallPayment === formData.allocatePaymentsTotal)
                    return true;
            } else {
                if (formData.paymentType === PAYMENT_TYPE.DEBIT) {
                    return (
                        formData.consentAmended && formData.overallPayment > 0 && formData.overallPayment === formData.allocatePaymentsTotal
                    );
                }
                if (formData.overallPayment > 0 && formData.overallPayment === formData.allocatePaymentsTotal) return true;
            }
        }
        return false;
    };

    // EVENT HANDLERS

    const onTabChange = (event, newValue: number) => {
        setTab(newValue);
    };

    const onPrevClick = () => {
        if (tab > EfileNaicOptinsTabs.COMPARE) {
            setTab(tab - 1);
        }
    };

    const onNextClick = () => {
        if (tab < EfileNaicOptinsTabs.PAYMENT_INFO) {
            setTab(tab + 1);
        }
    };

    const onReset = () => {
        setFormData((prev) => ({
            ...initialEfileNaicOptinsFormData,
            optinsInfo: prev.optinsInfo,
            selectedCompanyType: prev.selectedCompanyType,
            testingYear: prev.testingYear,
            compare: prev.compare,
            paymentOnly:prev.paymentOnly
        }));
        setTab(EfileNaicOptinsTabs.COMPANY_TYPE);
        recallfetchFilingStatus.current = true;
    };

    const fetchCompanyTypes = async () => {
        setIsFetchingCompanyTypes(true);
        setError(null);

        try {
            const optinsInfo = await optinsService.getCompanyTypesByState(
                payload
            );
            setFormData((prev) => ({
                ...prev,
                optinsInfo,
                selectedCompanyType:
                    optinsInfo?.defaultCompanyType || prev.selectedCompanyType,
                testingYear: optinsInfo?.optinsYear
                    ? Number(optinsInfo.optinsYear)
                    : prev.testingYear,
                compare: prev.compare,
            }));
        } catch (err) {
            if (err instanceof HttpError) {
                let errorMessage = "An unknown error occurred.";

                try {
                    const errorBody =
                        typeof err.body === "string"
                            ? JSON.parse(err.body)
                            : err.body;

                    if (
                        errorBody &&
                        typeof errorBody === "object" &&
                        "message" in errorBody
                    ) {
                        errorMessage = errorBody.message as string;
                    }
                } catch (parseError) {
                    console.error("Error parsing response:", parseError);
                }

                setError(errorMessage);
            } else {
                setError(
                    "An unexpected error occurred while fetching company types."
                );
            }
        } finally {
            setIsFetchingCompanyTypes(false);
        }
    };

    const fetchCompare = async () => {
        try {
            const optinsCompareResponse: OptinsCompareResponse[] =
                await optinsService.compare(payload);

            setFormData((prev) => ({
                ...prev,
                compare: optinsCompareResponse,
            }));
        } catch (err) {
            setError("Failed to fetch compare. Please try again.");
        }
    };

    useEffect(() => {
        console.log("Compare length   ", formData.compare.length);
        if (formData.compare.length > 0) {
            setTab(EfileNaicOptinsTabs.COMPARE);
        }
    }, [formData.compare]);

    const fetchFilingStatus = async () => {
        setIsFetchingFilingStatus(true);
        setError(null);

        try {
            const companyTypeIdentifier =
                formData.selectedCompanyType?.identifier || "";
            const optinsYear = formData.testingYear?.toString() || "";

            if (!companyTypeIdentifier) {
                throw new Error("No company type selected");
            }

            const filingStatus = await optinsService.findFilling({
                returnDocumentIdDTO: payload,
                naicCompanyTypeId: companyTypeIdentifier,
                testingYear: optinsYear,
            });

            const filingType = filingStatus?.response?.trackingNumber
                ? FILING_TYPE.AMENDED
                : FILING_TYPE.ORIGINAL;
            setFormData({
                ...formData,
                filingDeleteStatus: undefined,
                filingStatus,
                filingType: filingType,
            });
        } catch (err) {
            setError("Failed to fetch filing status. Please try again.");
        } finally {
            setIsFetchingFilingStatus(false);
        }
    };

    const fetchAllocationTypes = async () => {
        try {
            const companyTypeIdentifier =
                formData.selectedCompanyType?.identifier || "";
            const optinsYear = formData.testingYear?.toString() || "";

            if (!companyTypeIdentifier) {
                throw new Error("No company type selected");
            }

            const allocationTypes = await optinsService.getAllocationTypes(
                payload,
                companyTypeIdentifier,
                optinsYear
            );
            setFormData({
                ...formData,
                allocationTypes,
            });
        } catch (err) {
            setError("Failed to fetch fetchAllocationTypes. Please try again.");
        }
    };

    const deleteFiling = async () => {
        setFormData({
            ...formData,
            filingDeleteStatus: undefined,
        });
        const trackingId = formData.filingStatus?.response?.trackingNumber;
        if (trackingId) {
            try {
                setIsDeleting(true);
                const response: OptinsDeleteResponse =
                    await optinsService.delete(payload, trackingId);
                setFormData({
                    ...formData,
                    filingDeleteStatus: response,
                });
                if (
                    tabProperties &&
                    response.message.includes("Successfully deleted filing")
                ) {
                    const updatedTabProperties: TabProperties = {
                        ...tabProperties,
                        submissionId: "",
                    };
                    dispatch(GlobalStateActions.setTab(updatedTabProperties));
                    updateReturnNodeInTree({
                        ...returnNode,
                        submissionId: "",
                    });
                }
            } catch (err) {
                setError("Failed to delete filing. Please try again.");
            } finally {
                setIsDeleting(false);
            }
        } else {
            setError("Failed to delete filing, no tracking number");
        }
    };

    const handleExport = async () => {
        try {
            const response = await optinsService.export(payload);

            let fileName = "OPTinsExport.xlsx";
            const contentDispositionHeader = response.headers.get(
                "Content-Disposition"
            );

            if (contentDispositionHeader) {
                const match =
                    contentDispositionHeader.match(/filename=([^;]+)/);
                fileName = match?.[1] ?? fileName;
            }

            const blob = new Blob([await response.blob()], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            downloadFile(blob, fileName);
        } catch (error: FixMeLater) {
            console.error("Error exporting data:", error);
        }
    };

    const submitFiling = async () => {
        setIsFetchingCompanyTypes(true);
        setError(null);
        try {
            const response = await optinsService.submitFiling({
                returnDocumentIdDTO: payload,
                naicCompanyTypeId:
                    formData.selectedCompanyType?.identifier || "",
                testingYear: formData.testingYear || null,
                paymentMethod: formData.paymentType,
                types: formData.allocationTypes?.allocations.types  || [],
                didConsentToAll:
                    formData.paymentType === PAYMENT_TYPE.DEBIT &&
                    formData.consent === CONSENT_TYPE.ALL_PAYMENTS,
                naicCompanyTypeName: formData.selectedCompanyType?.name || "",
                selectedDate:
                    formData.filingType == FILING_TYPE.ORIGINAL &&
                    formData.paymentType == PAYMENT_TYPE.DEBIT &&
                    formData.consent === CONSENT_TYPE.ALL_PAYMENTS
                        ? formData.selectedDate
                        : undefined,
                paymentOnly: paymentOnly,
            });

            if (!response.clientError && !response.serverError) {
                if (tabProperties) {
                    const updatedTabProperties: TabProperties = {
                        ...tabProperties,
                        submissionId: "efiled",
                    };
                    dispatch(GlobalStateActions.setTab(updatedTabProperties));
                    updateReturnNodeInTree({
                        ...returnNode,
                        submissionId: response.response.trackingNumber,
                    });
                }
                onEfiled();
            }

            setFormData({
                ...formData,
                submitResponse: response,
            });
        } catch (err) {
            setError("Failed to submit the form. Please try again.");
        } finally {
            setIsFetchingCompanyTypes(false);
        }
    };

    const updateReturnNodeInTree = (newReturnNode: ReturnNode) => {
        const updatedTree = {
            ...tree,
            folderNodes: tree.folderNodes.map((folderNode) => ({
                ...folderNode,
                returnNodes: folderNode.returnNodes.map((node) =>
                    node.id === newReturnNode.id ? { ...newReturnNode } : node
                ),
            })),
        };
        dispatch(GlobalStateActions[product?.productName].setTree(updatedTree));
    };

    // Use effect to fetch company types when the dialog is opened
    useEffect(() => {
        if (open) {
            setFormData((prev) => ({
                ...prev,
                paymentOnly: paymentOnly,
            }));
            if (!paymentOnly) {
                fetchCompare();
            }
            fetchCompanyTypes();
        }
    }, [open]);

    useEffect(() => {
        if (formData.filingType == FILING_TYPE.AMENDED 
        //    && !paymentOnly
            ) {
            fetchAllocationTypes();
        }
    }, [formData.filingType]);

    // Use effect to fetch filing status when the company type is selected
    useEffect(() => {
        if (formData.selectedCompanyType) {
            fetchFilingStatus();
        }
    }, [formData.selectedCompanyType, formData.testingYear]);

    const recallfetchFilingStatus = useRef(false);

    useEffect(() => {
        if (recallfetchFilingStatus.current) {
            fetchFilingStatus();
            recallfetchFilingStatus.current = false;
        }
    }, [recallfetchFilingStatus.current]);

    return (
        <EfileNaicOptinsContext.Provider
            value={{
                formData,
                setFormData,
                isFetchingFilingStatus,
                isDeleting,
                deleteFiling,
                onReset,
            }}
        >
            {validationResponse?.failures?.length ? (
                <ErrorContainer>
                    <Title>The following requirements have not been met.</Title>
                    <ErrorList>
                        {validationResponse?.failures?.map(
                            (response, index) => (
                                <ErrorItem key={`attachment${index}`}>
                                    {response.message}
                                </ErrorItem>
                            )
                        )}
                    </ErrorList>
                </ErrorContainer>
            ) : (
                <EfileNaicOptinsStyled data-testid="efile-naic-optins">
                    {isFetchingCompanyTypes && <Loader />}
                    {error && (
                        <EfileNaicOptinsErrorStyled>
                            <Alert severity="error">{error}</Alert>
                        </EfileNaicOptinsErrorStyled>
                    )}
                    {!error && formData.submitResponse && <SubmitResponse />}
                    {!error &&
                        !isFetchingCompanyTypes &&
                        !formData.submitResponse && (
                            <>
                                <Tabs
                                    value={tab}
                                    onChange={onTabChange}
                                    variant="scrollable"
                                    scrollButtons={"auto"}
                                    TabIndicatorProps={{
                                        children: (
                                            <span className="MuiTabs-indicatorSpan" />
                                        ),
                                    }}
                                    slots={{
                                        StartScrollButtonIcon:
                                            ArrowCircleLeftOutlined,
                                        EndScrollButtonIcon:
                                            ArrowCircleRightOutlined,
                                    }}
                                >
                                    {formData.compare.length > 0 && (
                                        <Tab selectedTab={tab} value={0}>
                                            Compare
                                        </Tab>
                                    )}

                                    <Tab selectedTab={tab} value={1}>
                                        Company Type
                                    </Tab>
                                    <Tab
                                        selectedTab={tab}
                                        value={2}
                                        disabled={isNextDisabled(
                                            EfileNaicOptinsTabs.COMPANY_TYPE
                                        )}
                                    >
                                        Use Agreement
                                    </Tab>
                                    <Tab
                                        selectedTab={tab}
                                        value={3}
                                        disabled={isNextDisabled(
                                            EfileNaicOptinsTabs.USE_AGREEMENT
                                        )}
                                    >
                                        Payment Info
                                    </Tab>
                                </Tabs>
                                {formData.filingType &&
                                    (tab == EfileNaicOptinsTabs.USE_AGREEMENT ||
                                        tab ==
                                            EfileNaicOptinsTabs.PAYMENT_INFO) && (
                                        <Alert severity="info">
                                            This will be an{" "}
                                            {formData.filingType} filing.
                                        </Alert>
                                    )}
                                {formData.compare.length > 0 && (
                                    <TabPanel selectedTab={tab} value={0}>
                                        <Grid item>
                                            <Alert severity="error">
                                                When e-filing, an excel file is
                                                sent to OPTins. Listed below are
                                                the differences between the
                                                software calculations and the
                                                OPTins Excel calculations.{" "}
                                            </Alert>
                                        </Grid>
                                        <Compare
                                            optinsExport={formData.compare}
                                        />
                                    </TabPanel>
                                )}

                                <TabPanel selectedTab={tab} value={1}>
                                    <CompanyType />
                                </TabPanel>
                                <TabPanel selectedTab={tab} value={2}>
                                    <UseAgreement />
                                </TabPanel>
                                <TabPanel selectedTab={tab} value={3}>
                                    <PaymentInfo />
                                </TabPanel>
                                <EfileNaicOptinsActionContainerStyled>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "10px",
                                        }}
                                    >
                                        <ActionButtonStyled
                                            variant="contained"
                                            color="primary"
                                            onClick={onPrevClick}
                                            disabled={isPrevDisabled()}
                                            startIcon={<ArrowBack />}
                                        >
                                            Prev
                                        </ActionButtonStyled>
                                        <ActionButtonStyled
                                            variant="contained"
                                            color="primary"
                                            onClick={onNextClick}
                                            disabled={isNextDisabled(tab)}
                                            endIcon={<ArrowForward />}
                                        >
                                            Next
                                        </ActionButtonStyled>
                                    </Box>
                                    {!paymentOnly && (
                                        <Tooltip
                                            title="The Excel file is for review purposes only and cannot be edited. Any changes or corrections should be made on the return in the software."
                                            arrow
                                        >
                                            <ActionButtonStyled
                                                variant="contained"
                                                color="primary"
                                                onClick={handleExport}
                                                endIcon={<TableChartOutlined />}
                                            >
                                                Review Excel
                                            </ActionButtonStyled>
                                        </Tooltip>
                                    )}

                                    <ActionButtonStyled
                                        variant="contained"
                                        color="primary"
                                        onClick={submitFiling}
                                        endIcon={<Send />}
                                        disabled={!canSubmit()}
                                    >
                                        Submit
                                    </ActionButtonStyled>
                                </EfileNaicOptinsActionContainerStyled>
                            </>
                        )}
                </EfileNaicOptinsStyled>
            )}
        </EfileNaicOptinsContext.Provider>
    );
};
