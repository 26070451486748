import { GridFilterModel, GridRowModel } from "@mui/x-data-grid-premium";
import { CompanyOptionsEntity } from "./CompanyOptionsService.types";

export interface AllocatorJobsState {
    jobList: AllocatorJob[];
    selectedJob: AllocatorJob | null;
    selectedJobStatus: AllocatorJobStatus | null;
    selectedFileName: string | null;
    page: number;
    toogleFetchJobs: boolean;
    filterState: AllocatorJobsPayload | null;
    isJobSearchActive: boolean;
}

export interface AllocatorUploadResult {
    jobs: AllocatorJob[];
    hasErrors: boolean;
    errors: AllocatorApiError[];
}

export interface AllocatorApiError {
    errorCode: string;
    errorDetails: string;
    errorMsg: string;
    filename?: string;
    jobId?: number;
}

export interface AllocatorNotificationMessage {
    message?: string;
    messageType?: string;
    errorId?: number;
    jobId?: number;
}

export interface AllocatorPage {
    totalElements: number;
    totalPages: number;
    last: boolean;
    size: number;
    number: number;
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}

export interface AllocatorJobsPage extends AllocatorPage {
    content: AllocatorJob[];
}

export interface AllocatorJobsResponse {
    page: AllocatorJobsPage;
    meta: AllocatorJobsMeta;
}

export interface AllocatorJobsMeta {
    lastSelectedCompany: AllocatorCompany | string;
    lastSelectedYear: number | string;
    lastSelectedState: AllocatorSelectOption | string;
    allowedCompanies: AllocatorCompany[];
    exportOptions: AllocatorJobsExportOptions;
    statuses: AllocatorSelectOption[];
    supportedStates: AllocatorSelectOption[];
    supportedYears: number[];
    fixedWidthFileFormat: boolean;
}

export interface AllocatorCompany {
    id: number;
    abbr: string; 
}

export interface AllocatorJobsExportOptions {
    [key: string]: AllocatorExportOption[];
}

export interface AllocatorExportOption {
    displayName: string;
    name?: string; 
}

export interface AllocatorSelectOption {
    value: string;
    label: string;
}

export interface AllocatorJob {
    id: number;
    status: AllocatorJobStatus;
    allowedStatuses: AllocatorJobStatus[];
    goodMatches: string;
    modifiedDate: string;
    approvedDate: string;
    createdBy: string;
    completionDate: string;
    filename?: string;
    stateAbbr?: string;
    year?: number;
    quarter?: string;
    companyAbbreviation?: string;
    progress?: number;
    warningId?: number;
    checked?: boolean;
}

export interface AllocatorJobStatus {
    name: JOB_STATUS_TYPES;
    value: string;
}

export interface AllocatorJobStatusPayload {
    jobStatus: JOB_STATUS_TYPES;
}

export interface AllocatorJobStatistics {
    data: AllocatorJobStatisticsData[];
    meta: AllocatorJobStatisticsMeta;
}

export interface AllocatorJobStatisticsData {
    matchCode: number;
    matchDescription: string;
    recordsCount: number;
    percentage: string;
    premiumAmount: string;
    premiumAmountPercentage: string;
}

export interface AllocatorJobStatisticsMeta {
    filename: string;
    jobStatus: AllocatorJobStatus;
    exportOptions: AllocatorReportExportOption[];
    state: string;
}

export interface AllocatorJobsPayload {
    filters: AllocatorFilters[];
    searchPhrase?: string;
    lastSelectedCompanyAbbr?: string;
    lastSelectedYear?: number;
    lastSelectedState?: string;
}

export interface AllocatorErrorLog {
    errorDetails: AllocatorErrorLogDetails;
    filename: string;
}

export interface AllocatorErrorLogDetails {
    errors: AllocatorErrorLogError[];
    rowsFailed: number;
    rowsProcessed: number;
    totalRows: number;
}

export interface AllocatorErrorLogError {
    errorMessage: string;
    rowNumber: number;
    spreadsheetName?: string;
}

export interface AllocatorReport {
    columns: AllocatorReportColumns;
    page: AllocatorReportPage;
    meta: AllocatorReportMeta;
}

// Dynamically generated on the backend side
export interface AllocatorReportColumns {
    [key: string]: AllocatorReportColumn;
}

export interface AllocatorReportColumn {
    displayName: string;
    type: REPORT_COLUMN_TYPES;
    filterable: boolean;
}

export interface AllocatorReportPage extends AllocatorPage {
    content: AllocatorReportRow[];
}

// Dynamically generated on the backend side
export interface AllocatorReportRow {
    [key: string]: AllocatorReportRowItem;
}

export interface AllocatorReportRowItem {
    corrected: boolean;
    value: string;
    sourceValue: string;
    autocorrectionInfo?: string;
}

export interface AllocatorReportMeta {
    filename: string;
    jobStatus: AllocatorJobStatus;
    predefinedSearchData: AllocatorPredefinedSearchData;
    downloadOptions: AllocatorReportExportOption[];
    exportOptions: AllocatorReportExportOption[];
    state: string;
}

export interface AllocatorPredefinedSearchData {
    [key: string]: string[];
}

export interface AllocatorReportPayload {
    jobId: number;
    columns?: string[];
    filters?: AllocatorFilters[];
    searchPhrase?: string;
}

export interface CorrectedAddresses {
    [key: string]: GridRowModel;
}

export interface AllocatorCorrectionsPayload {
    corrections: AllocatorCorrections[];
}

export interface AllocatorCorrections {
    sourceRowId: number;
    correctedStreet: string;
    correctedCity: string;
    correctedState: string;
    correctedZipCode: string;
}

export interface AllocatorReportTab {
    id: number;
    name: ALLOCATOR_REPORT_TYPES;
}

export interface AllocatorResponseDownloadable {
    blob: Blob; 
    contentDispositionHeader: string;
}

export interface AllocatorReportExportOption extends AllocatorExportOption {
    excludeColumn?: string;
}

export interface AllocatorReportColumnsWidth {
    [reportId: string]: AllocatorReportColumnsWidthValues;
}

export interface AllocatorReportColumnsWidthValues {
    [field: string]: number;
}

export interface AllocatorReportFilterState {
    [key: string]: GridFilterModel;
}

export interface AllocatorReportsPageSize {
    [key: string]: string;
}

export interface AllocatorFilters {
    field: string,
    value: string,
    operator: string,
    booleanOperator: string | undefined,
}

export interface AllocatorFieldMapping {
    fieldMapping: AllocatorSettings;
}

export interface AllocatorSettings {
    custom: boolean;
    filename: string;
}

export interface NotificationEvent {
    id: number;
    accountId: number;
    eventType: SSE_TYPES;
    payload: AllocatorJob & AllocatorNotificationMessage;
}

export interface ContextMenuPosition {
    mouseX: number;
    mouseY: number;
}

export interface ConfirmationDialogAction {
    text: string;
    action: () => void;
}

export interface ConfirmationDialogSettings {
    title: string | undefined;
    content: React.ReactElement | string | string[] | undefined;
    actionPrimary: ConfirmationDialogAction | undefined;
    actionSecondary: ConfirmationDialogAction | undefined;
}

export interface ConfirmationDialogProps extends ConfirmationDialogSettings {
    open: boolean;
    handleClose: () => void;
}

export interface AllocatorCompanyOptionsEntity extends CompanyOptionsEntity {
    jsonOptions: AllocatorCompanyOptionsTabs;
}

export interface AllocatorCompanyOptionsTabs {
    allocationOptions: AllocationOptions;
    fileFormatOptions: FileFormatOptions;
    premiumPercentageOptions: PercentageOptions;
}

export interface AllocationOptions {
    displayName: string;
    allocateByZipCode: boolean;
}

export interface FileFormatOptions {
    displayName: string;
    columnFixedWidthPositions?: AllocatorFixedWidthField[];
    dataDelimiter?: string;
    defaultValues?: FileFormatOptions;
}

export interface PercentageOptions {
    displayName: string;
    premiumTypePercentages: AllocatorPremiumTypePercentages;
    defaultValues?: PercentageOptions;
}

export interface AllocatorFixedWidthField {
    cell: string;
    displayName: string;
    max: number;
    min: number;
    start: number;
    width: number;
}

export interface AllocatorPremiumTypePercentages {
    [key: string]: AllocatorPercentageField;
}

export interface AllocatorPercentageField {
    premiumTypeCode: string;
    displayName: string;
    max: number;
    min: number;
    percentage: number | string;
}

export enum SSE_TYPES {
    JOB_PROGRESS = "JOB_PROGRESS",
    JOB_STATUS_UPDATED = "JOB_STATUS_UPDATED",
    MESSAGE = "MESSAGE",
}

export enum JOB_STATUS_TYPES {
    NOT_PROCESSED = "NOT_PROCESSED",
    PROCESSING = "PROCESSING",
    PROCESSED = "PROCESSED",
    ON_REVIEW = "ON_REVIEW",
    APPROVED = "APPROVED",
    CANCELED = "CANCELED",
    FAILED = "FAILED",
}

export enum ALLOCATOR_REPORT_TYPES {
    JOB_SUMMARY = "Job Summary",
    ALLOCATION_SUMMARY = "Allocation Summary",
    MATCH_EXCEPTIONS = "Match Exceptions",
    NON_TAXABLE = "Non-Taxable",
    COUNTY_ALLOCATION = "County Allocation",
    ALLOCATION_DETAIL = "Allocation Detail",
    SOURCE_DATA = "Source Data",
    ADDRESS_CORRECTIONS = "Address Corrections",
    ACTIVITY = "Activity"
}

export enum FILE_FORMAT_TYPES {
    CSV = "csv",
    IMP = "imp",
}

export enum MUNICIPALITIES_TYPES {
    CODE = "code",
    NAME = "name",
}

export enum REPORT_COLUMN_TYPES {
    STRING = "string",
    NUMBER = "number",
    SINGLE_SELECT = "singleSelect",
    DATE = "date",
    BOOLEAN = "boolean",
}

export enum REPORT_EDIT_FIELDS {
    NORMAL_CITY = "sourceRow.normalizedAddress.normalizedCity",
    NORMAL_STREET = "sourceRow.normalizedAddress.normalizedStreet",
    NORMAL_STATE = "sourceRow.normalizedAddress.normalizedState",
    NORMAL_ZIP = "sourceRow.normalizedAddress.normalizedZipCode",
}

export enum ALLOCATOR_SEARCH_PARAMS {
    JOB_ID = "jobId",
    REPORT = "report",
    PAGE = "page",
    SIZE = "size"
}

export enum ALLOCATOR_TOOLS_TABS {
    ASCII_FORMAT = "ASCII Format",
    PREMIUM_TYPES = "Premium Types",
    PERCENTAGE = "Percentage",
    FIELD_MAPPING = "Field Mapping",
}

export enum ALLOCATOR_REPORT_PAGE_SIZES {
    SMALL = 100,
    MEDIUM = 500,
    LARGE = 1000,
    EXTRA_LARGE = 10000,
}

export enum ALLOCATOR_LOG_TYPES {
    WARNING = "Warning",
    ERROR = "Error",
}

export enum ALLOCATOR_TAX_DIST_OPTIONS {
    STOCK = "STOCK",
    MUTUAL = "MUTUAL",
}