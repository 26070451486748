import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Product, KentuckyAgentUserOptions } from "src/types";

interface KentuckyAgentUserOptionsState {
    value: KentuckyAgentUserOptions;
}

const initialState: KentuckyAgentUserOptionsState = {
    value: {
        premiumType: "",
        schedulePreferences: [],
        widgetsLayout: "",
        productPreferences: {
            Premium: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                },
                dropdownStateView: false,
            },
            FormsPlus: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                },
                dropdownStateView: false,
            },
            Municipal: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                    quarter: "",
                },
                dropdownStateView: false,
            },
        },
        globalPreferences: {
            theme: "tritech",
            zoom: 1,
            leftSidebarPinned: true,
            leftSidebarSize: 300,
        },
    },
};

export const KentuckyAgentUserOptionsSlice = createSlice({
    name: "KentuckyAgentUserOptions ",
    initialState,
    reducers: {
        setKentuckyAgentUserOptionsFilter: (
            state,
            action: PayloadAction<{
                product: Product;
                filters?: Record<string, string>;
                dropdownStateView: boolean;
            }>
        ) => {
            const productName = action.payload.product.productName;

            // Initialize product preferences if not present
            if (!state.value.productPreferences[productName]) {
                state.value.productPreferences[productName] = {
                    filters: action.payload.filters || {},
                    dropdownStateView: action.payload.dropdownStateView,
                };
            } else {
                // Merge existing filters with new filters
                const existingFilters =
                    state.value.productPreferences[productName].filters || {};
                state.value.productPreferences[productName].filters = {
                    ...existingFilters,
                    ...action.payload.filters,
                };
                state.value.productPreferences[productName].dropdownStateView =
                    action.payload.dropdownStateView;
            }
        },
        setKentuckyAgentUserOptionsFilterDropDownState: (
            state,
            action: PayloadAction<{
                product: Product;
                dropdownStateView: boolean;
            }>
        ) => {
            const productName = action.payload.product?.productName;

            // Initialize product preferences if not present
            if (!state.value.productPreferences[productName]) {
                state.value.productPreferences[productName] = {
                    dropdownStateView: action.payload.dropdownStateView,
                };
            } else {
                state.value.productPreferences[productName].dropdownStateView =
                    action.payload.dropdownStateView;
            }
        },
        setKentuckyAgentUserOptionsZoomScale: (state, action: PayloadAction<number>) => {
            state.value.globalPreferences.zoom = action.payload;
        },
        setKentuckyAgentUserOptions: (
            state,
            action: PayloadAction<KentuckyAgentUserOptions>
        ) => {
            state.value = action.payload;
        },
        setKentuckyAgentUserOptionsLeftSidebarPinned: (state, action: PayloadAction<boolean>) => {
            state.value.globalPreferences.leftSidebarPinned = action.payload;
        },
        setKentuckyAgentUserOptionsLeftSidebarSize: (state, action: PayloadAction<number>) => {
            state.value.globalPreferences.leftSidebarSize = action.payload;
        },
        setKentuckyAgentUserOptionsDropdownStateView: (
            state,
            action: PayloadAction<{
                productName: string;
                dropdownStateView: boolean;
            }>
        ) => {
            const productName = action.payload.productName;

            // Check if productPreferences and product exist, if not, initialize them
            if (!state.value.productPreferences[productName]) {
                state.value.productPreferences[productName] = {
                    dropdownStateView: false,
                };
            }
            state.value.productPreferences[productName].dropdownStateView =
                action.payload.dropdownStateView;
        },
    },
});

// Exporting actions and reducer
export const {
    setKentuckyAgentUserOptions,
    setKentuckyAgentUserOptionsFilterDropDownState,
    setKentuckyAgentUserOptionsDropdownStateView,
    setKentuckyAgentUserOptionsZoomScale,
    setKentuckyAgentUserOptionsFilter,
    setKentuckyAgentUserOptionsLeftSidebarPinned,
    setKentuckyAgentUserOptionsLeftSidebarSize,
} = KentuckyAgentUserOptionsSlice.actions;
export default KentuckyAgentUserOptionsSlice.reducer;
