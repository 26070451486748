import React, { FC, useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { AllocatorCompanyOptionsEntity, AllocatorCompanyOptionsTabs, AllocatorPercentageField, AllocatorPremiumTypePercentages } from "src/types";
import { StyledFlexContainer } from "../AllocatorCompanyOptions.styled";
import { StyledFieldsContainer, StyledPercentageContainer, StyledScrollableContainer } from "./PercentageOptions.styled";
import { StyledOutlinedButton } from "src/products/Allocator/Allocator.styled";

interface PercentageOptionsProps {
    isHidden: boolean;
    formData: AllocatorCompanyOptionsEntity;
    onFormDataChange: (newData: AllocatorCompanyOptionsTabs) => void;
    setOpenDialog: (value: boolean) => void;
}

const PercentageOptions: FC<PercentageOptionsProps> = ({
    isHidden,
    formData,
    onFormDataChange,
    setOpenDialog,
}) => {
    const premiumTypePercentages = formData?.jsonOptions?.premiumPercentageOptions?.premiumTypePercentages;
    const [percentage, setPercentage] = useState<AllocatorPremiumTypePercentages>({});

    const handleValueChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
        targetField: AllocatorPercentageField,
    ) => {
        const { value } = event.target;
        if (value.match(/^\d*(\.\d{0,2})?$/)) {
            const validatedValue = validateField(value, targetField?.min, targetField?.max);
            setPercentage({
                ...percentage,
                [targetField.premiumTypeCode]: {
                    ...targetField,
                    percentage: validatedValue,
                }
            });
        }
    };

    const handleBlur = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
        targetField: AllocatorPercentageField,
    ) => {
        const { value } = event.target;
        onFormDataChange({
            ...formData?.jsonOptions,
            premiumPercentageOptions: {
                ...formData?.jsonOptions?.premiumPercentageOptions,
                premiumTypePercentages: {
                    ...percentage,
                    [targetField.premiumTypeCode]: {
                        ...targetField,
                        percentage: +value,
                    }
                },
            }
        })
    };

    const validateField = (value: string, min: number, max: number) => {
        if (value[1] === ".") {
            return value;
        }
        if (Number(value) > max) { 
            return max.toString();
        } 
        if (Number(value) <= min || isNaN(Number(value))) { 
            return min.toString();
        }        
        return value.replace(/^0+(?=\d)/, "");
    };

    useEffect(() => {
        if (premiumTypePercentages) {
            setPercentage(premiumTypePercentages);
        }
    }, [formData]);

    return (
        <StyledFlexContainer $isHidden={isHidden}>
            <StyledPercentageContainer>
                <StyledFieldsContainer>
                    <Typography fontWeight="bold">
                        Premium Type
                    </Typography>
                    <Typography fontWeight="bold">
                        Percentage
                    </Typography>
                </StyledFieldsContainer>
                <StyledScrollableContainer >
                    {Object.values(percentage)?.map((field: AllocatorPercentageField) => 
                        <StyledFieldsContainer key={field?.premiumTypeCode}>
                            <Typography>
                                {field?.displayName}
                            </Typography>
                            <TextField
                                variant="standard"
                                size="small"
                                autoComplete="off"
                                sx={{width: "70px"}}
                                value={field?.percentage}
                                onChange={(event) => handleValueChange(event, field)}
                                onBlur={(event) => handleBlur(event, field)}
                            />
                            <Typography>%</Typography>
                        </StyledFieldsContainer>
                    )}
                </StyledScrollableContainer>
            </StyledPercentageContainer>
            <StyledOutlinedButton
                variant="outlined"
                onClick={() => setOpenDialog(true)}
            >
                Restore to Default
            </StyledOutlinedButton>
        </StyledFlexContainer>
    );
};

export default PercentageOptions;
