import React, { FC } from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { StyledCheckbox, StyledRadioGroup } from "./TaxDistribution.styled";
import { ALLOCATOR_TAX_DIST_OPTIONS } from "src/types";

interface TaxDistributionProps {
    taxDistributionOption: ALLOCATOR_TAX_DIST_OPTIONS, 
    setTaxDistributionOption: (value: ALLOCATOR_TAX_DIST_OPTIONS) => void;
    isMutualChecked: boolean;
    setIsMutualChecked: (value: boolean) => void;
}

const TaxDistribution: FC<TaxDistributionProps> = ({
    taxDistributionOption,
    setTaxDistributionOption,
    isMutualChecked,
    setIsMutualChecked,
}) => {
    return (
        <>
            <StyledRadioGroup
                value={taxDistributionOption}
                onChange={(e) => setTaxDistributionOption(
                    e?.target?.value as ALLOCATOR_TAX_DIST_OPTIONS
                )}
            >
                <FormControlLabel
                    value={ALLOCATOR_TAX_DIST_OPTIONS.STOCK}
                    control={<Radio />}
                    label="Stock"
                />
                <FormControlLabel
                    value={ALLOCATOR_TAX_DIST_OPTIONS.MUTUAL}
                    control={<Radio />}
                    label="Mutual"
                />
            </StyledRadioGroup>
            {taxDistributionOption === ALLOCATOR_TAX_DIST_OPTIONS.MUTUAL && (
                <FormControlLabel 
                    control={
                        <StyledCheckbox 
                            checked={isMutualChecked} 
                            onChange={() => setIsMutualChecked(!isMutualChecked)}
                        />
                    } 
                    label="Export New York City and Buffalo" 
                />
            )}
        </>
    );
};

export default TaxDistribution;
