import React, { FC, useState } from "react";
import { Tab } from "@mui/material";
import { AllocatorCompanyOptionsEntity, AllocatorCompanyOptionsTabs } from "src/types";
import FileFormatOptions from "./FileFormatOptions/FileFormatOptions";
import { SelectedStyledChipTab, StyledChipTab, StyledTabs } from "./AllocatorCompanyOptions.styled";
import AllocationOptions from "./AllocationOptions/AllocationOptions";
import ConfirmationDialog from "src/products/Allocator/components/ConfirmationDialog/ConfirmationDialog";
import PercentageOptions from "./PercentageOptions/PercentageOptions";

interface AllocatorCompanyOptionsProps {
    formData: AllocatorCompanyOptionsEntity;
    onFormDataChange: (newData: AllocatorCompanyOptionsTabs) => void;
}

const AllocatorCompanyOptions: FC<AllocatorCompanyOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    const [selectedTab, setSelectedTab] = useState(Object.keys(formData?.jsonOptions)[0]);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const handleChangeTab = (newValue: string) => {
        setSelectedTab(newValue);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleRestore = () => {
        onFormDataChange({
            ...formData?.jsonOptions,
            [selectedTab]: {
                ...formData?.jsonOptions?.[selectedTab],
                ...formData?.jsonOptions?.[selectedTab]?.defaultValues,
            }
        });
        handleClose();
    };

    const actionRestore = {
        text: "Restore",
        action: handleRestore,
    };

    const actionCancel = {
        text: "Cancel",
        action: handleClose,
    };

    return (
        <>
            <StyledTabs value={Number(!!selectedTab)}>
                {Object.entries(formData?.jsonOptions)?.map(([key, value]) => {
                    return key !== selectedTab ? (
                        <Tab key={key} disableRipple label={
                            <StyledChipTab
                                label={value?.displayName}
                                onClick={() => handleChangeTab(key)}
                            />
                        } />
                    ) : (
                        <Tab key={key} disableRipple label={
                            <SelectedStyledChipTab
                                label={value?.displayName}
                            />
                        } />
                    );
                })}
            </StyledTabs>
            <FileFormatOptions
                isHidden={selectedTab !== "fileFormatOptions"} 
                formData={formData}
                onFormDataChange={onFormDataChange}
                setOpenDialog={setOpenDialog}
            />
            <AllocationOptions
                isHidden={selectedTab !== "allocationOptions"} 
                formData={formData}
                onFormDataChange={onFormDataChange}
            />
            <PercentageOptions
                isHidden={selectedTab !== "premiumPercentageOptions"} 
                formData={formData}
                onFormDataChange={onFormDataChange}
                setOpenDialog={setOpenDialog}
            />
            <ConfirmationDialog
                open={openDialog}
                title={"Restore the default values?"}
                content={""}
                actionPrimary={actionRestore}
                actionSecondary={actionCancel}
                handleClose={handleClose}
            />
        </>
    );
};

export default AllocatorCompanyOptions;
