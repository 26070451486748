import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { IconButton, Popover, Typography } from "@mui/material";
import { StyledFieldsContainer, StyledVersionsContainer, StyledVersionsTitle } from "./SystemVersions.styled";
import { AgentService } from "src/services/AgentService";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import { SystemVersion } from "src/types";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../Loader/Loader";
import { THEME } from "src/constants/Theme";

interface SystemVersionsProps {
    open: boolean;
    anchorEl?: null | HTMLElement;
    onClose?: () => void;
}

const SystemVersions: FC<PropsWithChildren<SystemVersionsProps>> = ({
    open,
    anchorEl,
    onClose,
}) => {
    const agentService = AgentService.getInstance();
    const [versions, setVersions] = useState<SystemVersion[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        const fetchSystemVersions = async () => {
            try {
                setIsLoading(true);
                const systemVersions = await agentService.getSystemVersions();
                setVersions(systemVersions);
            } catch (error) {
                handleSnackbar("Failed to fetch system versions", "error");
            } finally {
                setIsLoading(false);
            }
        };

        if (open) {
            fetchSystemVersions();
        }
    }, [open]);

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <StyledVersionsContainer>
                    {isLoading ? (
                        <Loader color={THEME.palette.primary} />
                    ) : (
                        <>
                            <StyledVersionsTitle>
                                <Typography>
                                    System Versions
                                </Typography>
                                <IconButton onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </StyledVersionsTitle>
                            <StyledFieldsContainer>
                                <Typography fontWeight="bold">
                                    Component
                                </Typography>
                                <Typography fontWeight="bold">
                                    Version
                                </Typography>
                            </StyledFieldsContainer>
                            {versions?.map((field: SystemVersion) => 
                                <StyledFieldsContainer key={field?.component}>
                                    <Typography>
                                        {field?.component}
                                    </Typography>
                                    <Typography>
                                        {field?.version}
                                    </Typography>
                                </StyledFieldsContainer>
                            )}
                            <StyledFieldsContainer>
                                <Typography>
                                    TriTech UI
                                </Typography>
                                <Typography>
                                    {process.env.REACT_APP_VERSION}
                                </Typography>
                            </StyledFieldsContainer>
                        </>
                    )}
                </StyledVersionsContainer>
            </Popover>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </>
    );
};

export default SystemVersions;