import { ErrorCode } from "react-dropzone";

export const getErrorMessageByCode = (
    errorCode: ErrorCode | string, 
    fixedWidthFileFormat: boolean,
): string => {
    switch (errorCode) {
        case ErrorCode.FileInvalidType:
            if (fixedWidthFileFormat) {
                return "Can't upload: Use a file in .txt or .prn extension";
            }
            return "Unable to parse uploaded file due to invalid format. Please ensure it is in valid CSV format.";
        case ErrorCode.TooManyFiles:
            return "Maximum of 15 files is allowed";
        default:
            return "Can't upload file";
    }
};
