import styled from "styled-components";

export const StyledVersionsContainer = styled.div`
    min-height: 50px;
    min-width: 300px;
    margin: 10px 0;
`;

export const StyledVersionsTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 0 10px 10px 20px;

    p {
        font-size: 20px;
        font-weight: bold;
        align-self: center;
    }
`;

export const StyledFieldsContainer = styled.div`
    display: flex;
    padding: 7px 20px 7px 20px;

    &:nth-child(odd) {
        background-color: #eeeeee
    }
    
    p {
        font-size: 14px;
    }
    
    p:first-child {
        width: 170px;
    }
`;
