import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { Checkbox, RadioGroup } from "@mui/material";

export const StyledRadioGroup = styled(RadioGroup)`
    .MuiRadio-colorPrimary {
        color: ${colors.primaryColor};
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    margin-left: 10px;
    color: ${({ theme }) => theme.palette.primary};

    &.Mui-checked {
        color: ${({ theme }) => theme.palette.primary};
    }
`;