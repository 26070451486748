import React, { FC } from "react";
import "./Compare.scss";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import {  OptinsCompareResponse } from "src/types";

interface CompareProps {
    optinsExport: OptinsCompareResponse[];
}

const Compare: FC<CompareProps> = ({ optinsExport }) => {
    const columns: GridColDef[] = [
        { field: "fieldName", headerName: "Field Name", flex: 1 },
        { field: "cell", headerName: "Cell", flex: 1 },
        { field: "excelValue", headerName: "Excel Value", flex: 1 },
        { field: "stratusValue", headerName: "Stratus Value", flex: 1 },
    ];

    const rows = optinsExport.map((item, index) => {
        return {
            id: index,
            fieldName: item?.fieldName || "",
            cell: item?.cell || "",
            excelValue: item?.excelValue || "",
            stratusValue: item?.stratusValue || "",
        };
    });

    return (
        <div className="compare-container">
            {!rows || rows.length === 0 ? (
                <div>No data to display.</div>
            ) : (
                <DataGridPremium rows={rows} columns={columns} />
            )}
        </div>
    );
};

export default Compare;
