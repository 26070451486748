import React, { FC } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { AllocatorCompanyOptionsEntity, AllocatorCompanyOptionsTabs } from "src/types";
import { StyledFlexContainer } from "../AllocatorCompanyOptions.styled";

interface AllocationOptionsProps {
    isHidden: boolean;
    formData: AllocatorCompanyOptionsEntity;
    onFormDataChange: (newData: AllocatorCompanyOptionsTabs) => void;
}

const AllocationOptions: FC<AllocationOptionsProps> = ({
    isHidden,
    formData,
    onFormDataChange,
}) => {  
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const fieldName = name?.split(".")[1];
        onFormDataChange({
            ...formData?.jsonOptions,
            allocationOptions: {
                ...formData?.jsonOptions?.allocationOptions,
                [fieldName]: checked,
            }
        });
    };

    return (
        <StyledFlexContainer 
            $isHidden={isHidden}
        >
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.allocationOptions?.allocateByZipCode
                        }
                        onChange={handleToggleChange}
                        name="allocator.allocateByZipCode"
                    />
                }
                label="Allocate by Zip Code"
            />
        </StyledFlexContainer>
    );
};

export default AllocationOptions;
