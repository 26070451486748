import React, { useContext } from "react";
import {
    EfileNaicOptinsContext,
    EfileNaicOptinsContextState,
} from "../EfileNaicOptins.context";
import {
    ActionsContainer,
    CompanyTypeSelectorContainer,
    CompanyTypeStyled,
    FilingStatusContainer,
    GeneralInfoContainer,
} from "./CompanyType.styled";
import {
    FormControl,
    Select,
    MenuItem,
    SelectChangeEvent,
    Grid,
    Chip,
    Button,
    Divider,
    Alert,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    capitalize,
    Box,
    InputLabel,
} from "@mui/material";
import Loader from "src/components/Loader/Loader";
import { BugReportTwoTone, Delete, ExpandMore } from "@mui/icons-material";
import {
    getChipColor,
    getFilingStatusAndPaymentInfo,
} from "../EfileNaicOptins.helpers";
import { FilingStatus } from "src/types";
import { startCase } from "lodash";
import FeatureFlags from "src/utils/FeatureFlags";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

export const CompanyType: React.FC = () => {
    const [isDeleting, setIsDeleting] = React.useState(false);

    const EfileNaicOptinsCtx: EfileNaicOptinsContextState | undefined =
        useContext(EfileNaicOptinsContext);

    const handleSelectChange = (event: SelectChangeEvent<string>): void => {
        const newCompanyType =
            EfileNaicOptinsCtx?.formData?.optinsInfo?.companyTypes.find(
                (companyType) => companyType.identifier === event.target.value
            );
        EfileNaicOptinsCtx?.setFormData({
            ...EfileNaicOptinsCtx.formData,
            selectedCompanyType: newCompanyType,
        });
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        await EfileNaicOptinsCtx?.deleteFiling();
        setIsDeleting(false);
    };

    const getFilingStatus = (): FilingStatus => {
        return getFilingStatusAndPaymentInfo(
            EfileNaicOptinsCtx?.formData.filingStatus?.filingStatus ?? ""
        ).filingStatus;
    };

    const currentYear = dayjs().year(
        parseInt(
            EfileNaicOptinsCtx?.formData.testingYear?.toString() ?? "2000",
            10
        )
    );

    const optinsYear = dayjs().year(
        parseInt(
            EfileNaicOptinsCtx?.formData.optinsInfo?.optinsYear?.toString() ??
                "2000",
            10
        )
    );

    return (
        <CompanyTypeStyled>
            <GeneralInfoContainer
                component={"fieldset"}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        width: "100%",
                        rowGap: "4px",
                        alignItems: "center",
                        lineHeight: "1.2",
                    }}
                >
                    <strong>User Name:</strong>
                    <span>
                        {EfileNaicOptinsCtx?.formData.optinsInfo?.userName}
                    </span>
                    <strong>Company Name:</strong>
                    <span>
                        {EfileNaicOptinsCtx?.formData.optinsInfo?.companyName}
                    </span>

                    <strong>FEIN:</strong>
                    <span>{EfileNaicOptinsCtx?.formData.optinsInfo?.fein}</span>

                    <strong>NAIC:</strong>
                    <span>{EfileNaicOptinsCtx?.formData.optinsInfo?.naic}</span>

                    <strong>NAIC Group:</strong>
                    <span>
                        {
                            EfileNaicOptinsCtx?.formData.optinsInfo
                                ?.companyNaicGroup
                        }
                    </span>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        justifyContent: "center",
                        marginTop: "20px",
                    }}
                >
                    {FeatureFlags.showOnlyInLocalDevAndQa() ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Testing Year"
                                minDate={optinsYear}
                                maxDate={optinsYear.add(25, "year")}
                                defaultValue={currentYear}
                                openTo="year"
                                views={["year"]}
                                yearsOrder="desc"
                                formatDensity="dense"
                                onChange={(date) => {
                                    EfileNaicOptinsCtx?.setFormData({
                                        ...EfileNaicOptinsCtx.formData,
                                        testingYear: date?.year(),
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        sx: {
                                            width: "120px",
                                            fontSize: "0.875rem",
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    ) : (
                        <strong>{currentYear.year()}</strong>
                    )}
                </Box>
            </GeneralInfoContainer>
            <CompanyTypeSelectorContainer component={"fieldset"}>
                <legend>
                    <strong>Select company type</strong>
                </legend>
                <FormControl fullWidth>
                    <span
                        style={{
                            fontStyle: "italic",
                            marginBottom: "10px",
                        }}
                    >
                        Please verify that your company type is correct
                    </span>
                    <Select
                        labelId="company-type-label"
                        id="company-type-select"
                        value={
                            EfileNaicOptinsCtx?.formData.selectedCompanyType
                                ?.identifier || ""
                        }
                        onChange={handleSelectChange}
                        displayEmpty
                        MenuProps={{
                            disablePortal: true,
                        }}
                    >
                        {EfileNaicOptinsCtx?.formData?.optinsInfo?.companyTypes.map(
                            (companyType) => (
                                <MenuItem
                                    value={companyType.identifier}
                                    key={companyType.identifier}
                                >
                                    {companyType.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </CompanyTypeSelectorContainer>
            {EfileNaicOptinsCtx?.formData.selectedCompanyType && (
                <FilingStatusContainer component={"fieldset"}>
                    <legend>
                        <strong>Status</strong>
                    </legend>
                    {EfileNaicOptinsCtx?.isFetchingFilingStatus && <Loader />}
                    {!EfileNaicOptinsCtx?.isFetchingFilingStatus &&
                        EfileNaicOptinsCtx?.formData.filingStatus && (
                            <>
                                {EfileNaicOptinsCtx?.formData?.filingStatus
                                    ?.response?.trackingNumber && (
                                    <p contentEditable className="cursor-text">
                                        Tracking ID:{" "}
                                        {
                                            EfileNaicOptinsCtx.formData
                                                .filingStatus.response
                                                .trackingNumber
                                        }
                                    </p>
                                )}
                                {getFilingStatus() && (
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item>Filing Status → </Grid>
                                        <Grid item color="success">
                                            <Chip
                                                label={getFilingStatus()}
                                                color={getChipColor(
                                                    getFilingStatus()
                                                )}
                                            ></Chip>
                                        </Grid>
                                    </Grid>
                                )}
                                {EfileNaicOptinsCtx.formData.filingStatus
                                    .pendingPayments.length > 0 && (
                                    <>
                                        <Divider variant="fullWidth">
                                            <strong>Pending Payments</strong>
                                        </Divider>

                                        {EfileNaicOptinsCtx.formData.filingStatus.pendingPayments.map(
                                            (payment, index) => {
                                                return (
                                                    <Accordion
                                                        key={index}
                                                        disableGutters
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={
                                                                <ExpandMore />
                                                            }
                                                        >
                                                            Payment {index + 1}{" "}
                                                            →
                                                            <Chip
                                                                label={`$${payment.amount.toFixed(
                                                                    2
                                                                )}`}
                                                                color="info"
                                                                size="small"
                                                                sx={{
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            ></Chip>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <TableContainer
                                                                component={
                                                                    Paper
                                                                }
                                                            >
                                                                <Table>
                                                                    <TableBody>
                                                                        {Object.entries(
                                                                            payment
                                                                        ).map(
                                                                            ([
                                                                                key,
                                                                                value,
                                                                            ]) => (
                                                                                <TableRow
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                >
                                                                                    <TableCell>
                                                                                        {startCase(
                                                                                            key
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {
                                                                                            value
                                                                                        }
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                );
                                            }
                                        )}
                                    </>
                                )}
                                {EfileNaicOptinsCtx.formData
                                    .filingDeleteStatus && (
                                    <>
                                        <Divider variant="fullWidth">
                                            <strong>Delete Status</strong>
                                        </Divider>
                                        <Grid
                                            container
                                            spacing={1}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Alert severity="warning">
                                                    {
                                                        EfileNaicOptinsCtx
                                                            .formData
                                                            .filingDeleteStatus
                                                            .message
                                                    }
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {EfileNaicOptinsCtx.formData.filingStatus
                                    ?.clientError && (
                                    <Grid item>
                                        <Alert severity="error">
                                            {
                                                EfileNaicOptinsCtx.formData
                                                    .filingStatus?.clientError
                                                    .message
                                            }
                                        </Alert>
                                    </Grid>
                                )}

                                <Divider variant="fullWidth">
                                    <strong>Actions</strong>
                                </Divider>
                                <Button
                                    onClick={EfileNaicOptinsCtx.onReset}
                                    variant="contained"
                                    color="info"
                                >
                                    Check Filling Status
                                </Button>
                                {EfileNaicOptinsCtx.formData.filingStatus
                                    .filingStatus &&
                                    EfileNaicOptinsCtx.formData.filingStatus
                                        .filingStatus != "PROCESSED" && (
                                        <ActionsContainer>
                                            {!EfileNaicOptinsCtx.formData
                                                .filingDeleteStatus && (
                                                <Button
                                                    endIcon={
                                                        isDeleting ? (
                                                            <Loader
                                                                size={12}
                                                                color="white"
                                                            />
                                                        ) : (
                                                            <Delete />
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="error"
                                                    onClick={handleDelete}
                                                >
                                                    delete{" "}
                                                    {EfileNaicOptinsCtx.formData.filingStatus.filingStatus.replace(
                                                        "PROCESSED & ",
                                                        ""
                                                    )}
                                                </Button>
                                            )}
                                        </ActionsContainer>
                                    )}
                            </>
                        )}
                </FilingStatusContainer>
            )}
        </CompanyTypeStyled>
    );
};
