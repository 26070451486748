import { FilingStatus } from "src/types";

/**
 * Returns the filing status and payment info from a string
 * @param str - The string to parse
 * @returns The filing status and payment info
 */
export const getFilingStatusAndPaymentInfo = (
    str: string
): {
    filingStatus: FilingStatus;
    info: string;
} => {
    let [filingStatus, payment] = str.split(" & ");
    return {
        filingStatus: filingStatus as FilingStatus,
        info: payment,
    };
};

export const getChipColor = (filingStatus: FilingStatus) => {

    switch (filingStatus) {
        case "PENDING":
            return "warning";
        case "PROCESSED":
            return "success";
        case "SCHEDULED":
            return "info";
        case "FAILED":
            return "error";
    }

}