import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    AccountOptions,
    AllocateResponse,
    EffectiveDate,
    KentuckyAgentUserOptions,
    UsageReport,
} from "src/types";

interface AgentState {
    value: {
        document: string;
        usageReport?: UsageReport;
        effectiveDate?: EffectiveDate;
        accountOptions?: AccountOptions[];
        kentuckyAgentUserOptions?: KentuckyAgentUserOptions[];
        allocateAddressResult?: AllocateResponse;
    };
}

const initialState: AgentState = {
    value: {
        document: "Agent Home",
        usageReport: undefined,
        effectiveDate: undefined,
        accountOptions: undefined,
        kentuckyAgentUserOptions: undefined,
        allocateAddressResult: undefined,
    },
};

export const AgentSlice = createSlice({
    name: "Agent",
    initialState,
    reducers: {
        setDocument: (state, action: PayloadAction<string>) => {
            state.value.document = action.payload;
        },
        setUsageReport: (state, action: PayloadAction<UsageReport>) => {
            state.value.usageReport = action.payload;
        },
        setEffectiveDate: (state, action: PayloadAction<EffectiveDate>) => {
            state.value.effectiveDate = action.payload;
        },
        setAccountOptions: (state, action: PayloadAction<AccountOptions[]>) => {
            state.value.accountOptions = action.payload;
        },
        setKentuckyAgentUserOptions: (
            state,
            action: PayloadAction<KentuckyAgentUserOptions[]>
        ) => {
            state.value.kentuckyAgentUserOptions = action.payload;
        },
        setAllocateAddressResult: (
            state,
            action: PayloadAction<AllocateResponse>
        ) => {
            state.value.allocateAddressResult = action.payload;
        },
    },
});

export const {
    setDocument,
    setUsageReport,
    setEffectiveDate,
    setAccountOptions,
    setKentuckyAgentUserOptions,
    setAllocateAddressResult,
} = AgentSlice.actions;
export default AgentSlice.reducer;
