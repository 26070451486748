import React, { useContext, useEffect } from "react";
import { startCase } from "lodash";
import { NumericFormat } from "react-number-format";

import {
    EfileNaicOptinsContext,
    EfileNaicOptinsContextState,
} from "../EfileNaicOptins.context";
import { Box, TextField, Typography } from "@mui/material";
import { AllocationType } from "src/types";

export const AllocatePayments: React.FC = () => {
    const EfileNaicOptinsCtx: EfileNaicOptinsContextState | undefined =
        useContext(EfileNaicOptinsContext);

    useEffect(() => {
        if (
            EfileNaicOptinsCtx?.formData.allocationTypes &&
            EfileNaicOptinsCtx?.formData.allocationTypes?.allocations.types
                .length > 0
        ) {
            EfileNaicOptinsCtx?.setFormData({
                ...EfileNaicOptinsCtx.formData,
                allocatePaymentsTotal:
                    EfileNaicOptinsCtx?.formData.allocationTypes?.allocations.types.reduce(
                        (acc, type, idx) => {
                            return (
                                acc +
                                Number(
                                    EfileNaicOptinsCtx?.formData.allocationTypes
                                        ?.allocations.types[idx]
                                        .defaultPaymentAmount || 0
                                )
                            );
                        },
                        0
                    ),
            });
        }
    }, [EfileNaicOptinsCtx]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
            }}
        >
            <p>
                Allocate the total amount entered above to specific payment
                categories prior to submitting the return.
            </p>
            {EfileNaicOptinsCtx?.formData.allocationTypes &&
                EfileNaicOptinsCtx.formData.allocationTypes.allocations.types.map(
                    (type: AllocationType) => (
                        <Box
                            key={type.typeId}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                flexWrap: "wrap",
                                gap: "10px",
                            }}
                        >
                            <Typography variant="body1">{type.name}</Typography>
                            <NumericFormat
                                value={Number(type.defaultPaymentAmount)}
                                sx={{
                                    marginLeft: "auto",
                                    maxWidth: "150px",
                                }}
                                onValueChange={(values, sourceInfo) => {
                                    EfileNaicOptinsCtx.setFormData({
                                        ...EfileNaicOptinsCtx.formData,
                                        allocationTypes: {
                                            ...EfileNaicOptinsCtx.formData
                                                .allocationTypes,
                                            allocations: {
                                                types: [
                                                    ...(EfileNaicOptinsCtx.formData.allocationTypes?.allocations.types.map(
                                                        (allocation) => {
                                                            if (
                                                                allocation.typeId ===
                                                                type.typeId
                                                            ) {
                                                                return {
                                                                    ...allocation,
                                                                    defaultPaymentAmount:
                                                                        values.value,
                                                                };
                                                            }
                                                            return allocation;
                                                        }
                                                    ) || []),
                                                ],
                                            },
                                        },
                                    });
                                }}
                                defaultValue={0}
                                customInput={TextField}
                                thousandSeparator
                                prefix="$"
                                valueIsNumericString
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                    )
                )}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    flexWrap: "wrap",
                    gap: "10px",
                }}
            >
                <strong>Total Allocated:</strong>{" "}
                <NumericFormat
                    value={EfileNaicOptinsCtx?.formData.allocatePaymentsTotal}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    style={{
                        marginLeft: "auto",
                        maxWidth: "150px",
                        fontWeight: "bold",
                        color:
                            EfileNaicOptinsCtx?.formData
                                .allocatePaymentsTotal ===
                            EfileNaicOptinsCtx?.formData.overallPayment
                                ? "green"
                                : "red",
                    }}
                ></NumericFormat>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    flexWrap: "wrap",
                    gap: "10px",
                }}
            >
                <strong>Positive change in the overall payment: </strong>{" "}
                <NumericFormat
                    value={EfileNaicOptinsCtx?.formData.overallPayment}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    style={{
                        marginLeft: "auto",
                        maxWidth: "150px",
                        fontWeight: "bold",
                    }}
                ></NumericFormat>
            </Box>
        </Box>
    );
};
