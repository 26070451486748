import {
    ExportPaymentRequestDownloadable,
    MimeType,
    ReturnDocumentId,
    ScheduleRows,
    ScheduleStatus,
} from "src/types";
import { BaseAPIService } from "./BaseApiService";
import { StateCheckStatus } from "../types/StatesChecked.types";
import { GenericReturnCreationResult } from "../types/GenericReturn.types";

export class GenericReturnService {
    private static _instance: GenericReturnService;
    private readonly BASE_PATH: string = "/api/return-generic";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    public static getInstance(): GenericReturnService {
        if (!GenericReturnService._instance) {
            GenericReturnService._instance = new GenericReturnService();
        }
        return GenericReturnService._instance;
    }

    createGenericReturn(
        data: FormData,
    ): Promise<GenericReturnCreationResult> {
        const res =  this.api.post<GenericReturnCreationResult>(
            this.getPath(
                `/create`
            ),
            data,
            {
                headers: {
                    "Content-Type": MimeType.MULTIPART_FORM_DATA,
                },
            }
        );
        return res
    }
}
