import { useAuth0 } from "@auth0/auth0-react";
import { Button, Modal } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import {
    StyledFlexContainer,
    StyledModalContainer,
} from "src/components/PaymentRequestExport/PaymentRequestExport.styled";
import {
    PAYMENT_REQUEST_EXPORT_TAB,
    PaymentRequestExportInitialState,
} from "src/constants";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { ScheduleService, SubAccountOptionsService } from "src/services";
import { scheduleOnlyFieldsQuery } from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { getModuleId, getScheduleId } from "src/services/Utility";
import {
    ExportSchedulePayload,
    FixMeLater,
} from "src/types";
import { downloadFile } from "src/utils";
import ContainerWithResizableSideBar from "../ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import Loader from "../Loader/Loader";
import "./PaymentRequestExport.scss";
import FixedWidth from "./components/FixedWidth/FixedWidth";
import General from "./components/General/General";
import Menu from "./components/Menu/Menu";
import Options from "./components/Options/Options";
import SelectFields from "./components/SelectFields/SelectFields";

interface PaymentRequestExportProps {
    handleSnackbar: (message: string, severity: string) => void;
}

const PaymentRequestExport: FC<PaymentRequestExportProps> = ({
    handleSnackbar,
}) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAppend, setIsAppend] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company,
    );
    const paymentRequestExport = useAppSelector(
        (state) => state?.[product?.productName]?.value?.paymentRequestExport,
    );
    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev,
    );
    const municipalQuarter: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter,
    );
    const dispatch = useAppDispatch();
    const scheduleService = ScheduleService.getInstance();
    const subAccountOptionsService = SubAccountOptionsService.getInstance();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        setIsFetching(true);

        const fetchData = async () => {
            try {
                const accessToken = await getAccessTokenSilently();

                const variables = {
                    scheduleInput: {
                        companyId: company?.id,
                        productId: ProductNameToIdMap.get(product?.productName),
                        taxYearId: product?.taxYear,
                        moduleId: getModuleId(product, company, municipalState),
                        folderId:"104",
                        returnId: getScheduleId(product, municipalQuarter),
                        pageNumber: "0",
                    },
                };

                const { scheduleTemplateDTO }: FixMeLater =
                    await GQLService.fetchGraphQLData(
                        scheduleOnlyFieldsQuery,
                        variables,
                        accessToken,
                    );
                const additionalColumns = getAdditionalColumns(product.productName, scheduleTemplateDTO.columnTemplateDTOs.length)
                const scheduleTemplateDTO1 =
                    {...scheduleTemplateDTO,
                        columnTemplateDTOs: [...scheduleTemplateDTO.columnTemplateDTOs, ...additionalColumns]
                    };
                const savedValues =
                    await subAccountOptionsService.getSubAccountOptions(
                        company?.id,
                    );

                dispatch(
                    GlobalStateActions[
                        product?.productName
                    ]?.setPaymentRequestExport({
                        ...paymentRequestExport,
                        scheduleTemplateDTO: scheduleTemplateDTO1,
                        payload: savedValues,
                    }),
                );
            } catch (error: FixMeLater) {
                handleSnackbar(
                    `There has been an error fetching data. ${error?.message}`,
                    "error",
                );
            } finally {
                setIsFetching(false);
            }
        };

        if (paymentRequestExport?.open) fetchData();
    }, [paymentRequestExport?.open]);


    const getAdditionalColumns = (p: string, length: number): Array<
        {
            index: number;
            columnTemplate: { id: number; name: string }
        }
        > => {
        if (p === 'Premium') {
            return [
                {
                    index: length,
                    columnTemplate: {
                        id: length,
                        name: `NAIC Comp Num`
                    }
                },
                {
                    index: length + 1,
                    columnTemplate: {
                        id: length + 1,
                        name: `NAIC Grp Num`
                    }
                },
                {
                    index: length + 2,
                    columnTemplate: {
                        id: length + 2,
                        name: `FEIN`
                    }
                },
                {
                    index: length + 3,
                    columnTemplate: {
                        id: length + 3,
                        name: `Company Name`
                    }
                },
                {
                    index: length + 4,
                    columnTemplate: {
                        id: length + 4,
                        name: `Company Addr`
                    }
                },
                {
                    index: length + 5,
                    columnTemplate: {
                        id: length + 5,
                        name: `Company City`
                    }
                },
                {
                    index: length + 6,
                    columnTemplate: {
                        id: length + 6,
                        name: `Company State`
                    }
                },
                {
                    index: length + 7,
                    columnTemplate: {
                        id: length + 7,
                        name: `Company Zip`
                    }
                },
                {
                    index: length + 8,
                    columnTemplate: {
                        id: length + 8,
                        name: `User Name`
                    }
                },
                {
                    index: length + 9,
                    columnTemplate: {
                        id: length + 9,
                        name: `Addenda Record`
                    }
                }
            ];
        } else {
            return [
                {
                    index: length,
                    columnTemplate: {
                        id: length,
                        name: `NAIC Comp Num`
                    }
                },
                {
                    index: length + 1,
                    columnTemplate: {
                        id: length + 1,
                        name: `NAIC Grp Num`
                    }
                },
                {
                    index: length + 2,
                    columnTemplate: {
                        id: length + 2,
                        name: `FEIN`
                    }
                },
                {
                    index: length + 3,
                    columnTemplate: {
                        id: length + 3,
                        name: `Company Name`
                    }
                },
                {
                    index: length + 4,
                    columnTemplate: {
                        id: length + 4,
                        name: `Company Addr`
                    }
                },
                {
                    index: length + 5,
                    columnTemplate: {
                        id: length + 5,
                        name: `Company City`
                    }
                },
                {
                    index: length + 6,
                    columnTemplate: {
                        id: length + 6,
                        name: `Company State`
                    }
                },
                {
                    index: length + 7,
                    columnTemplate: {
                        id: length + 7,
                        name: `Company Zip`
                    }
                },
                {
                    index: length + 8,
                    columnTemplate: {
                        id: length + 8,
                        name: `User Name`
                    }
                }
            ];
        }
    };

    const handleClose = () => {
        setIsAppend(false);
        setFile(null);
        // reset redux state for payment request export
        dispatch(
            GlobalStateActions[product?.productName]?.setPaymentRequestExport(
                PaymentRequestExportInitialState,
            ),
        );
    };

    const exportPaymentRequest = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            if (file) {
                formData.append("file", file, file?.name);
            }
            const payload: ExportSchedulePayload = {
                exportOptions: paymentRequestExport?.payload,
                scheduleId: getScheduleId(product, municipalQuarter),
                scheduleKey: {
                    companyId: company?.id,
                    productId:
                        ProductNameToIdMap.get(product?.productName) || 0,
                    moduleId:
                        Number(getModuleId(product, company, municipalState)) ||
                        0,
                    taxYear: product?.taxYear,
                },
            };

            const attachmentMetadata = new Blob([JSON.stringify(payload)], {
                type: "application/json",
            });

            formData.append("exportInput", attachmentMetadata);

            const { csvFile, contentDispositionHeader } =
                await scheduleService.getExportPaymentRequestCSV(formData);

            const defaultExportedFileName = `${company?.coabbr}-${product?.productName === "Municipal" ? `${municipalState || ""}-${municipalQuarter || ""}-` : ""}${product?.taxYear || ""}-PaymentRequests.csv`;

            downloadFile(
                csvFile,
                contentDispositionHeader?.split("filename=")[1] ||
                    defaultExportedFileName,
            );

            await subAccountOptionsService.saveExportPaymentRequestOptions(
                company?.id,
                payload?.exportOptions,
            );

            handleSnackbar("Exported successfully", "success");
            handleClose();
        } catch (error: FixMeLater) {
            handleSnackbar(
                `There has been an error exporting the payment request. ${error?.message}`,
                "error",
            );
        } finally {
            setIsLoading(false);
        }
    };

    const getContent = () => {
        const selectedTab = paymentRequestExport?.selectedTab;
        if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.GENERAL) {
            return (
                <General
                    isAppend={isAppend}
                    setIsAppend={setIsAppend}
                    file={file}
                    setFile={setFile}
                    handleSnackbar={handleSnackbar}
                />
            );
        } else if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.OPTIONS) {
            return <Options />;
        } else if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.SELECT_FIELDS) {
            return <SelectFields />;
        } else if (selectedTab === PAYMENT_REQUEST_EXPORT_TAB.FIXED_WIDTH) {
            return <FixedWidth />;
        } else {
            return <div>Please select a tab.</div>;
        }
    };

    const content = (
        <>
            <div className="content-container">{getContent()}</div>
            <div className="action-buttons-container">
                <StyledFlexContainer justify={"center"}>
                    <Button
                        onClick={() => handleClose()}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => exportPaymentRequest()}
                        color="primary"
                        variant="contained"
                    >
                        Export
                    </Button>
                </StyledFlexContainer>
            </div>
        </>
    );

    return (
        <Modal open={paymentRequestExport?.open} onClose={handleClose}>
            <div className="payment-request-export-container">
                <StyledModalContainer>
                    <div className="modal-header">Export Payment Request</div>
                    {isFetching || isLoading ? (
                        <Loader />
                    ) : (
                        <ContainerWithResizableSideBar
                            sideBarContent={<Menu />}
                            content={content}
                            initialWidth={160}
                            resizeEnabled={false}
                            toolbar={undefined}
                        />
                    )}
                </StyledModalContainer>
            </div>
        </Modal>
    );
};

export default PaymentRequestExport;
