import { useContext } from "react";
import {
    EfileNaicOptinsContextState,
    EfileNaicOptinsContext,
} from "../EfileNaicOptins.context";
import {
    Box,
    Alert,
    Chip,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
} from "@mui/material";
import React from "react";

export const SubmitResponse: React.FC = () => {
    const { formData, onReset }: EfileNaicOptinsContextState = useContext(
        EfileNaicOptinsContext
    );
    const { submitResponse } = formData;

    const renderErrorMessage = () => {
        if (submitResponse?.clientError?.message) {
            return (
                <Alert severity="warning">
                    <Typography component="span" sx={{ userSelect: "text" }}>
                        There was an error with your submission: {submitResponse.clientError.message}
                    </Typography>
                </Alert>
            );
        }
        if (submitResponse?.serverError?.message) {
            return (
                <Alert severity="error">
                    <Typography component="span" sx={{ userSelect: "text" }}>
                        An unexpected error occurred on the server: {submitResponse.serverError.message}
                    </Typography>
                </Alert>
            );
        }
        return null;
    };

    const renderSuccessMessage = () => {
        if (!submitResponse?.response.trackingNumber) {
            return (
                <Alert severity="info">
                    <Typography component="span" sx={{ userSelect: "text" }}>
                        Something went wrong. Please try submitting again.
                    </Typography>
                </Alert>
            );
        }

        return (
            <>
                <Alert severity="success" sx={{ mb: 2 }}>
                    <Typography component="span" sx={{ userSelect: "text" }}>
                        ✅ Filing submitted successfully!
                    </Typography>
                </Alert>
                <Card variant="outlined" sx={{ width: "100%", p: 2, borderRadius: 2, boxShadow: 2 }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={4}>
                                <Typography fontWeight="bold">Tracking Number:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography component="span" sx={{ userSelect: "text" }}>
                                    {submitResponse.response.trackingNumber}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography fontWeight="bold">Version ID:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography component="span" sx={{ userSelect: "text" }}>
                                    {submitResponse.response.versionId}
                                </Typography>
                            </Grid>
                            {submitResponse.achBankMemo && (
                                <>
                                    <Grid item xs={12} sm={4}>
                                        <Typography fontWeight="bold">ACH Bank Memo:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography component="span" sx={{ userSelect: "text", wordBreak: "break-word" }}>
                                            {submitResponse.achBankMemo}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </>
        );
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3, p: 3 }}>
            {renderErrorMessage() || renderSuccessMessage()}
            <CardActions sx={{ justifyContent: "center", width: "100%" }}>
                <Button onClick={onReset} variant="contained" color="primary" sx={{ fontSize: "1rem", px: 4, py: 1 }}>
                    Check Filing
                </Button>
            </CardActions>
        </Box>
    );
};
