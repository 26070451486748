import React, { FC, useState } from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import {
    CustomColumnMenu,
    CustomFooter,
    resetAllToDefault,
    handleStateToggleChange,
    handleDropdownChange,
    handleEstimateBasisDropdownChange,
    getColumns,
    getRows,
    columnGroupingModel,
} from "./JurisdictionOptions.helpers";

interface JurisdictionOptionsProps {
    formData: any;
    onFormDataChange: (newData: any) => void;
}

const JurisdictionOptions: FC<JurisdictionOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    // State for all retal format and estimate basis overrides
    const [retalFormatAll, setRetalFormatAll] = useState<string>("");
    const [estimateBasisAll, setEstimateBasisAll] = useState<string>("");

    // State for display info
    const [displayInfo, setDisplayInfo] = useState<boolean>(false);

    // State for confirmation dialog
    const [openResetDialog, setOpenResetDialog] = useState<boolean>(false);

    const columns = getColumns(
        (event, state, option) => handleStateToggleChange(event, state, option, formData, onFormDataChange),
        (event, state) => handleDropdownChange(event, state, formData, onFormDataChange),
        (event, state) => handleEstimateBasisDropdownChange(event, state, formData, onFormDataChange)
    );

    const rows = getRows(formData);

    const handleResetClick = () => {
        setOpenResetDialog(true);
    };

    const handleResetConfirm = () => {
        resetAllToDefault(
            formData,
            onFormDataChange,
            setRetalFormatAll,
            setEstimateBasisAll
        );
        setOpenResetDialog(false);
    };

    const handleResetCancel = () => {
        setOpenResetDialog(false);
    };

    return (
        <Box
            sx={{
                height: 500,
                justifyContent: "center",
                width: "90%",
            }}
        >
            <DataGridPremium
                rows={rows}
                columns={columns}
                checkboxSelection={false}
                disableRowSelectionOnClick
                scrollbarSize={10}
                autoPageSize={true}
                columnGroupingModel={columnGroupingModel}
                columnHeaderHeight={30}
                columnGroupHeaderHeight={30}
                density="standard"
                slots={{
                    columnMenu: (props) => (
                        <CustomColumnMenu
                            {...props}
                            formData={formData}
                            onFormDataChange={onFormDataChange}
                            retalFormatAll={retalFormatAll}
                            setRetalFormatAll={setRetalFormatAll}
                            estimateBasisAll={estimateBasisAll}
                            setEstimateBasisAll={setEstimateBasisAll}
                        />
                    ),
                    footer: () => (
                        <CustomFooter
                            displayInfo={displayInfo}
                            setDisplayInfo={setDisplayInfo}
                            resetAllToDefault={handleResetClick}
                        />
                    ),
                }}
                sx={{
                    "& .MuiDataGrid-columnHeaders": {
                        'div[role="row"]': {
                            backgroundColor: "#f9f9f9",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                    },
                }}
            />

            <Dialog
                open={openResetDialog}
                onClose={handleResetCancel}
                aria-labelledby="reset-dialog-title"
            >
                <DialogTitle id="reset-dialog-title">
                    Reset All to System Defaults
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to reset all values to their defaults?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResetCancel}>Cancel</Button>
                    <Button onClick={handleResetConfirm} color="error" variant="contained">
                        Reset All
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default JurisdictionOptions;
