import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import { Modal } from "../../uikit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import React, { FC, useEffect, useState } from "react";
import {
    DropEvent,
    ErrorCode,
    FileRejection,
    useDropzone,
} from "react-dropzone";
import Loader from "src/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "src/hooks";
import colors from "src/styles/colors.scss";
import { FixMeLater } from "src/types";
import { HttpError } from "src/utils/HttpError";
import { zipRegex } from "../../constants/Regex";
import { USStateAbbreviations } from "../../constants/States";
import { StyledFlexContainer } from "../../products/AccountAdmin/AccountAdmin.styled";
import GlobalStateActions from "../../redux/slices/GlobalStateActions";
import { GenericReturnService } from "../../services/GenericReturnService";
import { returnTreeQuery } from "../../services/GQLQueries";
import GQLService from "../../services/GQLService";
import { getModuleId } from "../../services/Utility";
import { GenericReturnCreationResult } from "../../types/GenericReturn.types";
import "./GenericReturn.scss";
import {
    ACCEPTED_FILE_TYPES,
    getErrorMessageByCode,
} from "./GenericReturn.util";

interface GenericReturnCreateKey {
    companyId: string;
    moduleId: string;
    productId: string;
    taxYearId: string;
    folderId: string;
}

interface GenericReturnProps {
    genericReturnCreateKey: GenericReturnCreateKey;
    folderNodeForGenericReturn: FixMeLater;
    open: boolean;
    onClose: () => void;
    handleSnackbar: (message: string, severity: string) => void;
}

const GenericReturn: FC<GenericReturnProps> = ({
    genericReturnCreateKey,
    folderNodeForGenericReturn,
    open,
    onClose,
    handleSnackbar,
}) => {
    const dispatch = useAppDispatch();
    const genericReturnService = GenericReturnService.getInstance();
    const { getAccessTokenSilently } = useAuth0();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );

    const initialFormData = {
        formName: "",
        dueDate: "",
        payee: "",
        addressOption: "",
        address1: "",
        address2: "",
        addressCity: "",
        addressState: "",
        addressZip: "",
        url: "",
        amount: "",
    };

    const addressOptions = ["Mailing Address", "Physical Address"];
    const [formData, setFormData] = useState(initialFormData);
    const [dueDateObj, setDueDateObj] = useState<Dayjs | null>(null);

    const resetForm = () => {
        setFormData(initialFormData);
        setDueDateObj(null);
        setSelectedFile(null);
        setFormIsValid(false);
        setHasUnsavedChanges(false);
    };

    const safeCloseModal = () => {
        resetForm();
        onClose();
    };

    const handleAttemptClose = () => {
        if (hasUnsavedChanges) {
            setConfirmOpen(true);
        } else {
            safeCloseModal();
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: isLoading,
        multiple: false,
        accept: ACCEPTED_FILE_TYPES,
        onDrop: (
            acceptedFiles: File[],
            rejectedFiles: FileRejection[],
            event: DropEvent | Event
        ) => {
            setHasUnsavedChanges(true);
            if (rejectedFiles?.length) {
                handleRejectedFileCode(
                    rejectedFiles[0]?.errors[0]?.code,
                    event ?? new Event("genericReturn")
                );
            } else {
                setSelectedFile(acceptedFiles[0]);
            }
        },
    });

    const acceptStyle = {
        border: `1px dashed ${colors.allocatorSelectedContainerBorderColor}`,
        backgroundColor: colors.allocatorComponentBackgroundColor,
    };

    const handleRejectedFileCode = (
        errorCode: ErrorCode | string,
        event: DropEvent | Event
    ) => {
        safeCloseModal();
        handleSnackbar(getErrorMessageByCode(errorCode), "error");
    };

    const validateForm = (): boolean => {
        return (
            !!formData.formName &&
            !!dueDateObj &&
            dueDateObj.isValid() &&
            !!formData.payee &&
            !!formData.addressOption &&
            !!formData.addressState &&
            zipRegex.test(formData.addressZip) &&
            !!formData.url &&
            !!formData.amount &&
            !isNaN(Number(formData.amount))
        );
    };

    useEffect(() => {
        setFormIsValid(validateForm());
    }, [formData, dueDateObj]);

    useEffect(() => {
        if (open) {
            setHasUnsavedChanges(false);
        }
    }, [open]);

    const handleUpload = async (event: DropEvent | Event) => {
        if (!selectedFile || !validateForm()) return;

        setIsLoading(true);

        const updatedFormData = {
            ...formData,
            dueDate: dueDateObj?.format("MM/DD/YYYY") || "",
        };

        const fileFormData = new FormData();
        fileFormData.append("file", selectedFile, selectedFile.name);

        const attachmentMetadata = new Blob(
            [JSON.stringify(genericReturnCreateKey)],
            { type: "application/json" }
        );
        const formMetadata = new Blob([JSON.stringify(updatedFormData)], {
            type: "application/json",
        });

        fileFormData.append("scheduleInput", attachmentMetadata);
        fileFormData.append("formData", formMetadata);

        try {
            const response: GenericReturnCreationResult =
                await genericReturnService.createGenericReturn(fileFormData);
            if (response.errorMessage.includes("successfully")) {
                handleSnackbar(response.errorMessage, "success");
                dispatch(GlobalStateActions.setToogleFetch(true));
            } else {
                handleSnackbar(response.errorMessage, "error");
            }
        } catch (error: FixMeLater) {
            if (error instanceof HttpError) {
                handleSnackbar(JSON.parse(error?.body)?.message, "error");
            } else {
                handleSnackbar("Error creating generic return", "error");
            }
        } finally {
            setIsLoading(false);
            safeCloseModal();
        }

        const treeInput = {
            companyId: company.id,
            productId: product?.productId,
            taxYearId: product?.taxYear,
            moduleId: getModuleId(product, company, null),
        };
        const accessToken = await getAccessTokenSilently();

        const data: FixMeLater = await GQLService.fetchGraphQLData(
            returnTreeQuery,
            { treeInput },
            accessToken
        );

        dispatch(GlobalStateActions[product?.productName].setTree(data.tree));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setHasUnsavedChanges(true);
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleAttemptClose}
                title={`Create Generic Return for ${folderNodeForGenericReturn?.attributes?.displayName}`}
            >
                <div style={{ width: "1000px" }}>
                    <StyledFlexContainer>
                        <TextField
                            sx={{ marginY: 1 }}
                            variant="outlined"
                            required
                            fullWidth
                            id="formName"
                            label="Form Name"
                            name="formName"
                            value={formData.formName}
                            onChange={handleInputChange}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Due Date"
                                value={dueDateObj}
                                onChange={(newValue) => {
                                    setDueDateObj(newValue);
                                    setHasUnsavedChanges(true);
                                }}
                                slotProps={{
                                    textField: {
                                        variant: "outlined",
                                        fullWidth: true,
                                        sx: { marginY: 1 },
                                        required: true,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                        <TextField
                            sx={{ marginY: 1 }}
                            variant="outlined"
                            fullWidth
                            required
                            id="payee"
                            label="Payee/Addressee"
                            name="payee"
                            value={formData.payee}
                            onChange={handleInputChange}
                        />
                    </StyledFlexContainer>

                    <Autocomplete
                        sx={{ marginY: 1 }}
                        id="addressOption"
                        options={addressOptions}
                        value={formData.addressOption}
                        onChange={(event, newValue) => {
                            handleInputChange({
                                target: {
                                    name: "addressOption",
                                    value: newValue ?? "",
                                },
                            } as React.ChangeEvent<HTMLInputElement>);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Address Option"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        )}
                        fullWidth
                        autoHighlight
                    />

                    <TextField
                        sx={{ marginY: 1 }}
                        variant="outlined"
                        required
                        fullWidth
                        id="address1"
                        label="Address1"
                        name="address1"
                        value={formData.address1}
                        onChange={handleInputChange}
                    />
                    <TextField
                        sx={{ marginY: 1 }}
                        variant="outlined"
                        fullWidth
                        id="address2"
                        label="Address2"
                        name="address2"
                        value={formData.address2}
                        onChange={handleInputChange}
                    />

                    <StyledFlexContainer>
                        <TextField
                            sx={{ marginY: 1 }}
                            variant="outlined"
                            required
                            fullWidth
                            id="addressCity"
                            label="City"
                            name="addressCity"
                            value={formData.addressCity}
                            onChange={handleInputChange}
                        />
                        <Autocomplete
                            sx={{ marginY: 1 }}
                            id="addressState"
                            options={USStateAbbreviations}
                            value={formData.addressState}
                            onChange={(event, newValue) => {
                                handleInputChange({
                                    target: {
                                        name: "addressState",
                                        value: newValue ?? "",
                                    },
                                } as React.ChangeEvent<HTMLInputElement>);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                            )}
                            fullWidth
                            autoHighlight
                        />

                        <TextField
                            sx={{ marginY: 1 }}
                            variant="outlined"
                            required
                            fullWidth
                            id="addressZip"
                            label="Zip Code"
                            name="addressZip"
                            value={formData.addressZip}
                            onChange={handleInputChange}
                        />
                    </StyledFlexContainer>

                    <StyledFlexContainer>
                        <TextField
                            sx={{ marginY: 1 }}
                            variant="outlined"
                            required
                            fullWidth
                            id="url"
                            label="URL"
                            name="url"
                            value={formData.url}
                            onChange={handleInputChange}
                        />
                        <TextField
                            sx={{ marginY: 1 }}
                            variant="outlined"
                            required
                            fullWidth
                            id="amount"
                            label="Amount"
                            name="amount"
                            type="number"
                            value={formData.amount}
                            onChange={handleInputChange}
                        />
                    </StyledFlexContainer>

                    <div
                        data-testid="dropzone"
                        {...getRootProps({
                            className: "generic-return-data-dropzone",
                            style: isDragActive ? acceptStyle : {},
                        })}
                    >
                        {isLoading ? (
                            <Loader color={colors.primaryColor} />
                        ) : (
                            <div className="generic-return-data-content">
                                <input {...getInputProps()} />
                                <Upload
                                    fontSize="medium"
                                    style={{ color: colors.primaryColor }}
                                />
                                {selectedFile ? (
                                    <p>
                                        Selected file:{" "}
                                        <strong>{selectedFile.name}</strong>
                                    </p>
                                ) : isDragActive ? (
                                    <p>Drop a document here</p>
                                ) : (
                                    <p>Click or drag PDF (.pdf) file</p>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="create-button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={
                                !selectedFile || !formIsValid || isLoading
                            }
                            onClick={(e) => handleUpload(e as unknown as Event)}
                        >
                            Create
                        </Button>
                    </div>
                </div>
            </Modal>

            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <DialogTitle>Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You have unsaved changes. Are you sure you want to
                        discard them?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setConfirmOpen(false);
                            safeCloseModal();
                        }}
                        color="error"
                    >
                        Discard Changes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GenericReturn;
