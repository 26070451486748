import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Company, MigrationCompany } from "src/types";

interface MigrationCompaniesState {
    value: MigrationCompany[]
}

const initialState: MigrationCompaniesState = {
    value: [],
};

export const MigrationCompaniesSlice = createSlice({
    name: "MigrationCompanies",
    initialState,
    reducers: {
        setMigrationCompanies: (state, action: PayloadAction<MigrationCompany[]>) => {
            state.value = action.payload;
        },
    },
});

export const { setMigrationCompanies } = MigrationCompaniesSlice.actions;
export default MigrationCompaniesSlice.reducer;
