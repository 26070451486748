import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { getModuleId } from "src/services/Utility";

import {
    Company,
    FolderNode,
    Product,
    QueryParams,
    ReturnDocumentId,
    ReturnNode,
    State,
    ToolbarItem,
    ToolbarItemName,
} from "src/types";

import {
    AttachEmail as AttachEmailIcon,
    AttachFile as AttachFileIcon,
    ContentPasteGo as ContentPasteGoIcon,
    Info as InfoIcon,
    Save as SaveIcon,
    SaveAlt as SaveAltIcon,
    EditNote,
    Password as PasswordIcon,
    OpenInNew,
} from "@mui/icons-material";
import ExplicitIcon from "@mui/icons-material/Explicit";
import { ReturnPDFService } from "src/services";
import { CreateReturnPDFPayload } from "src/types";
import { downloadFile, getQueryParams } from "src/utils";
import { HttpError } from "src/utils/HttpError";
import PaymentRequestIcon from "../../assets/images/toolbar-icons/payment-request.png";
import PreviousReturnIcon from "../../assets/images/toolbar-icons/previous-return.png";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import FeatureFlags from "src/utils/FeatureFlags";
import { filterForReturnNodes, isValidWebLink } from "src/utils/TreeUtils";

const returnPDFService = ReturnPDFService.getInstance();

export async function onInstructionsClick(
    product: Product,
    folderNode: FolderNode,
    company: Company,
    returnNode: ReturnNode,
    handleSnackbar: (message: string, severity: string) => void,
    municipalState: string,
    dropdownStateView: boolean,
    selectedState: State
) {
    const params: QueryParams = getQueryParams({
        productId: product.productId,
        taxYearId: product.taxYear,
        folderId: dropdownStateView
            ? selectedState?.id
            : (folderNode.id as number),
        moduleId: getModuleId(
            product,
            dropdownStateView && folderNode ? folderNode : company || {},
            municipalState
        ),
        returnId: returnNode.id,
        retalFolderId: returnNode.retalFolderId,
        companyId: dropdownStateView ? folderNode?.id?.toString() : company.id,
    });

    try {
        const pdf = await returnPDFService.getReturnPDFInstructions(params);
        const blob = new Blob([pdf], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        handleSnackbar("Instructions loaded successfully", "success");
        window.open(pdfUrl, "_blank");
    } catch (e) {
        if (e instanceof Error || e instanceof HttpError) {
            if (e.message === "Not Found") {
                handleSnackbar("Error instructions not found", "error");
            } else {
                handleSnackbar("Error downloading instructions", "error");
            }
        }
    }
}

// Toolbar button click handlers
export async function onPrintClick(
    company,
    product,
    folderNode,
    returnNode,
    handleSnackbar,
    municipalState,
    dropdownStateView,
    selectedState
) {
    const returnDocId: CreateReturnPDFPayload = {
        returnDocId: {
            companyId: dropdownStateView
                ? folderNode?.id?.toString()
                : company.id,
            productId: product.productId,
            taxYearId: product.taxYear,
            folderId: dropdownStateView
                ? selectedState?.id
                : (folderNode.id as number),
            moduleId: Number(
                getModuleId(
                    product,
                    dropdownStateView && folderNode
                        ? folderNode
                        : company || {},
                    municipalState
                )
            ),
            returnId: returnNode.id,
            retalFolderId: returnNode.retalFolderId,
        },
        pages: [], // Pages are required, but not used on the backend side; check stratus-api ReturnPdfController -> createReturnPdf function
    };

    try {
        const returnDownloadable =
            await returnPDFService.generateDownloadableAllPdf(returnDocId);
        const { blob, contentDispositionHeader } = returnDownloadable;
        downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
        handleSnackbar("File downloaded successfully", "success");
    } catch (error) {
        handleSnackbar("Error downloading file", "error");
        console.error(error);
    }
}

export function onEfileEmailClick(setEfileEmailModalOpen) {
    setEfileEmailModalOpen(true);
}

export function onEfileClick(setEfileModalOpen) {
    setEfileModalOpen(true);
}

export function onEfileNaicOptinsClick(setEfileNaicOptinsModalOpen) {
    setEfileNaicOptinsModalOpen(true);
}

export async function onButtonClick(setShowPaymentRequest, showPaymentRequest) {
    setShowPaymentRequest(!showPaymentRequest);
}

// Toolbar icon data
export const getIconData = (
    hasPriorYearReturn,
    hasInstructionsPdf,
    isManualSave,
    hasPaymentRequest,
    hasOptinsExport,
    validationResponse,
    returnNode,
    pushDocument,
    setEfileEmailModalOpen,
    setEfileModalOpen,
    returnDocument,
    priorYearReturn,
    product,
    folderNode,
    company,
    handleSnackbar,
    setShowPaymentRequest,
    showPaymentRequest,
    isAttachmentsOpen,
    openAttachments,
    optinsExport,
    municipalState,
    dispatch,
    returnNotes,
    onOpenPriorYearReturn,
    onWebLoginClick,
    dropdownStateView,
    openSidebar,
    setOpenSidebar,
    selectedState,
    setEfileNaicOptinsModalOpen
): ToolbarItem[] => {
    const isReady =
        returnNode.isReady || FeatureFlags.showOnlyInLocalDevAndQa();

    return [
        {
            position: 1,
            icon: PreviousReturnIcon,
            title: ToolbarItemName.OPEN_PRIOR_YEARS_RETURN,
            onClick: () => onOpenPriorYearReturn(priorYearReturn),
            isEnabled: hasPriorYearReturn,
            isActive: true,
            isValid: true,
        },
        {
            position: 2,
            icon: InfoIcon,
            title: ToolbarItemName.INSTRUCTIONS,
            onClick: () =>
                onInstructionsClick(
                    product,
                    folderNode,
                    company,
                    returnNode,
                    handleSnackbar,
                    municipalState,
                    dropdownStateView,
                    selectedState
                ),
            isEnabled: true,
            isActive: hasInstructionsPdf,
            isValid: true,
        },
        {
            position: 3,
            icon: SaveAltIcon,
            title: ToolbarItemName.DOWNLOAD,
            onClick: () =>
                onPrintClick(
                    company,
                    product,
                    folderNode,
                    returnNode,
                    handleSnackbar,
                    municipalState,
                    dropdownStateView,
                    selectedState
                ),
            isActive: true,
            isValid: true,
            isEnabled: true,
        },
        {
            position: 4,
            icon: SaveIcon,
            title: ToolbarItemName.SAVE,
            onClick: () => pushDocument({ ...returnDocument }),
            isActive: isManualSave,
            isValid: true,
            isEnabled: true,
        },
        {
            position: 5,
            icon: PaymentRequestIcon,
            title: ToolbarItemName.PAYMENT_REQUEST,
            onClick: () =>
                onButtonClick(setShowPaymentRequest, showPaymentRequest),
            isEnabled: true,
            isActive: hasPaymentRequest,
            isValid: true,
        },
        {
            position: 6,
            icon: AttachFileIcon,
            title: ToolbarItemName.ATTACHMENTS,
            onClick: openAttachments,
            isActive: true,
            isValid: true,
            isEnabled: true,
        },
        {
            position: 7,
            icon: ContentPasteGoIcon,
            title: isReady
                ? ToolbarItemName.OPTINS_EXPORT
                : ToolbarItemName.OPTINS_EXPORT_NOT_AVAILABLE,
            onClick: optinsExport,
            isEnabled: isReady,
            isActive: hasOptinsExport,
            isValid: validationResponse?.successful,
        },
        {
            position: 8,
            icon: AttachEmailIcon,
            title: isReady
                ? ToolbarItemName.EFILE_VIA_EMAIL
                : ToolbarItemName.EFILE_VIA_EMAIL_NOT_AVAILABLE,
            onClick: () => onEfileEmailClick(setEfileEmailModalOpen),
            isEnabled: isReady && validationResponse,
            isActive:
                filterForReturnNodes(
                    returnNode,
                    folderNode,
                    company,
                    false,
                    false
                ) && returnNode?.efileEmail,
            isValid: validationResponse?.successful,
        },
        {
            position: 9,
            icon: ExplicitIcon,
            title: isReady
                ? ToolbarItemName.EFILE
                : ToolbarItemName.EFILE_NOT_AVAILABLE,
            onClick: () => onEfileClick(setEfileModalOpen),
            isEnabled: isReady,
            isActive: true,
            isValid: validationResponse?.successful,
        },
        {
            position: 10,
            icon: ExplicitIcon,
            title: isReady
                ? ToolbarItemName.EFILE_NAIC_OPTINS
                : ToolbarItemName.EFILE_NAIC_OPTINS_NOT_AVAILABLE,
            onClick: () => onEfileNaicOptinsClick(setEfileNaicOptinsModalOpen),
            isActive: returnNode?.efileOptins === "OPTINS",
            isEnabled: isReady,
            isValid: validationResponse?.successful,
        },
        {
            position: 11,
            icon: ExplicitIcon,
            title: isReady
                ? ToolbarItemName.EFILE_NAIC_OPTINS_PAYMENT_ONLY
                : ToolbarItemName.EFILE_NAIC_OPTINS_PAYMENT_ONLY_NOT_AVAILABLE,
            onClick: () => onEfileNaicOptinsClick(setEfileNaicOptinsModalOpen),
            isActive: returnNode?.efileOptins === "PAYMENT_ONLY",
            isEnabled: isReady,
            isValid: validationResponse?.successful,
        },
        {
            position: 12,
            icon: EditNote,
            title: ToolbarItemName.NOTES,
            onClick: () => {
                dispatch(
                    GlobalStateActions?.[product?.productName].setReturnNotes({
                        ...returnNotes,
                        showNotes: !returnNotes.showNotes,
                    })
                );
            },
            isEnabled: true,
            isActive: true,
            isValid: true,
        },
        {
            position: 13,
            icon: PasswordIcon,
            title: ToolbarItemName.WEB_LOGIN,
            onClick: () => {
                const returnDocumentId: ReturnDocumentId = {
                    companyId: dropdownStateView
                        ? folderNode?.id?.toString()
                        : company.id,
                    productId: product.productId,
                    taxYearId: product.taxYear,
                    folderId: dropdownStateView
                        ? selectedState?.id
                        : (folderNode.id as number),
                    moduleId: getModuleId(
                        product,
                        dropdownStateView && folderNode
                            ? folderNode
                            : company || {},
                        municipalState
                    ) as unknown as number,
                    returnId: returnNode.id,
                    retalFolderId: returnNode.retalFolderId,
                };
                onWebLoginClick(returnDocumentId);
            },
            isEnabled: isReady,
            isActive:
                (filterForReturnNodes(
                    returnNode,
                    folderNode,
                    company,
                    false,
                    false
                ) &&
                    returnNode?.filingType === "ONLINE") ||
                false,
            isValid: true,
        },
        {
            position: 14,
            icon: OpenInNew,
            title: ToolbarItemName.WEB_LINK,
            onClick: () => {
                window.open(returnNode?.webLink, "_blank");
            },
            isActive: isValidWebLink(returnNode?.webLink),
            isEnabled: isValidWebLink(returnNode?.webLink),
            isValid: isValidWebLink(returnNode?.webLink),
        },
    ];
};
