import { useAuth0 } from "@auth0/auth0-react";
import AppsIcon from "@mui/icons-material/Apps";
import LogoutIcon from "@mui/icons-material/Logout";
import { AppBar, Box, IconButton, Popover } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import colors from "src/styles/colors.scss";
import { Company, FixMeLater, MigrationCompany, Product, User } from "src/types";
import "./ApplicationNavBar.scss";
import {
    StyledGridContainer,
    StyledGridItem,
    StyledNavButton,
    StyledPaper,
    StyledToolbar,
} from "./ApplicationNavBar.styled";
import { getFirstPathSegment } from "src/services/Utility";
import { ValidPaths } from "src/constants/ValidPaths";
import { PRODUCT_NAME, TRITECH_ROLE } from "src/constants";
import PdfControls from "../PdfControls/PdfControls";
import RightContent from "./RightContent";
import Migration from "../Migration/Migration";
import { MockMigrationProduct } from "../../mocks/MockMigrationProduct";
import { UserService } from "../../services";

const ApplicationNavBar: FC = () => {
    const self: User | undefined = useAppSelector(
        (state) => state?.Self
    )?.value;

    const navigate = useNavigate();

    const processedProducts: Product[] = useAppSelector(
        (state) => state?.Products?.value.processedProducts
    );

    const dispatch = useAppDispatch();

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const [activeNavButton, setActiveNavButton] = useState<string>();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { logout } = useAuth0();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const userService = UserService.getInstance();

    const [migrationModalOpen, setMigrationModalOpen] =
        useState<boolean>(false);

    const migrationCompanies: MigrationCompany[] | undefined = useAppSelector(
        (state) => state?.MigrationCompanies?.value,
    );

    const handleHomeClick = () => {
        if (
            self?.pendingChanges?.length &&
            !window.confirm(
                "You have unsaved changes. Do you really want to leave?"
            )
        ) {
            return;
        } else {
            if (self) {
                dispatch(
                    GlobalStateActions.setSelf({
                        ...self,
                        pendingChanges: [],
                        pendingCompanyOptionChanges: false,
                    })
                );
            }
            dispatch(GlobalStateActions.setProduct(undefined));
            navigate("/");
        }
        handleClose();
    };
    if (!self) return null;

    const hasPendingChanges = () => {
        const hasCompanyOptionChanges =
            ["Municipal", "Premium"].includes(product?.productName) &&
            self?.pendingCompanyOptionChanges;

        return self?.pendingChanges?.length || hasCompanyOptionChanges;
    };

    const handleProductClick = async (selectedProduct: Product) => {
        if (selectedProduct.productId === MockMigrationProduct.productId) {
            const companies: Company[] =
                await userService.getUserCompanies(
                    self!.id,
                    3,
                    2024
                );
            dispatch(GlobalStateActions.setCompanies(companies));

            const migrationCompanies: MigrationCompany[] =
                await userService.getUserMigrationCompanies(self!.id);
            dispatch(GlobalStateActions.setMigrationCompanies(migrationCompanies));
            setMigrationModalOpen(true)
            return;
        }
        if (
            hasPendingChanges() &&
            !window.confirm(
                "You have unsaved changes. Do you really want to leave?"
            )
        ) {
            return;
        } else {
            if (self) {
                dispatch(
                    GlobalStateActions.setSelf({
                        ...self,
                        pendingChanges: [],
                        pendingCompanyOptionChanges: false,
                    })
                );
                if (
                    GlobalStateActions[product?.productName]
                        ?.setCompanyOptionsOpen
                ) {
                    dispatch(
                        GlobalStateActions[
                            product?.productName
                        ].setCompanyOptionsOpen(false)
                    );
                }
            }
            if (product?.productId != selectedProduct.productId) {
                dispatch(GlobalStateActions.setProduct(selectedProduct));
                if (
                    ["Municipal", "FormsPlus", "Premium"].includes(
                        selectedProduct?.productName
                    )
                ) {
                    dispatch(
                        GlobalStateActions[
                            selectedProduct?.productName
                        ].setReturnNode(null)
                    );
                    dispatch(
                        GlobalStateActions[
                            selectedProduct?.productName
                        ].setFolderNode(null)
                    );
                }
            }
            handleClose();
        }
    };

    const getProductSoloLogo = product && (
        <img
            className="product-logo-container"
            src={require("../../assets/images/" +
                product?.productName +
                "Solo.svg")}
            alt="icon"
        />
    );

    const handleJobsClick = () => {
        // Check if Allocator route has nested path
        if (window.location.href.includes(`${getFirstPathSegment()}/`)) {
            setActiveNavButton("jobs");
            dispatch(GlobalStateActions.Allocator.setCompanyOptionsOpen(false));
            navigate(`/${getFirstPathSegment()}`);
        }
    };

    const handleToolsClick = () => {
        setActiveNavButton("tools");
        dispatch(GlobalStateActions.Allocator.setCompanyOptionsOpen(false));
        navigate(`/${getFirstPathSegment()}/tools`);
    };

    useEffect(() => {
        if (self?.pendingChanges?.length) {
            return;
        } else {
            if (self) {
                dispatch(
                    GlobalStateActions.setSelf({
                        ...self,
                        pendingChanges: [],
                        pendingCompanyOptionChanges: false,
                    })
                );
            }

            if (!product) {
                const firstPathSegment = getFirstPathSegment();
                const isValidPath = ValidPaths.includes(firstPathSegment);
                if (isValidPath && firstPathSegment !== "not-authorized") {
                    navigate("/");
                }
                return;
            }

            const formattedProductName = product.productName
                .toLowerCase()
                .replace(/\s+/g, "-");

            if (formattedProductName === "allocator") {
                // Check if Allocator route has nested path
                if (
                    !window.location.href.includes(`${getFirstPathSegment()}/`)
                ) {
                    setActiveNavButton("jobs");
                }

                if (window.location.href.includes("tools")) {
                    setActiveNavButton("tools");
                }

                if (!window.location.href.includes("allocator-app")) {
                    setActiveNavButton("jobs");
                    navigate("/allocator-app");
                }

                return;
            }

            // There is a product set in the redux state but the URL does not match the product name
            if (formattedProductName !== getFirstPathSegment()) {
                navigate(`/${formattedProductName}`);
            }
        }
    }, [product]);

    const getIconImg = (productName: string, isDisabled: boolean) => {
        return require(`../../assets/images/product-icons/${productName}${
            isDisabled ? "Disabled" : ""
        }.png`);
    };

    return (
        <>
            <div className="application-nav-bar-container">
                <AppBar position="static">
                    <StyledToolbar>
                        <div className="application-nav-bar-left-container">
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="menu"
                                onClick={handleClick}
                                style={{ color: colors.iconColor }}
                            >
                                <AppsIcon />
                            </IconButton>
                            {getProductSoloLogo}
                            {getFirstPathSegment() === "allocator-app" &&
                                self?.roles?.includes(
                                    TRITECH_ROLE.ClientAccountAdmin
                                ) && (
                                    <Box sx={{ marginLeft: "60px" }}>
                                        <StyledNavButton
                                            disableRipple
                                            $isActive={activeNavButton === "jobs"}
                                            onClick={handleJobsClick}
                                        >
                                            Jobs
                                        </StyledNavButton>
                                        <StyledNavButton
                                            disableRipple
                                            $isActive={activeNavButton === "tools"}
                                            onClick={handleToolsClick}
                                            sx={{
                                                marginLeft: "32px"
                                            }}
                                        >
                                            Tools
                                        </StyledNavButton>
                                    </Box>
                                )}
                        </div>
                        {["Premium", "FormsPlus", "Municipal"].includes(
                            product?.productName
                        ) && <PdfControls />}
                        <div className="application-nav-bar-right-container">
                            <RightContent />
                        </div>
                    </StyledToolbar>
                </AppBar>

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                >
                    <StyledGridContainer container>
                        <StyledGridItem
                            item
                            xs={4}
                            key="Home"
                            onClick={handleHomeClick}
                            data-testid="home"
                        >
                            <StyledPaper $disabled={false}>
                                <img
                                    src={require("../../assets/images/Home.ico")}
                                    alt="icon"
                                />
                                <div>Home</div>
                            </StyledPaper>
                        </StyledGridItem>

                        {Object.entries(PRODUCT_NAME).map(([key, productName]) => {
                            const product = processedProducts?.find(
                                (product: Product) =>
                                    product.productName === productName
                            );

                            const name = product
                                ? product.productName
                                : productName;
                            const onClickFn = product
                                ? () => handleProductClick(product)
                                : () => {
                                };
                            const isDisabled = !product;

                            return (
                                <StyledGridItem
                                    item
                                    xs={4}
                                    key={name}
                                    onClick={onClickFn}
                                    data-testid={name}
                                >
                                    <StyledPaper $disabled={isDisabled}>
                                        <img
                                            src={getIconImg(name, isDisabled)}
                                            alt="icon"
                                        />
                                        <div>{name}</div>
                                    </StyledPaper>
                                </StyledGridItem>
                            );
                        })}
                        <StyledGridItem
                            item
                            xs={4}
                            key="Logout"
                            data-testid="logout"
                            onClick={() =>
                                logout({
                                    logoutParams: {
                                        returnTo: window.location.origin
                                    }
                                })
                            }
                        >
                            <StyledPaper $disabled={false}>
                                <LogoutIcon />
                                <div>Logout</div>
                            </StyledPaper>
                        </StyledGridItem>
                    </StyledGridContainer>
                </Popover>
            </div>
            <Migration
                open={migrationModalOpen}
                onClose={() => setMigrationModalOpen(false)}
                adminView={false}
                migrationCompanies={migrationCompanies}
            />
        </>

);
};

export default ApplicationNavBar;
