export const emailRegex = new RegExp(
    /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
);

export const phoneRegex = new RegExp(/^\d{10}$/);

export const zipRegex = new RegExp(/^\d{5}$/);

export const naicRegex = new RegExp(/^\d{5}$/);

export const naicGroupRegex = new RegExp(/^\d{4}$/);

export const federalIdRegex = new RegExp(/^\d{9}$|^\d{2}-\d{7}$/);

export const onlyLettersRegex = new RegExp(/^[a-zA-Z]+$/);

export const dueDateRegex = new RegExp(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/);
