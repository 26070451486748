import { Explicit, LockOutlined } from "@mui/icons-material";
import React from "react";
import { CompositeIcon } from "src/uikit";

const RETURN = "RETURN";
const NOT_UPDATED = "NOT_UPDATED";
const UPDATED = "UPDATED";
const PLACE = "PLACE";
const REPORT = "REPORT";

const getImagePath = (formStatus, isLocked, isEfiled=false) => {
    const lockedSuffix =  "";
    if(isEfiled){
        return 'efile.ico'
    }

    switch (formStatus) {
        case RETURN:
            return `regular-form${lockedSuffix}.ico`;
        case NOT_UPDATED:
            return `grey-circle${lockedSuffix}.ico`;
        case UPDATED:
            return `regular-form${lockedSuffix}.ico`;
        case PLACE:
            return `grey-circle${lockedSuffix}.ico`;
        case REPORT:
            return `report${lockedSuffix}.ico`;
        default:
            return `schedule${lockedSuffix}.ico`;
    }
};

export const getReturnNodeIcon = (formStatus, isLocked, isEfiled = false) => {

    const imagePath = getImagePath(formStatus, isLocked, isEfiled);

    let subIcon: React.ReactNode = null;
    let supIcon: React.ReactNode = null;

    if(isLocked){
        subIcon = (
            <LockOutlined sx={
                {
                    width: "100%",
                    height: "100%",
                    fill: "yellow",
                }
            } />
        )
    }

    return (
        <CompositeIcon
            icon={
                <img
                className="return-icon"
                src={require(`../../assets/images/return-icons/${imagePath}`)}
                alt={`${formStatus?.toLowerCase()} icon`}
                />
            }
            supIcon={supIcon}
            subIcon={subIcon}
        />
    );
};
