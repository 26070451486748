import React, { FC } from "react";
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { AllocatorCompanyOptionsEntity, AllocatorCompanyOptionsTabs, AllocatorFixedWidthField } from "src/types";
import { StyledFlexContainer } from "../AllocatorCompanyOptions.styled";
import { StyledFieldsContainer, StyledFixedWidthContainer, StyledScrollableContainer } from "./FileFormatOptions.styled";
import { StyledOutlinedButton } from "src/products/Allocator/Allocator.styled";

interface FileFormatOptionsProps {
    isHidden: boolean;
    formData: AllocatorCompanyOptionsEntity;
    onFormDataChange: (newData: AllocatorCompanyOptionsTabs) => void;
    setOpenDialog: (value: boolean) => void;
}

const FileFormatOptions: FC<FileFormatOptionsProps> = ({
    isHidden,
    formData,
    onFormDataChange,
    setOpenDialog,
}) => {
    const dataDelimiter = formData?.jsonOptions?.fileFormatOptions?.dataDelimiter;
    const columnFixedWidthPositions = formData?.jsonOptions?.fileFormatOptions?.columnFixedWidthPositions;
    const isOtherDelimiter = 
        dataDelimiter !== "," && 
        dataDelimiter !== ";" &&
        dataDelimiter !== "TAB" &&
        dataDelimiter !== "FWD";
        
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const fieldName = name?.split(".")[1];
        onFormDataChange({
            ...formData?.jsonOptions,
            fileFormatOptions: {
                ...formData?.jsonOptions?.fileFormatOptions,
                [fieldName]: value,
            }
        });
    };

    const handleOtherDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value.match(/^[^'" `]*$/)) {
            onFormDataChange({
                ...formData?.jsonOptions,
                fileFormatOptions: {
                    ...formData?.jsonOptions?.fileFormatOptions,
                    dataDelimiter: value,
                }
            })
        }
    };

    const handleFieldLengthChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
        targetField: AllocatorFixedWidthField
    ) => {
        const { value } = event.target;
        if (value.match(/^\d*$/)) {
            const newWidth = validateField(Number(value), targetField?.min, targetField?.max);
            let updatedStart = Number(columnFixedWidthPositions?.[0]?.start);
            const updatedFixedWidthPositions = columnFixedWidthPositions?.map((field: AllocatorFixedWidthField) => {
                if (field?.cell === targetField?.cell) {
                    const updatedField = {...field, start: updatedStart, width: newWidth}
                    updatedStart += newWidth;
                    return updatedField;
                } else {
                    const updatedField = {...field, start: updatedStart}
                    updatedStart += field?.width;
                    return updatedField;
                }
            })
            onFormDataChange({
                ...formData?.jsonOptions,
                fileFormatOptions: {
                    ...formData?.jsonOptions?.fileFormatOptions,
                    columnFixedWidthPositions: updatedFixedWidthPositions,
                }
            })
        }
    };

    const validateField = (value: number, min: number, max: number) => {
        if (value > max) { 
            return max;
        } 
        if (value < min) { 
            return min;
        } 
        return value;
    };

    return (
        <StyledFlexContainer $isHidden={isHidden}>
            <Box marginRight="100px">
                <Typography fontWeight="bold" marginBottom="10px">
                    Select the File Format of ASCII File
                </Typography>
                <RadioGroup
                    name="allocator.dataDelimiter"
                    value={dataDelimiter}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel
                        value={","}
                        control={<Radio />}
                        label="Comma Delimited"
                    />
                    <FormControlLabel
                        value={"TAB"}
                        control={<Radio />}
                        label="Tab Delimited"
                    />
                    <FormControlLabel
                        value={";"}
                        control={<Radio />}
                        label="Semicolon Delimited"
                    />
                    <FormControlLabel
                        value={isOtherDelimiter ? dataDelimiter : ""}
                        control={<Radio />}
                        label="Other Delimited"
                    />
                    {isOtherDelimiter &&
                        <TextField
                            size="small"
                            autoComplete="off"
                            sx={{width: "50px"}}
                            value={dataDelimiter}
                            inputProps={{ maxLength: 1, style: {textAlign: "center"} }}
                            onChange={handleOtherDelimiterChange}
                        />
                    }
                    <FormControlLabel
                        value={"FWD"}
                        control={<Radio />}
                        label="Fixed Width"
                    />
                </RadioGroup>
            </Box>
            {dataDelimiter === "FWD" && (
                <>
                    <StyledFixedWidthContainer>
                        <StyledFieldsContainer>
                            <Typography fontWeight="bold">
                                Field Name
                            </Typography>
                            <Typography fontWeight="bold">
                                Start Position
                            </Typography>
                            <Typography fontWeight="bold">
                                Field Length
                            </Typography>
                        </StyledFieldsContainer>
                        <StyledScrollableContainer >
                            {columnFixedWidthPositions?.map((field: AllocatorFixedWidthField) => 
                                <StyledFieldsContainer key={field?.cell}>
                                    <Typography>
                                        {field?.displayName}
                                    </Typography>
                                    <Typography>
                                        {field?.start}
                                    </Typography>
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        autoComplete="off"
                                        sx={{width: "100px"}}
                                        value={field?.width}
                                        onChange={(event) => handleFieldLengthChange(event, field)}
                                    />
                                </StyledFieldsContainer>
                            )}
                        </StyledScrollableContainer>
                    </StyledFixedWidthContainer>
                    <StyledOutlinedButton
                        variant="outlined"
                        onClick={() => setOpenDialog(true)}
                    >
                        Restore to Default
                    </StyledOutlinedButton>
                </>
            )}
        </StyledFlexContainer>
    );
};

export default FileFormatOptions;
