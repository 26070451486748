import { Box } from "@mui/material";
import React from "react";
import { FixMeLater } from "src/types";

export const LayoutPaneTabPanel: React.FC<FixMeLater> = (props) => {
    const { children, selectedTab, value, ...other } = props;
    const isHidden = value !== selectedTab;

    return (
        <Box
            role="tabpanel"
            hidden={isHidden}
            id={`tts-tabpanel-${value}`}
            aria-labelledby={`tts-tab-${value}`}
            component="div"
            {...other}
            sx={{
                display: "flex",
                flexDirection: "column",
                height: isHidden ? 0 : "100%",
                overflowY: "scroll",
                overflowX: "hidden",
            }}
        >
            {value === selectedTab && <Box sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}>{children}</Box>}
        </Box>
    );
};
