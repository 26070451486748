import React from "react";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorJob, AllocatorJobsPayload, AllocatorCompany, FixMeLater, AllocatorSelectOption } from "src/types";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { StyledFormControlDropdown } from "../../Allocator.styled";

interface StateDropdownProps {
    supportedStates: AllocatorSelectOption[];
    isDragActive: boolean;
    fetchJobList: (
        filterData?: AllocatorJobsPayload | null, 
        pageNumber?: number, 
        company?: AllocatorCompany | null, 
        year?: number | null, 
        state?: string
    ) => Promise<AllocatorJob[]>;
}

const StateDropdown: React.FC<StateDropdownProps> = ({
    supportedStates,
    isDragActive,
    fetchJobList,
}) => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedState: string = useAppSelector(
        (state) => state?.[product?.productName]?.value?.selectedState,
    );
    const page: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.page
    );
    const filterState: AllocatorJobsPayload = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.filterState
    );

    const dispatch = useAppDispatch();

    const handleChange = async (event: SelectChangeEvent<string>) => {
        const selectedState = supportedStates?.find(state => state?.value === event?.target?.value);
        if (page) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsPage(0)
            );
        }
        dispatch(
            GlobalStateActions[product?.productName].setSelectedState(
                selectedState?.value
            ),
        );
        if (filterState === undefined) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState({ filters: [], searchPhrase: ""})
            );
        }
        await fetchJobList(null, 0, null, null, selectedState?.value);
    };

    if (isDragActive) return null;

    return (
        <StyledFormControlDropdown size="small">
            <InputLabel>State</InputLabel>
            <Select
                value={selectedState}
                label="State"
                onChange={handleChange}
            >
                {
                    !supportedStates?.length 
                    ? <MenuItem disabled value="">No states</MenuItem>
                    : supportedStates?.map((state, index) => {
                        return (
                            <MenuItem value={state?.value} key={index}>{state?.value}</MenuItem>
                        )
                    })
                }
            </Select>
        </StyledFormControlDropdown>
    );
};

export default StateDropdown;
