export const ACCEPTED_FILE_TYPES = {
    "text/csv": [],
    "text/plain": [],
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

export const ACCEPTED_FIXED_WIDTH_FILE_TYPES = {
    "text/plain": [],
    ".prn": [],
};

export const ACCEPTED_SETTINGS_TYPES = {
    "application/vnd.ms-excel": [],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

export const DEFAULT_SEARCH_PARAMS = {
    summary: "0",
    report: "1", 
    page: "0", 
    size: "100",
}

export const QUARTER_OPTIONS = [
    { value: "1", label: "Q1" },
    { value: "2", label: "Q2" },
    { value: "3", label: "Q3" },
    { value: "4", label: "Q4" },
]
