import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TabProperties, TabsPropertiesMap } from "src/types";
import { action } from "webextension-polyfill";

interface Tabs {
    tabsProperties: TabsPropertiesMap;
    splitScreen: boolean;
    toogleFetch: boolean;
}

const initialState: Tabs = {
    tabsProperties: {},
    splitScreen: false,
    toogleFetch: false,
};

export const TabSlice = createSlice({
    name: "Tabs",
    initialState,
    reducers: {
        setTabs: (state, action: PayloadAction<TabsPropertiesMap>) => {
            state.tabsProperties = action.payload;
        },
        setTab: (state, action: PayloadAction<TabProperties>) => {
            const tab = action.payload;
            state.tabsProperties[tab.id] = tab;
        },
        removeTab: (state, action: PayloadAction<string>) => {
            delete state.tabsProperties[action.payload];
        },
        setSplitScreen: (state, action: PayloadAction<boolean>) => {
            state.splitScreen = action.payload;
        },
        setToogleFetch: (state, action: PayloadAction<boolean>) => {
            state.toogleFetch = action.payload;
        },
    },
});

export const {
    setTabs,
    setTab,
    removeTab,
    setSplitScreen,
    setToogleFetch,
} = TabSlice.actions;
export default TabSlice.reducer;
