import { useAuth0 } from "@auth0/auth0-react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { MouseEvent, useState } from "react";
import { ProductNameToIdMap } from "src/constants/ProductIds";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import {
    GraphqlService,
    ReturnDocumentService,
    ReturnPDFService,
    WebExtensionService,
    WebLoginService,
} from "src/services";
import {
    returnCompaniesTreeQuery,
    returnTreeQuery,
} from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import { getModuleId } from "src/services/Utility";
import {
    CreateReturnPDFPayload,
    ExtMessageResponse,
    ExtMessageResponseStatus,
    FixMeLater,
    FolderNode as FolderNodeType,
    LoginData,
    QueryParams,
    ReturnNode,
    ScheduleKey,
    TabProperties,
    Tree,
} from "src/types";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import "./FolderNodeMenu.scss";
import { downloadFile, generateUniqueKey, getQueryParams } from "src/utils";
import {
    ChevronRightOutlined,
    RadioButtonCheckedOutlined,
    RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { ReturnStatusService } from "src/services/ReturnStatusService";
import { PRODUCT_NAME } from "src/constants";
import FeatureFlags from "src/utils/FeatureFlags";
import { filterForReturnNodes } from "src/utils/TreeUtils";
import { HttpError } from "src/utils/HttpError";
import { StyledFlexContainer } from "../FolderNodeDialog/FolderNodeDialog.styled";

type FolderNodeMenuProps = {
    anchorEl: null | HTMLElement;
    onCloseFolderNodeMenu: () => void;
    folderNode: FolderNodeType;
    onFolderNodeDialogOpen: (folderNode: FixMeLater) => void;
    onGenericReturnDialogOpen: (folderNode: FixMeLater) => void;
};

const FolderNodeMenu: React.FC<FolderNodeMenuProps> = ({
    anchorEl,
    onCloseFolderNodeMenu,
    folderNode,
    onFolderNodeDialogOpen,
    onGenericReturnDialogOpen
}) => {
    const [lockAllLoading, setLockAllLoading] = useState<boolean>(false);
    const [unlockAllLoading, setUnlockAllLoading] = useState<boolean>(false);
    const [downloadAllReturnsLoading, setDownloadAllReturnsLoading] =
        useState<boolean>(false);
    const [
        downloadAllPaymentRequestsLoading,
        setDownloadAllPaymentRequestsLoading,
    ] = useState<boolean>(false);
    const [
        downloadAnnualReconciliationLoading, 
        setDownloadAnnualReconciliationLoading
    ] = useState<boolean>(false);

    const [showExportConfirmation, setShowExportConfirmation] = useState<boolean>(false);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>(
        "Returns updated successfully!"
    );
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");

    const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);
    const [returnNodeStatus, setReturnNodeStatus] = useState<{
        [key: number]: string;
    }>({});
    const returnStatusService = ReturnStatusService.getInstance();
    const returnPDFService = ReturnPDFService.getInstance();
    const graphqlService = GraphqlService.getInstance();
    const webLoginService = WebLoginService.getInstance();
    const webExtensionService = WebExtensionService.getInstance();

    const product: FixMeLater = useAppSelector((state) => state?.Product.value);
    const company = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company
    );
    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev
    );
    if (!product) return null;

    const dropdownStateView: boolean = useAppSelector((state) => {
        return state?.UserOptions?.value?.productPreferences[
            product.productName
        ]?.dropdownStateView;
    });

    const state = useAppSelector(
        (state) => state?.[product?.productName]?.value?.state
    );
    const selectedQuarter = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedQuarter
    );

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs
    )?.tabsProperties;

    const returnDocumentService = ReturnDocumentService.getInstance();

    const dispatch = useAppDispatch();

    const { getAccessTokenSilently } = useAuth0();

    const handleFolderNodeDialogOpen = (e: MouseEvent) => {
        e.stopPropagation();
        onCloseFolderNodeMenu();
        onFolderNodeDialogOpen(folderNode);
    };

    const handleGenericReturnDialogOpen = (e: MouseEvent) => {
        e.stopPropagation();
        onCloseFolderNodeMenu();
        onGenericReturnDialogOpen(folderNode);
    };

    const updateLockedsInTabState = (
        tabsKeysArray: string[],
        locked: boolean
    ) => {
        tabsKeysArray.forEach((tabKey) => {
            if (tabsProperties[tabKey]) {
                const updatedTabProperties: TabProperties = {
                    ...tabsProperties[tabKey],
                    isLocked: locked,
                };
                dispatch(GlobalStateActions.setTab(updatedTabProperties));
            }
        });
    };

    const updateReturnsStatusInTabState = (returnStatus: number) => {
        const tabsKeysArray: string[] = [];
        const filteredReturnNodes = folderNode?.returnNodes?.filter(
            (returnNode) => returnNode.isActive
        );
        filteredReturnNodes?.forEach((returnNode) => {
            const uniqueKey = generateUniqueKey(
                folderNode?.id.toString(),
                returnNode,
                product,
                company?.name,
                municipalState
            );
            tabsKeysArray.push(uniqueKey);
        });
        tabsKeysArray.forEach((tabKey) => {
            if (tabsProperties[tabKey]) {
                const updatedTabProperties: TabProperties = {
                    ...tabsProperties[tabKey],
                    returnStatus: Number(returnStatus),
                };
                dispatch(GlobalStateActions.setTab(updatedTabProperties));
            }
        });
    };

    const updateAllReturnNodes = async (
        e: MouseEvent,
        actionType: "lock" | "unlock"
    ) => {
        e.stopPropagation();

        const loadingState =
            actionType === "lock" ? setLockAllLoading : setUnlockAllLoading;

        loadingState(true);

        try {
            const accessToken = await getAccessTokenSilently();
            const tabsKeysArray: string[] = [];

            const commonParams: QueryParams = getQueryParams({
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: ProductNameToIdMap.get(product?.productName),
                taxYearId: product?.taxYear,
                folderId: dropdownStateView ? state?.id : folderNode?.id,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
            });

            const createPayload = (returnNodes: ReturnNode[]) =>
                returnNodes?.map((returnNode) => {
                    const params: QueryParams = getQueryParams({
                        ...commonParams,
                        returnId: returnNode?.id,
                        retalFolderId: returnNode?.retalFolderId,
                    });
                    return params;
                });

            const filteredReturnNodes =
                actionType === "lock"
                    ? folderNode?.returnNodes?.filter(
                          (returnNode) =>
                              returnNode.isActive && !returnNode?.isLocked
                      )
                    : folderNode?.returnNodes?.filter(
                          (returnNode) =>
                              returnNode.isActive && returnNode?.isLocked
                      );

            const actionPayloads = createPayload(filteredReturnNodes);
            filteredReturnNodes?.forEach((returnNode) => {
                const uniqueKey = generateUniqueKey(
                    folderNode?.id.toString(),
                    returnNode,
                    product,
                    company?.name,
                    municipalState
                );
                tabsKeysArray.push(uniqueKey);
            });

            if (!actionPayloads?.length) {
                setSnackbarMessage("There are no returns to update.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }

            let success = true;

            if (actionPayloads.length !== 0) {
                try {
                    if (actionType === "lock") {
                        try {
                            await returnDocumentService.lockReturnNodes(
                                actionPayloads
                            );

                            // update global state to reflect change in tabs
                            updateLockedsInTabState(tabsKeysArray, true);
                        } catch (error) {
                            success = false;
                        }
                    } else {
                        try {
                            await returnDocumentService.unlockReturnNodes(
                                actionPayloads
                            );
                            // update global state to reflect change in tabs
                            updateLockedsInTabState(tabsKeysArray, false);
                        } catch (error) {
                            success = false;
                        }
                    }
                } catch (error) {
                    success = false;
                }
            }

            const treeInput: FixMeLater = {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
            };

            let data: FixMeLater;
            if (dropdownStateView) {
                const compTreeInput = {
                    folderId: state?.id,
                    productId: product?.productId,
                    taxYearId: product?.taxYear,
                };

                const { treeCompanies } = await graphqlService.fetchData<{
                    treeCompanies: Tree;
                }>(returnCompaniesTreeQuery, { compTreeInput });
                dispatch(
                    GlobalStateActions[product?.productName].setTree(
                        treeCompanies
                    )
                );
            } else {
                data = await GQLService.fetchGraphQLData(
                    returnTreeQuery,
                    { treeInput },
                    accessToken
                );
                dispatch(
                    GlobalStateActions[product?.productName].setTree(data.tree)
                );
            }

            if (success) {
                handleSnackbar(
                    `Returns ${actionType}ed successfully!`,
                    "success"
                );
            } else {
                handleSnackbar(
                    "There has been an error updating the return statuses for some returns.",
                    "error"
                );
            }
        } catch (error) {
            handleSnackbar(
                "There has been an error updating the return statuses for some returns.",
                "error"
            );
        } finally {
            loadingState(false);
            onCloseFolderNodeMenu();
        }
    };
    const downloadAllMunicipalReturns = async (isPaymentRequest: boolean) => {
        try {
            isPaymentRequest
                ? setDownloadAllPaymentRequestsLoading(true)
                : setDownloadAllReturnsLoading(true);

            const returnDocId: CreateReturnPDFPayload = {
                returnDocId: {
                    companyId: dropdownStateView
                        ? folderNode?.id?.toString()
                        : company.id,
                    productId: product.productId,
                    taxYearId: product.taxYear,
                    folderId: Number(folderNode?.id),
                    moduleId: Number(
                        getModuleId(
                            product,
                            company
                                ? dropdownStateView
                                    ? folderNode
                                    : company
                                : {},
                            municipalState
                        )
                    ),
                    returnId: 0,
                    retalFolderId: Number(folderNode?.id),
                    paymentRequestFormId: isPaymentRequest ? 1171 : 0,
                },
            };

            const returnsDownloadable =
                await returnPDFService.generateDownloadableReturnsBulk(
                    returnDocId
                );
            const { blob, contentDispositionHeader } = returnsDownloadable;
            downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
            handleSnackbar("Downloaded successfully", "success");
        } catch (error) {
            handleSnackbar("Error downloading files", "error");
            console.error("Error downloading files:", error);
        } finally {
            isPaymentRequest
                ? setDownloadAllPaymentRequestsLoading(false)
                : setDownloadAllReturnsLoading(false);
            onCloseFolderNodeMenu();
        }
    };

    const downloadAnnualReconciliation = async () => {
        try {
            setDownloadAnnualReconciliationLoading(true);

            const payload: QueryParams = {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: product?.productId,
                taxYear: product?.taxYear,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
            };

            const returnsDownloadable =
                await returnDocumentService.generateDownloadableAnnualReconciliation(
                    payload
                );
            const { blob, contentDispositionHeader } = returnsDownloadable;
            downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
            handleSnackbar("Exported successfully", "success");
            setShowExportConfirmation(true);
        } catch (error) {
            if (error instanceof HttpError) {
                handleSnackbar(JSON.parse(error?.body)?.message, "error");
            } else {
                handleSnackbar("Error exporting Annual Reconciliation", "error");
            }
        } finally {
            setDownloadAnnualReconciliationLoading(false);
            onCloseFolderNodeMenu();
        }
    };

    const onWebLoginClick = async () => {
        setShowExportConfirmation(false);
        
        const payload: ScheduleKey = {
            companyId: dropdownStateView
                ? Number(folderNode?.id)
                : company.id,
            productId: product.productId,
            taxYear: product.taxYear,
            moduleId: Number(
                getModuleId(
                    product,
                    company
                        ? dropdownStateView
                            ? folderNode
                            : company
                        : {},
                    municipalState
                )
            ),
        };
        const message = `Web login`;

        const loginData: LoginData =
            await webLoginService.getLoginDataByFixedCredential(payload);

        handleSnackbar(message, "info");

        const loginResponseData: ExtMessageResponse =
            await webExtensionService.doLogin(loginData);

        if (loginResponseData.status === ExtMessageResponseStatus.ERROR) {
            handleSnackbar(message + " failed", "error");
            return;
        }

        if (loginResponseData.status === ExtMessageResponseStatus.SUCCESS) {
            handleSnackbar(message + " completed", "success");
            return;
        }

        if (loginResponseData.status === ExtMessageResponseStatus.WARNING) {
            handleSnackbar(message + " completed with warnings", "warning");
            return;
        }
    };

    const downloadAllFolderReturns = async () => {
        try {
            setDownloadAllReturnsLoading(true);
            const returnDocId: CreateReturnPDFPayload = {
                returnDocId: {
                    companyId: dropdownStateView
                        ? Number(folderNode?.id)
                        : company.id,
                    productId: product.productId,
                    taxYearId: product.taxYear,
                    folderId: dropdownStateView
                        ? Number(state?.id)
                        : Number(folderNode?.id),
                    moduleId: Number(
                        getModuleId(
                            product,
                            company
                                ? dropdownStateView
                                    ? folderNode
                                    : company
                                : {},
                            municipalState
                        )
                    ),
                    returnId: 0,
                    retalFolderId: Number(folderNode?.id),
                },
            };

            const returnsDownloadable =
                await returnPDFService.generateDownloadableAllPdf(returnDocId);
            const { blob, contentDispositionHeader } = returnsDownloadable;
            downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
            handleSnackbar("Downloaded successfully", "success");
        } catch (error) {
            handleSnackbar("Error downloading files", "error");
            console.error("Error downloading files:", error);
        } finally {
            setDownloadAllReturnsLoading(false);
            onCloseFolderNodeMenu();
        }
    };

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleStatusMenuClick = async (event) => {
        setStatusMenuAnchorEl(event.currentTarget);
        try {
            const payload: QueryParams = {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: product?.taxYear,
                folderId: dropdownStateView
                    ? state?.id.toString()
                    : folderNode?.id.toString(),
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
                returnId: "0",
                retalFolderId:
                    folderNode.returnNodes[0]?.retalFolderId?.toString() ?? "",
            };
            const result = await returnStatusService.getReturnStatuses(payload);
            setReturnNodeStatus(result.second);
            
        } catch (error) {
            console.error("Error fetching statuses:", error);
        }
    };

    const handleStatusMenuClose = () => {
        setStatusMenuAnchorEl(null);
    };

    const handleSetStatus = async (statusKey) => {
        try {
            const commonParams: QueryParams = {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: `${ProductNameToIdMap.get(product?.productName)}`,
                taxYearId: product?.taxYear,
                folderId: dropdownStateView
                    ? state?.id.toString()
                    : folderNode?.id.toString(),
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
                returnId: "0",
                retalFolderId:
                    folderNode.returnNodes[0]?.retalFolderId?.toString() ?? "",
                returnStatus: statusKey,
            };
            await returnStatusService.setReturnStatuses(commonParams);
            const accessToken = await getAccessTokenSilently();
            const treeInput: FixMeLater = {
                companyId: dropdownStateView
                    ? Number(folderNode?.id)
                    : company.id,
                productId: product?.productId,
                taxYearId: product?.taxYear,
                moduleId: getModuleId(
                    product,
                    company ? (dropdownStateView ? folderNode : company) : {},
                    municipalState
                ),
            };

            let data: FixMeLater;
            if (dropdownStateView) {
                const compTreeInput = {
                    folderId: state?.id,
                    productId: product?.productId,
                    taxYearId: product?.taxYear,
                };

                const { treeCompanies } = await graphqlService.fetchData<{
                    treeCompanies: Tree;
                }>(returnCompaniesTreeQuery, { compTreeInput });
                dispatch(
                    GlobalStateActions[product?.productName].setTree(
                        treeCompanies
                    )
                );
            } else {
                data = await GQLService.fetchGraphQLData(
                    returnTreeQuery,
                    { treeInput },
                    accessToken
                );
                dispatch(
                    GlobalStateActions[product?.productName].setTree(data.tree)
                );
            }
            handleSnackbar("Returns status have been updated", "success");
            updateReturnsStatusInTabState(statusKey);
        } catch (error) {
            console.error("Error updating statuses:", error);
            handleSnackbar("Unable to save returns status", "error");
        }
        handleStatusMenuClose();
        onCloseFolderNodeMenu();
    };

    // ReturnNodes without retals or retal worksheets
    const filteredReturnNodes = folderNode.returnNodes.filter(
        (returnNode: ReturnNode) =>
            filterForReturnNodes(returnNode, folderNode, company, true)
    );

    const hasActiveReturns = filteredReturnNodes.some(
        (returnNode) => returnNode.isActive
    );

    const hasInactiveReturns = filteredReturnNodes.some(
        (returnNode) => !returnNode.isActive
    );

    const hasLockedReturns = filteredReturnNodes.some(
        (returnNode) => returnNode.isActive && returnNode.isLocked
    );

    const hasUnlockedReturns = filteredReturnNodes.some(
        (returnNode) => returnNode.isActive && !returnNode.isLocked
    );

    return (
        <div>
            {filterForReturnNodes.length !== 0 && (
                <>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={onCloseFolderNodeMenu}
                        TransitionProps={{
                            timeout: 0,
                        }}
                    >
                        {folderNode?.attributes?.displayName !==
                            "Municipal Returns Reconciliation" && // Municipal Returns Reconciliation cannot be activated
                            hasInactiveReturns && (
                                <MenuItem onClick={handleFolderNodeDialogOpen}>
                                    Activate
                                </MenuItem>
                            )}
                        {product.productName === "FormsPlus" && // Generic return is only for FormsPlus
                            (
                                <MenuItem onClick={handleGenericReturnDialogOpen}>
                                    Add Generic Return
                                </MenuItem>
                            )}
                        {hasUnlockedReturns && (
                            <MenuItem
                                onClick={(e) => updateAllReturnNodes(e, "lock")}
                            >
                                Lock All
                                {lockAllLoading && (
                                    <CircularProgress size={20} />
                                )}
                            </MenuItem>
                        )}

                        {hasLockedReturns && (
                            <MenuItem
                                onClick={(e) =>
                                    updateAllReturnNodes(e, "unlock")
                                }
                            >
                                Unlock All
                                {unlockAllLoading && (
                                    <CircularProgress size={20} />
                                )}
                            </MenuItem>
                        )}

                        {hasActiveReturns &&
                            (product?.productName === PRODUCT_NAME.MUNICIPAL ? (
                                <>
                                    <MenuItem
                                        onClick={() =>
                                            downloadAllMunicipalReturns(false)
                                        }
                                    >
                                        Download all Municipal Returns
                                        {downloadAllReturnsLoading && (
                                            <CircularProgress size={20} />
                                        )}
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() =>
                                            downloadAllMunicipalReturns(true)
                                        }
                                    >
                                        Download all Payment Requests
                                        {downloadAllPaymentRequestsLoading && (
                                            <CircularProgress size={20} />
                                        )}
                                    </MenuItem>
                                    {folderNode?.attributes?.displayName ===
                                        "Municipal Returns Reconciliation" && (
                                        <MenuItem onClick={downloadAnnualReconciliation}>
                                            Export Annual Reconciliation
                                            {downloadAnnualReconciliationLoading && (
                                                <CircularProgress size={20} />
                                            )}
                                        </MenuItem>
                                    )}
                                </>
                            ) : (
                                <MenuItem
                                    onClick={() => downloadAllFolderReturns()}
                                >
                                    Download All to Pdf
                                    {downloadAllReturnsLoading && (
                                        <CircularProgress size={20} />
                                    )}
                                </MenuItem>
                            ))}

                        {folderNode?.attributes?.displayName !==
                            "Municipal Returns Reconciliation" && // Municipal Returns Reconciliation cannot be activated
                            hasActiveReturns && (
                                <MenuItem
                                    onClick={handleStatusMenuClick}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    Set Status
                                    <ChevronRightOutlined />
                                </MenuItem>
                            )}

                        {FeatureFlags.showOnlyInLocalDevAndQa() &&
                            dropdownStateView && (
                                <MenuItem
                                    onClick={() => {
                                        dispatch(
                                            GlobalStateActions[
                                                product?.productName
                                            ]?.setCompanyOptionsOpen(true)
                                        );
                                    }}
                                >
                                    Company Options
                                </MenuItem>
                            )}
                    </Menu>
                    <Menu
                        anchorEl={statusMenuAnchorEl}
                        open={Boolean(statusMenuAnchorEl)}
                        onClose={handleStatusMenuClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        {Object.entries(returnNodeStatus).map(
                            ([statusKey, status]) => (
                                <MenuItem
                                    onClick={() => handleSetStatus(statusKey)}
                                    key={statusKey}
                                >
                                    <ListItemIcon>
                                        {folderNode.returnNodes
                                            .filter((rN) => rN.isActive)
                                            .every(
                                                (returnNode) =>
                                                    parseInt(
                                                        returnNode.returnStatus
                                                    ) == parseInt(statusKey, 10)
                                            ) ? (
                                            <RadioButtonCheckedOutlined />
                                        ) : (
                                            <RadioButtonUncheckedOutlined />
                                        )}
                                    </ListItemIcon>
                                    {status}
                                </MenuItem>
                            )
                        )}
                    </Menu>
                    <CustomSnackbar
                        open={snackbarOpen}
                        setOpen={setSnackbarOpen}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                    <Dialog
                        open={showExportConfirmation}
                        onClose={() => setShowExportConfirmation(false)}
                    >
                        <DialogTitle>
                            Export Annual Reconciliation
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to upload the exported annual reconciliation file to the Kentucky Online Gateway?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <StyledFlexContainer justify={"center"}>
                                <Button
                                    onClick={() => setShowExportConfirmation(false)}
                                    color="primary"
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={onWebLoginClick}
                                    color="primary"
                                >
                                    Yes
                                </Button>
                            </StyledFlexContainer>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default FolderNodeMenu;
