import React, { useEffect, useRef } from "react";
import { PropsWithChildren } from "react";
import { ReturnPage } from "src/types";
import { StyledPageWrapper } from "./PageWrapper.styled";

export interface PageWrapperProps {
    page: ReturnPage;
    onVisible: (page: ReturnPage) => void;
    pageLoaded: boolean;
    id: string;
}

export const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps>> = ({
    children,
    onVisible,
    page,
    pageLoaded,
    id,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Verified that that we need to use setTimeout here due to: wait for the page to be loaded before we can check if it is visible/active.
        setTimeout(() => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && pageLoaded) {
                            onVisible(page);
                        }
                    });
                },
                {
                    root: null,
                    threshold: 0.1, // adjusted due to browser zoom settings.
                    rootMargin: "0px",
                }
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.disconnect();
                }
            };
        }, 100);
    }, []);

    return (
        <StyledPageWrapper id={id} ref={ref}>
            {children}
        </StyledPageWrapper>
    );
};
